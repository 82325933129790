import { CartItem } from '../cart/cart';

export default class ItemShipping {
  public amount: number;
  public customHeight?: number;
  public customWidth?: number;
  public productId: number;

  constructor(item: CartItem) {
    this.amount = item?.amount || 0;
    this.customHeight = item?.customHeight;
    this.customWidth = item?.customWidth;
    this.productId = item?.productId || 0;
  }
}
