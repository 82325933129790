import Card from '../payment/card';
import User from '../user/user';
import Holder from './holder';
import Installment from './installment';

export default class CreditCart {
  public holder?: Holder;
  public installment?: Installment;
  public token?: string; // Token do Cartão de Crédito. Token retornado no serviço de obtenção de token do cartão de crédito.

  constructor(user: User, card: Card) {
    if (card?.cardNumber) {
      this.token = card.token;
      this.holder = new Holder(user);
      this.installment = new Installment(card.installments);
    }
  }
}
