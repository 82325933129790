export default class Address {
  address?: string | undefined;
  city?: string;
  complement?: string;
  district?: string;
  ibgeCode?: string;
  id?: number;
  numberAddress?: string;
  principal?: boolean;
  uf?: string;
  zipCode?: string;

  static validAdress(address: Address): boolean {
    const valid = true;
    return valid;
  }

  static filledAdress(address: Address): boolean {
    if (
      address.uf != null ||
      address.city != null ||
      address.district != null ||
      address.address != null ||
      address.ibgeCode != null ||
      address.numberAddress != null ||
      address.zipCode != null ||
      address.complement != null ||
      address.uf
    ) {
      return true;
    }
    return false;
  }

  fromJson(address: any) {
    if (address) {
      this.id = address.id || '';
      this.uf = address.uf || '';
      this.city = address.city || '';
      this.district = address.district || '';
      this.address = address.address || '';
      this.ibgeCode = address.ibgeCode || '';
      this.numberAddress = address.numberAddress || '';
      this.zipCode = address.zipCode || '';
      this.complement = address.complement || '';
      this.principal = address.principal || '';
    }
  }
}
