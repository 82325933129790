import { Injectable } from '@angular/core';
import CalculateShipping from '../../shared/models/freight/calculate-shipping';
import Carrying from '../../shared/models/freight/carrying';
import Freight from '../../shared/models/freight/freight';
import QuoteList from '../../shared/models/freight/quote-list';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class FreightService {
  constructor(private httpService: HttpService) {}

  public static addStorePickup() {
    const c = new Carrying();
    c.displayName = 'RETIRAR NA LOJA';
    c.priceFreight = 0;
    c.costFreight = 0;
    c.optionFreight = Freight.STORE_PICKUP;
    c.deliveryTime = 1;

    let dtEstimada = new Date();
    dtEstimada.setDate(dtEstimada.getDate() + 1);

    const now = new Date();
    const h = now.getHours();
    if (h >= 17) {
      dtEstimada.setDate(dtEstimada.getDate() + 1);
      c.deliveryTime = 2;
    }

    c.estimatedDelivery = dtEstimada.toLocaleDateString('br', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    return c;
  }

  public async calculateShippingOrCart(
    calculate: CalculateShipping
  ): Promise<QuoteList> {
    try {
      if (calculate?.cartId) {
        return await this.calculateCart(calculate);
      } else {
        return await this.calculateShipping(calculate);
      }
    } catch (error) {
      throw error;
    }
  }

  public async calculateShipping(
    calculate: CalculateShipping
  ): Promise<QuoteList> {
    return new Promise((resolve, reject) => {
      this.httpService
        .post(`freight/calculate/shipping/`, calculate)
        .subscribe({
          next: (res: any) => {
            if (res) {
              const quote: QuoteList = this.getQuotes(res);
              resolve(quote);
            }
          },
          error: async (error: any) => {
            reject(error);
          },
        });
    });
  }

  public async calculateCart(calculate: CalculateShipping): Promise<QuoteList> {
    return new Promise((resolve, reject) => {
      this.httpService
        .post(`freight/calculate/shipping/cart/`, calculate)
        .subscribe({
          next: (res: any) => {
            if (res) {
              const quote: QuoteList = this.getQuotes(res);
              resolve(quote);
            }
          },
          error: async (error: any) => {
            reject(error);
          },
        });
    });
  }

  getQuotes(res: any): QuoteList {
    const quote: QuoteList = Object.assign(new QuoteList(), res);
    const newCarries = [];

    if ((quote?.carriers?.length || 0) > 0) {
      newCarries.push(FreightService.addStorePickup());
    }

    quote?.carriers?.map((c) => {
      c.name = c?.name?.toLowerCase();
      if (c.priceFreight === 0.01 || c.costFreight === 0.01) {
        c.priceFreight = 0;
        c.costFreight = 0;
      }
      newCarries.push(Object.assign(new Carrying(), c));
    });
    quote.carriers = newCarries.slice(0, 5);
    return quote;
  }
}
