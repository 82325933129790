export default class Volume {
  packingWidth?: number;
  packingLength?: number;
  packingWeight?: number;
  packingHeightThickness?: number;

  fromJson(volume: any) {
    if (volume) {
      this.packingWidth = volume.packingWidth;
      this.packingLength = volume.packingLength;
      this.packingWeight = volume.packingWeight;
      this.packingHeightThickness = volume.packingHeightThickness;
    }
  }
}
