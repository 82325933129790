<app-breadcrumb
  [path]="'FAQ - Perguntas Frequentes'"
  [showTitle]="true"
></app-breadcrumb>
<div class="faq-page-main">
  <div class="container">
    <div class="faq-page-main-content">
      <details>
        <summary>1. Quais tipos de produtos vocês oferecem?</summary>
        <div>
          <p>
            Trabalhamos com chapas de policarbonato, perfis de acabamento em
            alumínio, fitas de vedação e todos os itens necessários para a
            instalação do policarbonato. Também oferecemos persianas sob medida
            dos modelos rolo e romana, em diversos tecidos, e toldos de fácil
            instalação.
          </p>
        </div>
      </details>
      <details>
        <summary>2. Quais são as cores de policarbonato disponíveis?</summary>
        <div>
          <p>
            Oferecemos uma variedade de cores, incluindo opções de cores
            refletivas que ajudam a evitar o efeito estufa, além de cores
            tradicionais como Cristal, Bronze e Fumê.
          </p>
        </div>
      </details>
      <details>
        <summary>
          3. Vocês oferecem corte sob medida para chapas de policarbonato?
        </summary>
        <div>
          <p>
            Não, trabalhamos apenas com chapas inteiras nas medidas padrão, mas
            temos perfis e acessórios que facilitam a instalação.
          </p>
        </div>
      </details>
      <details>
        <summary>4. Vocês realizam entregas para todo o Brasil?</summary>
        <div>
          <p>
            Sim, enviamos para todo o Brasil itens de até 1 metro. Para as
            regiões Sul e Sudeste, enviamos até 3 metros. Para outras regiões,
            as condições de envio devem ser consultadas previamente.
          </p>
        </div>
      </details>
      <details>
        <summary>
          5. Vocês oferecem garantia nos produtos de policarbonato?
        </summary>
        <div>
          <p>
            Sim, os produtos de policarbonato têm garantia de 10 anos contra
            amarelamento e ressecamento, desde que as recomendações de fábrica
            sejam seguidas.
          </p>
        </div>
      </details>
      <details>
        <summary>6. Como posso solicitar um orçamento personalizado?</summary>
        <div>
          <p>
            Para solicitar um orçamento, acesse a nossa página de contato ou
            entre em contato pelos canais fornecidos no site, informando as
            especificações do seu projeto.
          </p>
        </div>
      </details>
      <details>
        <summary>7. Quais tipos de persianas vocês oferecem?</summary>
        <div>
          <p>
            Oferecemos persianas sob medida nos modelos rolo e romana,
            disponíveis em diversos tecidos, ideais para controlar a
            luminosidade e oferecer estilo ao ambiente.
          </p>
        </div>
      </details>
      <details>
        <summary>8. Posso cancelar ou trocar um pedido após a compra?</summary>
        <div>
          <p>
            Sim, conforme nossa política, você pode solicitar o cancelamento ou
            troca em até 7 dias corridos após o recebimento do produto, desde
            que ele esteja em sua embalagem original, sem sinais de uso ou
            montagem.
          </p>
        </div>
      </details>
      <details>
        <summary>
          9. O que devo fazer se o produto chegar com avaria ou defeito?
        </summary>
        <div>
          <p>
            Caso receba um produto avariado ou com defeito, entre em contato
            conosco em até 48 horas após o recebimento, enviando fotos e uma
            descrição do problema. Iniciaremos o processo de troca ou devolução
            assim que recebermos a solicitação.
          </p>
        </div>
      </details>
      <details>
        <summary>
          10. Como é feito o reembolso em caso de devolução ou cancelamento?
        </summary>
        <div>
          <p>
            O reembolso será realizado no mesmo método de pagamento utilizado na
            compra, em até 10 dias úteis após a confirmação da devolução do
            produto. No caso de pagamento via cartão, o prazo pode variar
            conforme a administradora do cartão.
          </p>
        </div>
      </details>
      <details>
        <summary>
          11. O que faço se meu pedido estiver fora do prazo de entrega?
        </summary>
        <div>
          <p>
            Se o prazo de entrega informado tiver expirado, entre em contato com
            nossa equipe de atendimento para que possamos verificar a situação
            com a transportadora e fornecer um status atualizado.
          </p>
        </div>
      </details>
      <details>
        <summary>
          12. Posso alterar o endereço de entrega após a compra?
        </summary>
        <div>
          <p>
            Sim, é possível solicitar a alteração do endereço de entrega, desde
            que o pedido ainda não tenha sido despachado. Entre em contato com
            nossa equipe o mais rápido possível para fazer a alteração.
          </p>
        </div>
      </details>
    </div>
  </div>
</div>
