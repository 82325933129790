import { AbstractInfoEnum } from '../app/abstract-info-enum';

export default class InfoCoupon extends AbstractInfoEnum {
  discountTypes?: Array<AbstractInfoEnum>;
  status: Array<AbstractInfoEnum> = [];
  types: Array<AbstractInfoEnum> = [];
  meansOfPayment: Array<AbstractInfoEnum> = [];

  override fromJson(couponObj: any) {
    if (couponObj != null) {
      if (
        couponObj.discountTypes != null &&
        couponObj.discountTypes.length > 0
      ) {
        this.discountTypes = this.newAbstractInfo(couponObj.discountTypes);
      }

      if (couponObj.status != null && couponObj.status.length > 0) {
        this.status = this.newAbstractInfo(couponObj.status);
      }

      if (couponObj.types != null && couponObj.types.length > 0) {
        this.types = this.newAbstractInfo(couponObj.types);
      }

      if (
        couponObj.meansOfPayment != null &&
        couponObj.meansOfPayment.length > 0
      ) {
        this.meansOfPayment = this.newAbstractInfo(couponObj.meansOfPayment);
      }
    }
  }

  newAbstractInfo(couponObj: Array<any>) {
    let itens: Array<AbstractInfoEnum> = [];
    let abstractInfo: AbstractInfoEnum;
    itens = [];
    couponObj.forEach((item) => {
      abstractInfo = new AbstractInfoEnum();
      abstractInfo.fromJson(item);
      itens.push(abstractInfo);
    });
    return itens;
  }
}
