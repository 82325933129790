import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RecaptchaModule } from 'ng-recaptcha';
import { MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { PrimeNGModule } from '../../primeng.module';
import { LoaderService } from '../../services/loader/loader.service';
import { MetaTagsService } from '../../services/meta-tags/meta-tags.service';
import { ToastService } from '../../services/toast/toast.service';
import { UserService } from '../../services/user/user.service';
import {
  AddressComponent,
  AddressDisable,
  AddressError,
} from '../../shared/components/address/address.component';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { UserDataComponent } from '../../shared/components/user-data/user-data.component';
import Address from '../../shared/models/user/address';
import User from '../../shared/models/user/user';
import { validCPF } from '../../shared/utils/document';
import {
  validBirthDate,
  validEmail,
  validName,
  validPassword,
  validPhone,
  validZipCode,
} from '../../shared/utils/validators';
import { TWO_SECONDS_IN_MS, wait } from '../../shared/utils/wait';

export class UserError {
  public errorName = false;
  public errorLastName = false;
  public errorDocument = false;
  public errorPhone = false;
  public errorBirthDate = false;
  public errorEmail = false;
  public errorPassword = false;
  public errorConfirmPassword = false;
  public msgPassword = '';
}

export class PasswordError {
  public errorUpperCase = false;
  public errorLowercase = false;
  public errorNumber = false;
  public errorLength = false;
  public errorSame = false;
}

@Component({
  selector: 'app-register-user',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    AddressComponent,
    UserDataComponent,
    RecaptchaModule,
    PrimeNGModule,
  ],
  templateUrl: './register-user.component.html',
  styleUrl: './register-user.component.scss',
  providers: [ToastService, MessageService],
})
export class RegisterUserComponent implements OnInit {
  public user: User = new User();
  public userError: UserError = new UserError();
  public address: Address = new Address();
  public addressDisable: AddressDisable = new AddressDisable();
  public addressError: AddressError = new AddressError();
  public captcha = false;
  public siteKey = environment.siteKeyGoogle;

  constructor(
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private metaServices: MetaTagsService,
    private toastService: ToastService,
    private userService: UserService
  ) {}

  ngOnInit() {
    const email = this.activatedRoute.snapshot.paramMap.get('email');
    if (email) {
      this.user.email = email.toString();
    }

    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_REGISTER,
        url[0]?.path,
        '',
        '',
        null
      );
    });
  }

  resolvedcCaptcha(captchaResponse: string | null) {
    this.captcha = captchaResponse ? true : false;
  }

  onClickRegister() {
    if (!this.captcha) {
      this.toastService.error(
        'Faça a validação de anti-spam e tente novamente!'
      );
      return;
    }

    const validUser = this.validaUser();
    const validAddress = this.validAddress();
    if (validUser && validAddress) {
      this.register();
    } else {
      this.toastService.error('É necessário preencher todos os campos!');
    }
  }

  async register() {
    try {
      this.loaderService.showLoader();
      if (!this.user.address) {
        this.address.principal = true;
        this.user.address = [];
        this.user.address.push(this.address);
      }
      this.user.document = this.user?.document?.replace(/\D/g, '');
      this.user.phone = this.user?.phone?.replace(/\D/g, '');
      await this.userService.save(this.user);
      this.loaderService.hideLoader();
      this.toastService.success('Cadastro realizado com sucesso!');
      await wait(TWO_SECONDS_IN_MS);
      this.userService.goToLogin();
    } catch (error: any) {
      this.loaderService.hideLoader();
      this.toastService.error(error || 'Erro ao cadastrar usuário!');
    }
  }

  validaUser() {
    let hasError = true;
    this.userError.errorName = !validName(this.user.name || '');
    this.userError.errorLastName = !validName(this.user.lastName || '');
    this.userError.errorEmail = !validEmail(this.user.email || '');
    this.userError.errorDocument = !validCPF(this.user.document || '');
    this.userError.errorPhone = !validPhone(this.user.phone || '');
    this.userError.errorBirthDate = !validBirthDate(this.user.birthDate || '');

    const msgPassword = validPassword(
      this.user.password || '',
      this.user.confirmPassword || ''
    );

    this.userError.msgPassword = msgPassword;
    this.userError.errorPassword = !!msgPassword;
    this.userError.errorConfirmPassword = !!msgPassword;

    hasError =
      this.userError.errorName ||
      this.userError.errorLastName ||
      this.userError.errorEmail ||
      this.userError.errorDocument ||
      this.userError.errorPhone ||
      this.userError.errorBirthDate ||
      this.userError.errorPassword ||
      this.userError.errorConfirmPassword;

    return !hasError;
  }

  validAddress() {
    let hasError = true;
    this.addressError.errorZipCode = !validZipCode(this.address.zipCode || '');
    this.addressError.errorAddress = !this.address.address;
    this.addressError.errorNumber = !this.address.numberAddress;
    this.addressError.errorComplement = !this.address.complement;
    this.addressError.errorDistrict = !this.address.district;
    this.addressError.errorUF = !this.address.uf;
    this.addressError.errorCity = !this.address.city;

    hasError =
      this.addressError.errorZipCode ||
      this.addressError.errorAddress ||
      this.addressError.errorNumber ||
      this.addressError.errorComplement ||
      this.addressError.errorDistrict ||
      this.addressError.errorUF ||
      this.addressError.errorCity;

    return !hasError;
  }
}
