import {
  APP_INITIALIZER,
  ApplicationConfig,
  provideZoneChangeDetection,
} from '@angular/core';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
} from '@angular/router';

import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { AppInfoService } from './services/app-info/app-info.service';

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

export function loadAppData(appInfoService: AppInfoService) {
  return () => {
    return new Promise<void>((resolve) => {
      appInfoService.loadData().subscribe((data) => {
        appInfoService.setGlobalAppInfo(data);
        resolve();
      });
    });
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      inMemoryScrollingFeature
    ),
    provideClientHydration(),
    provideHttpClient(withFetch()),
    provideAnimations(),
    AppInfoService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadAppData,
      deps: [AppInfoService],
      multi: true,
    },
  ],
};
