import { ResolveFn } from '@angular/router';
import { of } from 'rxjs';

export const homeResolver: ResolveFn<any> = (route, state) => {
  const videos = [
    {
      id: 'hJfnH5HLzKM',
      src: 'https://www.youtube.com/embed/hJfnH5HLzKM?si=SVM-SHrfmJx0tc2_&amp;controls=0',
      title: 'Chapas de Policarbonato',
      url: 'https://d20p8exwoll05u.cloudfront.net/catalogs/rm-guia-rapido-instalacao-kit-de-cobertura.pdf',
    },
    {
      id: 'NghFgHiH6YQ',
      src: 'https://www.youtube.com/embed/NghFgHiH6YQ?si=FSCF13FgHg7AUoAs&amp;controls=0',
      title: 'Persiana Rolo Blackout',
      url: 'https://d20p8exwoll05u.cloudfront.net/catalogs/manual-de-persianas-rm-policarbbonatos.pdf',
    },
    {
      id: '3mk3Sg6UUpE',
      src: 'https://www.youtube.com/embed/3mk3Sg6UUpE?si=OF6JDvKfY-IYHBhC&amp;controls=0',
      title: 'Toldo Cortina',
      url: 'https://d20p8exwoll05u.cloudfront.net/catalogs/rm-manual-toldo-cortina.jpg',
    },
  ];

  return of({ videos });
};
