<p-dialog
  header="Dados para pagamento"
  [modal]="true"
  [style]="{ width: '25vw', height: '61vh' }"
  [draggable]="false"
  [resizable]="false"
  (onHide)="closeModalPix()"
  [(visible)]="_visible"
>
  <div class="pix-modal-content">
    <div class="pix-modal-content-countdown" *ngIf="_showCountDown">
      <h3>O pedido será cancelado em:</h3>
      <app-countdown-timer
        [creationTime]="order?.createdAt || ''"
      ></app-countdown-timer>
    </div>

    <h2>QRCODE de pagamento no valor: {{ pix?.value | customCurrency }}</h2>
    <h3>
      Abra o app do seu banco, acesse a área pix e leia a imagem do QRCode ou
      copie e cole o link.
    </h3>
    <h3>
      Aguarde, que após o pagamento, a tela será atualizada automaticamente.
    </h3>
    <img
      [src]="pix?.base64QrCode"
      [alt]="'QRCode para pagamento pix'"
      [width]="200"
      [height]="200"
    />
    <button
      (click)="onClickCopyCodePix()"
      title="Copiar código pix para área de tranferência"
      class="button-rm"
      aria-label="Copiar código pix para área de tranferência"
    >
      COPIAR CÓDIGO
    </button>
  </div>
</p-dialog>
