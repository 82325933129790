import { Component, EventEmitter, Output } from '@angular/core';
import { PrimeNGModule } from '../../../primeng.module';

export class ProductShopOrder {
  id?: number;
  name?: string;
  attribute?: string;
  orderBy?: string;
}

@Component({
  selector: 'app-products-shop-order',
  standalone: true,
  imports: [PrimeNGModule],
  templateUrl: './products-shop-order.component.html',
  styleUrl: './products-shop-order.component.scss',
})
export class ProductsShopOrderComponent {
  public options: Array<ProductShopOrder>;
  public filterSelected: ProductShopOrder | undefined;

  @Output()
  public changed: EventEmitter<ProductShopOrder> =
    new EventEmitter<ProductShopOrder>();

  constructor() {
    this.options = [
      {
        id: 1,
        name: 'Menor Preço',
        attribute: 'value',
        orderBy: 'ASC',
      },
      {
        id: 2,
        name: 'Maior Preço',
        attribute: 'value',
        orderBy: 'DESC',
      },
    ];
    this.filterSelected = this.options[0];
  }

  optionSelected() {
    this.changed.emit(this.filterSelected);
  }
}
