export default class AttachmentProductLink {
  id?: number;
  url?: string;
  urlAPI?: string;
  orderDisplay?: number;
  type?: string;
  resized?: string;

  constructor(attachment?: any) {
    if (attachment) this.fromJson(attachment);
  }

  fromJson(attachment: any) {
    if (attachment != null) {
      this.id = attachment.id;
      this.url = attachment.url;
      this.orderDisplay = attachment.orderDisplay;
      this.type = attachment.type;
      this.urlAPI = attachment.urlAPI;
      this.resized = attachment.resized;
    }
  }
}
