export default class Phone {
  public areaCode?: string; // DDD do comprador
  public number?: string; // Número do telefone do comprador

  constructor(phone: string) {
    if (phone) {
      this.areaCode = phone.substring(0, 2);
      this.number = phone.substring(2);
    }
  }
}
