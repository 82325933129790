<div class="faq-main" *ngIf="faq.faqs">
  <div class="container">
    <div class="faq-video">
      <h2 class="section-title">{{ faq.titleVideo }}</h2>
      <h3><i class="pi pi-youtube"></i>{{ faq.subTitleVideo }}</h3>
      <app-youtube-video
        [videoId]="faq.videoId"
        [title]="faq.titleVideo"
        [url]="faq.urlVideo"
      ></app-youtube-video>

      <a href="{{ faq.urlCatalog }}" target="_blank">
        <p>
          {{ faq.titleCatalog }}
          <img
            src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/pdf-20.png"
            alt="Link - {{ faq.titleCatalog }}"
            width="20"
            height="20"
          />
        </p>
      </a>
    </div>
    <div class="faq-questions">
      <h2 class="section-title">{{ faq.titleFaq }}</h2>
      <div class="faq-questions-summary">
        <details *ngFor="let item of faq.faqs.contents || []">
          <summary>{{ item?.title }}</summary>
          <p
            *ngFor="let content of item.contents"
            [innerHTML]="content | safe : 1"
          ></p>
        </details>
      </div>
    </div>
  </div>
</div>
