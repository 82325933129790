import { Injectable } from '@angular/core';
import Address from '../../shared/models/user/address';
import extractNumbers from '../../shared/utils/extract-numbers';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor() {}

  public async getAddress(cep: string): Promise<Address> {
    try {
      const response = await window.fetch(
        `https://viacep.com.br/ws/${extractNumbers(cep)}/json/`
      );
      if (response.status !== 200) {
        throw response;
      }
      const json = await response.json();
      if (json.erro) {
        throw response;
      }

      const address = new Address();
      address.city = json.localidade || '';
      address.zipCode = json.cep || '';
      address.district = json.bairro || '';
      address.address = json.logradouro || '';
      address.ibgeCode = json.ibge || '';
      address.uf = json.uf || '';
      address.principal = true;
      return address;
    } catch (err) {
      throw err;
    }
  }
}
