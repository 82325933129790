import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-countdown-timer',
  standalone: true,
  imports: [],
  templateUrl: './countdown-timer.component.html',
  styleUrl: './countdown-timer.component.scss',
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  @Input()
  public creationTime?: string;

  public minutes: number;
  public seconds: number;
  public intervalId: any;
  public route?: string;

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute
  ) {
    this.minutes = 15;
    this.seconds = 0;
  }

  ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.route = url[0]?.path;
    });
    if (this.creationTime) {
      this.calculateRemainingTime();
      this.startTimer();
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  calculateRemainingTime() {
    const now = new Date().getTime();
    const orderTime = this.convertToDate(this.creationTime || '').getTime();

    const diff = 15 * 60 * 1000 - (now - orderTime); // 15 minutes in milliseconds

    if (diff > 0) {
      this.minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((diff % (1000 * 60)) / 1000);
    } else {
      this.minutes = 0;
      this.seconds = 0;
      clearInterval(this.intervalId);
    }
  }

  startTimer() {
    this.intervalId = setInterval(() => {
      if (this.seconds === 0) {
        if (this.minutes === 0) {
          clearInterval(this.intervalId);
          if (this.route === 'meus-pedidos') {
            window.location.reload();
          } else {
            this.userService.goToCustomerOrders();
          }
        } else {
          this.minutes--;
          this.seconds = 59;
        }
      } else {
        this.seconds--;
      }
    }, 1000);
  }

  formatTime(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  convertToDate(dateString: string): Date {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
  }
}
