[
  {
    "id": "POLICARBONATO_ALVEOLAR",
    "titleVideo": "Aprenda a Instalar",
    "subTitleVideo": "Chapas de Policarbonato Alveolar",
    "urlVideo": "https://www.youtube.com/embed/hJfnH5HLzKM?si=SVM-SHrfmJx0tc2_&amp;controls=0",
    "urlCatalog": "https://d20p8exwoll05u.cloudfront.net/catalogs/rm-guia-rapido-instalacao-kit-de-cobertura.pdf",
    "titleCatalog": "Donwload do manual e PDF",
    "titleFaq": "Perguntas Frequentes",
    "faqs": {
      "contents": [
        {
          "title": "O que é Chapa de Policarbonato Alveolar?",
          "contents": [
            "As chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-alveolar\">Policarbonato Alveolar</a> são um tipo de chapa com <strong>aspecto de vidro canelado</strong>, que possuem cavidades ocas (alvéolos) que reduzem consideravelmente seu peso e a quantidade de material utilizada em sua confecção. Por serem leves, possuem um custo mais acessível se comprado ao Policarbonato compacto. "
          ]
        },
        {
          "title": "Quais as vantagens da Chapa de Policarbonato Alveolar em comparação a outros tipos de telhas?",
          "contents": [
            "<strong>Isolamento térmico:</strong> as cavidades ocas (alvéolos) proporcionam um melhor isolamento térmico em comparação com chapas de Policarbonato compacto, ajudando a reduzir a transferência de calor. Nós da RM Policarbonatos trabalhamos com chapas Alveolares refletivas que evitam o efeito estufa, fazem sombra bloqueando de 4°C a 5°C da temperatura, nas cores cinza refletivo e branco pérola refletivo. ",
            "<strong>Leveza:</strong> a parte oca torna as chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-alveolar\">Policarbonato Alveolar</a> mais leves do que as chapas compactas, que as telhas termo acústicas, aluzinco ou telhas comuns facilitando o manuseio e a instalação.",
            "<strong>Transmissão de luz difusa:</strong> o aspecto de vidro canelado que os alvéolos proporcionam a chapa ajudam a difundir a luz, trazendo uma distribuição mais uniforme da iluminação natural, reduzindo sombras e brilhos excessivos.",
            "<strong>Aspecto visual:</strong> diferente das telhas de fibra comum, telhas plásticas o Policarbonato tem um acabamento estético clean, se adapta a vários tipos de projeto desde residenciais, comerciais, rústicos ou modernos. Tudo depende de como será aplicado."
          ]
        },
        {
          "title": "Onde as chapas de Policarbonato Alveolar podem ser aplicadas?",
          "contents": [
            "Pergolados | Coberturas em geral | Telhados comerciais e residenciais | Abrigos para veículos | Passarelas | Divisórias e paredes de separação em ambientes internos | Painéis de proteção cústica | Displays e sinalizações"
          ]
        },
        {
          "title": "Qual espessura de Chapa de Policarbonato Alveolar devo utilizar?",
          "contents": [
            "Aqui na <a target=\"_blank\" href=\"/\">RM Policarbonatos & Acessórios</a>, nós trabalhamos com <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-alveolar\">Policarbonato Alveolar</a> em epessuras: 4mm, 6mm e 10mm.",
            "A escolha da espessura adequada depende da aplicação, levando em consideração o tamanho da <strong>área a ser coberta, e espaçamento entre as travessas da estrutura para que não corra o risco</strong> de infiltração e “barriga” nas chapas.",
            "É necessário respeitar um espaçamento entre os caibros/travessas:",
            "Chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-alveolar\">Policarbonato Alveolar 4mm</a> - até 50cm de espessamento",
            "Chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-alveolar\">Policarbonato Alveolar 6mm</a> - até 70cm de espaçamento ",
            "Chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-alveolar\">Policarbonato Alveolar 10mm</a> - até 1m de espaçamento",
            "Veja mais sobre isso <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-catalogo-policarbonato-e-guia-de-instalacao.pdf\">clicando aqui!</a>"
          ]
        },
        {
          "title": "Como cotar Chapa de Policarbonato Alveolar?",
          "contents": [
            "As chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-alveolar\">Policarbonato Alveolar</a> são extremamente fáceis de cotar. Utilize ferramentas como estilete, serra tico tico ou serras circulares com lâminas finas, para cortar as chapas com precisão. Tenha cuidado para evitar lascas ou danos às bordas das chapas durante o corte."
          ]
        },
        {
          "title": "Como emendar chapas de Policarbonato Alveolar?",
          "contents": [
            "Para a instalação ser feita de forma correta as chapas devem ser emendadas utilizando o <a target=\"_blank\" href=\"/perfis-em-aluminio/perfis-fixacao-acabamento\">Perfil Trapézio</a> + <a target=\"_blank\" href=\"/acessorios-de-instalacao/fitas-selantes\">Gaxeta</a>. Essa emenda deve ser feita sempre em cima de um caibro/travessas, no mesmo sentido dos alvéolos da chapa. ",
            "Caso seja necessário emendar no sentido do avanço/caída água (contra os alvéolos), essa emenda por ser feita <a target=\"_blank\" href=\"/perfis-em-aluminio/perfis-fixacao-acabamento\">Perfil H</a> + <a target=\"_blank\" href=\"/acessorios-de-instalacao/fitas-selantes\">Fitas de Vedação</a> + <a target=\"_blank\" href=\"/acessorios-de-instalacao/fitas-selantes\">Silicone</a>. Essa emenda tambem deve ser apoiada em cima de um caibro/travessa."
          ]
        },
        {
          "title": "Qual a garantia das chapas de Policarbonato Alveolar?",
          "contents": [
            "Aqui na <a target=\"_blank\" href=\"/\">RM Policarbonatos & Acessórios</a>, nós trabalhamos com <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-alveolar\">Chapas de Policarbonato</a> <strong>fabricadas com matéria prima 100% virgem e tratamento UV.</strong> Com isso garantimos <strong>10 anos de garantia contra amarelamento e ressecamento nas chapas.</strong>",
            "Para que as chapas tenham garantia é importante seguir o <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-guia-rapido-instalacao-kit-de-cobertura.pdf\"> passo a passo de instalação </a> corretamente, assim como em caso de abertura de garantia enviar a amostra do material utilizado para análise. Você pode ver mais sobre a garantia e como aciona-la <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-certificado-garantia.pdf\"> clicando aqui.</a>"
          ]
        },
        {
          "title": "Como instalar Chapa de Policarbonato Alveolar?",
          "contents": [
            "Verifique as instruções de instalação que nós da <a target=\"_blank\" href=\"/\">RM Policarbonatos & Acessórios</a> preparamos para você <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-guia-rapido-instalacao-kit-de-cobertura.pdf\"> clicando aqui </a>, ou assista o vídeo rápido <a target=\"_blank\" href=\"https://youtu.be/hJfnH5HLzKM\"> clicando aqui </a>"
          ]
        }
      ]
    }
  },
  {
    "id": "POLICARBONATO_COMPACTO",
    "titleVideo": "Aprenda a Instalar",
    "subTitleVideo": "Chapas de Policarbonato Compacto",
    "urlVideo": "https://www.youtube.com/embed/hJfnH5HLzKM?si=SVM-SHrfmJx0tc2_&amp;controls=0",
    "urlCatalog": "https://d20p8exwoll05u.cloudfront.net/catalogs/rm-guia-rapido-instalacao-kit-de-cobertura.pdf",
    "titleCatalog": "Donwload do manual e PDF",
    "titleFaq": "Perguntas Frequentes",
    "faqs": {
      "contents": [
        {
          "title": "O que são chapas de Policarbonato Compacto?",
          "contents": [
            "As chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-compacto\">Policarbonato Compacto</a> são um tipo de chapa de Policarbonato sólida e transparente, totalmente maciça. Elas possuem alta resistência ao impacto, transparência e durabilidade. Seu aspecto visual se assemelha ao vidro."
          ]
        },
        {
          "title": "Quais são as vantagens das chapas de Policarbonato Compacto?",
          "contents": [
            "As chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-compacto\">Policarbonato Compacto</a> oferecem várias vantagens, tais como:",
            "<strong>Alta resistência ao impacto:</strong> são aproximadamente 200 vezes mais resistentes que o vidro, tornando-as ideais para aplicações que exigem segurança e proteção contra impactos.",
            "<strong>Transparência:</strong> as chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-compacto\">Policarbonato Compacto</a> possuem excelente transmissão de luz, permitindo a passagem de luz natural sem distorções significativas.",
            "<strong>Leveza:</strong> são mais leves que o vidro, o que facilita a manipulação, transporte e instalação.",
            "<strong>Flexibilidade:</strong> podem ser facilmente moldadas em diferentes formas e curvas, permitindo uma ampla gama de aplicações.",
            "<strong>Resistência a intempéries:</strong> possuem resistência UV e são altamente duráveis, não amarelando ou deteriorando facilmente com a exposição ao sol e às intempéries."
          ]
        },
        {
          "title": "Onde as chapas de Policarbonato Compacto podem ser aplicadas?",
          "contents": [
            "Pergolados.",
            "Coberturas em geral.",
            "Claraboias em edifícios comerciais e residenciais.",
            "Janelas de segurança, portas e divisórias.",
            "Proteção de máquinas e equipamentos industriais.",
            "Vitrines e displays de lojas.",
            "Componentes de iluminação, como luminárias e luminosos.",
            "Equipamentos esportivos, como escudos e proteções."
          ]
        },
        {
          "title": "Como escolher a espessura o Policarbonato Compacto?",
          "contents": [
            "Aqui na RM Policarbonatos e Acessórios, nós trabalhamos com <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-compacto\">Policarbonato Compacto</a> nas seguintes espessuras: 3mm, 4mm, 6mm, 8mm, 10mm e 12mm.",
            "A escolha da espessura adequada depende da aplicação da chapa. Para aplicação em coberturas deve levar em consideração o  <strong>área a ser coberta, e espaçamento entre as travessas da estrutura para que não corra o risco</strong> de infiltração e \"barriga\" nas chapas.",
            "Para aplicação em coberturas é necessário respeitar um espaçamento entre os caibros/travessas.",
            "- Chapas de 3mm - até 50cm de espessamento",
            "- Chapas de 4mm - até 60m de espaçamento ",
            "- Chapas de 6mm - até 1m de espaçamento",
            "- Chapas de 8mm, 10mm e 12mm são indicadas para encasulamento de máquinas e outros tipos de proteções."
          ]
        },
        {
          "title": "Como cotar chapa de Policarbonato Compacto?",
          "contents": [
            "As chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-compacto\">Policarbonato Compacto</a> podem ser cortadas com serra tico tico ou serras circulares com lâminas finas, para cortar as chapas com precisão. Tenha cuidado para evitar lascas ou danos às bordas das chapas durante o corte."
          ]
        },
        {
          "title": "Como emendar chapas de Policarbonato Compacto?",
          "contents": [
            "Para a instalação ser feita de forma correta as chapas devem ser emendadas utilizando o <strong>perfil trapézio + gaxeta</strong>. Essa emenda deve ser feita sempre em cima de um caibro/travessas, no mesmo sentido dos alvéolos da chapa.",
            "Caso seja necessário emendar no sentido do avanço/caída água (contra os alvéolos), essa emenda por ser feita <strong>com perfil H + silicone.</strong> Essa emenda também deve ser apoiada em cima de um caibro/travessa."
          ]
        },
        {
          "title": "Como são instaladas as chapas de Policarbonato Compacto?",
          "contents": [
            "- Verifique as instruções de instalação que nós da <a target=\"_blank\" href=\"/\">RM Policarbonatos & Acessórios</a> preparamos para você <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-guia-rapido-instalacao-kit-de-cobertura.pdf\"> clicando aqui </a>, ou assista o vídeo rápido <a target=\"_blank\" href=\"https://youtu.be/hJfnH5HLzKM\"> clicando aqui </a>",
            "- Preparação da estrutura: Verifique se a estrutura de suporte está devidamente preparada e nivelada para a instalação das chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-compacto\">Policarbonato Compacto</a>. Certifique-se de que a estrutura esteja com o espaçamento adequado para a espessura de chapa escolhida, que esteja limpa e livre de qualquer obstáculo que possa interferir na instalação.",
            "- Fixação das chapas: Posicione as chapas de <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-compacto\">Policarbonato Compacto</a> nas estruturas de suporte, alinhando-as corretamente. Utilize perfis de fixação adequados, como o perfil de trapézio + gaxeta, para fixar as chapas na estrutura. Certifique-se de que as fixações estejam distribuídas uniformemente ao longo das bordas das chapas para garantir uma instalação segura.",
            "- Vedação das extremidades: diferente do <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-compacto\">Policarbonato Compacto</a>, na instalação do <a target=\"_blank\" href=\"/coberturas-em-policarbonato/policarbonato-compacto\">Policarbonato Compacto</a> não há necessidade de vedação das extremidades pois a peça é maciça. Basta apenas aplicar o perfil U de acabamento.",
            "- Finalização da instalação: Verifique se todas as chapas estão devidamente fixadas e alinhadas. Faça os ajustes necessários para garantir um encaixe perfeito. Limpe as chapas e a área circundante para remover qualquer sujeira ou resíduo da instalação. E finalize a vedação com silicone neutro."
          ]
        },
        {
          "title": "As chapas de Policarbonato Compacto possuem garantia?",
          "contents": [
            "Aqui na RM Policarbonato e Acessórios, nós trabalhamos com chapas de Policarbonato fabricadas com matéria prima 100% virgem e tratamento UV. Com isso garantimos 10 anos de garantia contra amarelamento e ressecamento nas chapas.",
            "Para que as chapas tenham garantia é importante seguir o <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-guia-rapido-instalacao-kit-de-cobertura.pdf\"> passo a passo de instalação </a> corretamente, assim como em caso de abertura de garantia enviar a amostra do material utilizado para análise. Você pode ver mais sobre a garantia e como aciona-la <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-certificado-garantia.pdf\"> clicando aqui.</a>"
          ]
        }
      ]
    }
  },
  {
    "id": "ROLO",
    "titleVideo": "Aprenda a Instalar",
    "subTitleVideo": "Persianas Rolo",
    "urlVideo": "https://www.youtube.com/embed/dV0einSDzWg?si=GjKM1DTgQJgGRWw0_&amp;controls=0",
    "urlCatalog": "https://d20p8exwoll05u.cloudfront.net/catalogs/manual-de-persianas-rm-policarbbonatos.pdf",
    "titleCatalog": "Donwload do manual e PDF",
    "titleFaq": "Perguntas Frequentes",
    "faqs": {
      "contents": [
        {
          "title": "1 - O que acompanha as Persianas rolo?",
          "contents": [
            "Enviamos tudo o que você precisa para instalar a persiana: suportes, buchas, parafusos e um manual completo."
          ]
        },
        {
          "title": "2 - Qual Persiana Rolo devo escolher para o meu ambiente e quais as diferenças?",
          "contents": [
            "Para te ajudar a escolher o melhor modelo para o seu ambiente nos preparamos uma descrição de cada um! <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-catalogo-persianas.pdf\">Acesse clicando aqui.</a>"
          ]
        },
        {
          "title": "3 - Como instalar as Persianas Rolo?",
          "contents": [
            "A instalação é fácil e intuitiva, e você mesmo pode fazer!",
            "1. Meça e marque os pontos de fixação.",
            "2. Fixe os suportes na parede ou teto.",
            "3. Encaixe a persiana nos suportes.",
            "4. Verifique o nivelamento e alinhamento.",
            "5. Teste o mecanismo de abertura e fechamento.",
            "6. Faça ajustes finais, se necessário.",
            "Verifique as instruções de instalação que nós da <a target=\"_blank\" href=\"/\">RM Policarbonatos & Acessórios</a> preparamos para você <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-guia-rapido-instalacao-kit-de-cobertura.pdf\">clicando aqui</a>, ou assista o vídeo rápido <a target=\"_blank\" href=\"https://youtu.be/dV0einSDzWg\">clicando aqui</a>."
          ]
        },
        {
          "title": "4 - Qual o prazo de fabricação da Persiana Rolo?",
          "contents": [
            "Aqui na <a target=\"_blank\" href=\"/\">RM Policarbonatos & Acessórios</a> as <a target=\"_blank\" href=\"/persianas/double-vision\">Persianas Double Vision</a> são produzidas sob medida para o seu ambiente!",
            "<strong>O prazo de fabricação é 5 a 7 dias úteis</strong>, mas fique tranquilo! Esse prazo já está sendo somado no prazo de entrega no momento da cotação do frete. ",
            "Data que você verá em tela, será a data final para a persiana chegar no seu endereço!"
          ]
        },
        {
          "title": "5 - Caso duas Persianas Rolo sejam instaladas lado a lado, qual será o espaço sem tecido entre elas?",
          "contents": [
            "Instalando as persianas lado a lado, de forma que os suportes delas se encostem, o espaço sem tecido entre elas será 3cm a 3,5 cm."
          ]
        },
        {
          "title": "6 - Quais são as cores dos bandôs e comandos das Persianas Rolo?",
          "contents": [
            "As <a target=\"_blank\" href=\"/persianas/rolo\">Persianas Rolo</a> possuem como opcional <a target=\"_blank\" href=\"/produtos/bando\">bandô de acabamento</a> e estão disponíveis em duas cores. Se desejar, basta adquirir um <a target=\"_blank\" href=\"/produtos/bando\">Bandô com as mesmas medidas da persiana escolhida.</a> E não se preocupe com a instalação, o Bandô já vem pronto para uso junto com a persiana, por isso, ao optar pelos acabamentos, cada persiana acompanhará acessórios (bandô e comando) de acordo com a tonalidade do tecido escolhido, como apresentado na relação abaixo:",
            "<a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Blackout%20Branca\">Persiana Rolo Branca</a> - acompanha base inferior e acessórios na cor BRANCA",
            "<a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Blackout%20Bege\">Persiana Rolo Bege</a> - acompanha base inferior e acessórios na cor BRANCA",
            "<a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Blackout%20Cinza\">Persiana Rolo Cinza</a> - acompanha base inferior e acessórios na cor BRANCA",
            "<a target=\"_blank\" href=\"/produtos/Persiana%20Rolo%20Blackout%20Pinpoint%20Preta\">Persiana Rolo Preta</a> - acompanha base inferior e acessórios na cor PRETA"
          ]
        },
        {
          "title": "7 - Como devem ser tiradas as medidas para instalação fora do vão da janela?",
          "contents": [
            "Para instalações de persianas fora do vão da janela, meça a largura e altura da janela e, ao fazer o pedido, adicione pelo menos 10cm a cada uma das dimensões das persianas."
          ]
        },
        {
          "title": "8 - Como devem ser tiradas as medidas para instalação dentro do vão da janela?",
          "contents": [
            "Para instalações de persianas dentro do vão da janela, meça as dimensões da janela e, ao fazer o pedido, reduza 1cm da largura e da altura das persianas."
          ]
        },
        {
          "title": "9 - Posso automatizar o controle das persianas Rolo?",
          "contents": [
            "Sim! Aqui na <a target=\"_blank\" href=\"/\">RM Policarbonatos & Acessórios</a> você irá encontrar os anúncios por padrão com <strong>acionamento manual</strong>, mas no momento da compra é possível selecionar a opção de <strong>motorização</strong>. ",
            "<strong>As opções que temos são: </strong>",
            "<strong>1 - Acionamento manual:</strong> o comando se dá por meio de uma pequena corrente de bolinhas, que permite a coleta dos tecidos até que o batente seja alcançado e bloqueado. Por padrão, o acionamento é sempre posicionado para o lado direto da peça. ",
            "<strong>2 - Acionamento motorizado:</strong> permite que os tecidos sejam recolhidos ou abaixados ao toque de um botão, tornando o uso da cortina mais prático. Por padrão, o lado de saída do fio do motor é sempre posicionado para o lado direto da peça."
          ]
        },
        {
          "title": "10- Qual a garantia das persianas?",
          "contents": [
            "A garantia para as <a target=\"_blank\" href=\"/persianas/rolo\">Persianas Rolo</a> é de 2 anos, cobrindo defeitos de fabricação. Esta garantia se aplica apenas aos produtos que possuam a etiqueta de fabricação e a identificação da empresa. É importante ressaltar que a garantia é efetivada somente na fábrica, não sendo viável o deslocamento de técnicos para realizar assistência no local."
          ]
        }
      ]
    }
  },
  {
    "id": "DOUBLE_VISION",
    "titleVideo": "Aprenda a Instalar",
    "subTitleVideo": "Persianas Double Vision",
    "urlVideo": "https://www.youtube.com/embed/xQOhQh_EWbQ?si=_R3G98r4-EEUGPXs_&amp;controls=0",
    "urlCatalog": "https://d20p8exwoll05u.cloudfront.net/catalogs/manual-de-persianas-rm-policarbbonatos.pdf",
    "titleCatalog": "Donwload do manual e PDF",
    "titleFaq": "Perguntas Frequentes",
    "faqs": {
      "contents": [
        {
          "title": "1 - O que acompanha as Persianas Double Vision?",
          "contents": [
            "Enviamos tudo o que você precisa para instalar a persiana: suportes, buchas, parafusos e um manual completo."
          ]
        },
        {
          "title": "2 - Qual Persiana Double Vision devo escolher para o meu ambiente e quais as diferenças?",
          "contents": [
            "Para te ajudar a escolher o melhor modelo para o seu ambiente nós preparamos uma descrição de cada um! <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-catalogo-persianas.pdf\">Acesse clicando aqui!</a>"
          ]
        },
        {
          "title": "3- Qual o prazo de fabricação da Persiana Double Vision?",
          "contents": [
            "Aqui na <a target=\"_blank\" href=\"/\">RM Policarbonatos & Acessórios</a> as <a target=\"_blank\" href=\"/persianas/double-vision\">Persianas Double Vision</a> são produzidas sob medida para o seu ambiente! ",
            "<strong>O prazo de fabricação é 5 a 7 dias úteis</strong>, mas fique tranquilo! Esse prazo já está sendo somado no prazo de entrega no momento da cotação do frete. ",
            "Data que você verá em tela, será a data final para a persiana chegar no seu endereço!"
          ]
        },
        {
          "title": "4 - Como Funcionam as Persianas Double Vision?",
          "contents": [
            "Este modelo possui tecidos sobrepostos em listras de tecido opaco que se intercalam com tela. Une Blackout + Translucido permitindo flexibilidade no controle da luz sem ter que recolher a persiana por completo.",
            "Ao ajustar as camadas de tecido, é possível obter diferentes níveis de privacidade, mantendo também a visão externa."
          ]
        },
        {
          "title": "5 - Quais são as cores dos bandôs e comandos das Persianas Double Vision?",
          "contents": [
            "As <a target=\"_blank\" href=\"/persianas/double-vision\">Persianas Double Vision</a> possuem como opcional <a target=\"_blank\" href=\"/persianas/acessorios-persianas\">bandô de acabamento</a> e estão disponíveis em quatro cores. ",
            "Por isso, ao optar pelos acabamentos, cada persiana acompanhará acessórios (bandô e comando) de acordo com a tonalidade do tecido escolhido, como apresentado na relação abaixo:",
            "<a target=\"_blank\" href=\"/produtos/Persiana%20Double%20Vision%20Branca\">Persiana Double Vision Branca</a> - acompanha base inferior e acessórios na cor BRANCA",
            "<a target=\"_blank\" href=\"/produtos/Persiana%20Double%20Vision%20Bege\">Persiana Double Vision Bege</a> - acompanha base inferior e acessórios na cor BRANCA",
            "<a target=\"_blank\" href=\"/produtos/Persiana%20Double%20Vision%20Cinza\">Persiana Double Vision Cinza</a> - acompanha base inferior e acessórios na cor BRANCA",
            "<a target=\"_blank\" href=\"/produtos/Persiana%20Double%20Vision%20Preto\">Persiana Double Vision Preta</a>- acompanha base inferior e acessórios na cor PRETA"
          ]
        },
        {
          "title": "6 - Caso duas Persianas Rolo sejam instaladas lado a lado, qual será o espaço sem tecido entre elas?",
          "contents": [
            "Instalando as persianas lado a lado, de forma que os suportes delas se encostem, o espaço sem tecido entre elas será 3cm a 3,5 cm."
          ]
        },
        {
          "title": "7 - Como faço para manter e limpar as persianas Double Vision?",
          "contents": [
            "A limpeza é simples. Utilize um aspirador de pó e se necessário um pano levemente úmido. Evite produtos abrasivos."
          ]
        },
        {
          "title": "8 - Posso automatizar o controle das persianas Double Vision?",
          "contents": [
            "Sim! Aqui na <a target=\"_blank\" href=\"/\">RM Policarbonatos & Acessórios</a> você irá encontrar os anúncios por padrão com <strong>acionamento manual</strong>, mas no momento da compra é possível selecionar a opção de <strong>motorização</strong>. ",
            "<strong>As opções que temos são:</strong>",
            "<strong>1 - Acionamento manual:</strong> o comando se dá por meio de uma pequena corrente de bolinhas, que permite a coleta dos tecidos até que o batente seja alcançado e bloqueado. Por padrão, o acionamento é sempre posicionado para o lado direto da peça. ",
            "<strong>2 - Acionamento motorizado:</strong> permite que os tecidos sejam recolhidos ou abaixados ao toque de um botão, tornando o uso da cortina mais prático. Por padrão, o lado de saída do fio do motor é sempre posicionado para o lado direto da peça."
          ]
        },
        {
          "title": "9 - Qual a garantia das Persianas Double Vision?",
          "contents": [
            "A garantia para as <a target=\"_blank\" href=\"/persianas/double-vision\">Persianas Double Vision</a> é de 2 anos, cobrindo defeitos de fabricação. Esta garantia se aplica apenas aos produtos que possuam a etiqueta de fabricação e a identificação da empresa. É importante ressaltar que a garantia é efetivada somente na fábrica, não sendo viável o deslocamento de técnicos para realizar assistência no local."
          ]
        },
        {
          "title": "10 - Como instalar as Persianas Double Vision?",
          "contents": [
            "A instalação é fácil e intuitiva, e você mesmo pode fazer!",
            "1 - Meça e marque os pontos de fixação.",
            "2 - Fixe os suportes na parede ou teto.",
            "3 - Encaixe a persiana nos suportes.",
            "4 - Verifique o nivelamento e alinhamento.",
            "5 - Teste o mecanismo de abertura e fechamento.",
            "6 - Faça ajustes finais, se necessário.",
            "Verifique as instruções de instalação que nós da <a target=\"_blank\" href=\"/\">RM Policarbonatos & Acessórios</a> preparamos para você <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/manual-de-persianas-rm-policarbbonatos.pdf\">clicando aqui</a>, ou assista o vídeo rápido <a target=\"_blank\" href=\"https://youtu.be/xQOhQh_EWbQ\">clicando aqui</a>."
          ]
        }
      ]
    }
  },
  {
    "id": "ROMANA",
    "titleVideo": "Aprenda a Instalar",
    "subTitleVideo": "Persianas Romana",
    "urlVideo": "https://www.youtube.com/embed/LA4NNXoFzOY?si=fHiLBhw2is9t0zrN_&amp;controls=0",
    "urlCatalog": "https://d20p8exwoll05u.cloudfront.net/catalogs/manual-de-persianas-rm-policarbbonatos.pdf",
    "titleCatalog": "Donwload do manual e PDF",
    "titleFaq": "Perguntas Frequentes",
    "faqs": {
      "contents": [
        {
          "title": "1 - O que acompanha as Persianas Romana?",
          "contents": [
            "Enviamos tudo o que você precisa para instalar a persiana: suportes, buchas, parafusos e um manual completo."
          ]
        },
        {
          "title": "2 - As Persianas Romana Possuem Bandô de acabamento?",
          "contents": [
            "As persianas romanas não possuem <a target=\"_blank\" href=\"/persianas/acessorios-persianas\">bandô de acabamento</a>, mas o seu acabamento é slim e discreto. O <a target=\"_blank\" href=\"/persianas/acessorios-persianas\">bandô</a> pode ser adicionado nos modelos <a target=\"_blank\" href=\"/persianas/rolo\">Rolo</a> e <a target=\"_blank\" href=\"/persianas/double-vision\">Double Vision.</a>"
          ]
        },
        {
          "title": "3 - Qual Persiana Romana devo escolher para o meu ambiente e quais as diferenças?",
          "contents": [
            "Para te ajudar a escolher o melhor modelo para o seu ambiente nos preparamos uma descrição de cada um!  <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/rm-catalogo-persianas.pdf\">Acesse clicando aqui!</a>"
          ]
        },
        {
          "title": "4 - Como instalar as Persianas Romana?",
          "contents": [
            "A instalação é fácil e intuitiva, e você mesmo pode fazer!",
            "1 - Meça e marque os pontos de fixação.",
            "2 - Fixe os suportes na parede ou teto.",
            "3 - Encaixe a persiana nos suportes.",
            "4 - Verifique o nivelamento e alinhamento.",
            "5 - Teste o mecanismo de abertura e fechamento.",
            "6 - Faça ajustes finais, se necessário.",
            "Verifique as instruções de instalação que nós da <a target=\"_blank\" href=\"/\">RM Policarbonatos & Acessórios</a> preparamos para você <a target=\"_blank\" href=\"https://d20p8exwoll05u.cloudfront.net/catalogs/manual-de-persianas-rm-policarbbonatos.pdf\">clicando aqui</a>, ou assista o vídeo rápido <a target=\"_blank\" href=\"https://youtu.be/LA4NNXoFzOY\">clicando aqui</a>."
          ]
        },
        {
          "title": "5 - Qual o prazo de fabricação da Persiana Romana?",
          "contents": [
            "Aqui na <a target=\"_blank\" href=\"/\">RM Policarbonatos & Acessórios</a> as <a target=\"_blank\" href=\"/persianas/romana\">Persianas Romanas</a> são produzidas sob medida para o seu ambiente! O prazo de fabricação é 5 a 7 dias úteis, mas fique tranquilo! Esse prazo já está sendo somado no prazo de entrega no momento da cotação do frete. Data que você verá em tela, será a data final para a persiana chegar no seu endereço!"
          ]
        },
        {
          "title": "6 - Caso duas Persianas Romana sejam instaladas lado a lado, qual será o espaço sem tecido entre elas?",
          "contents": [
            "Instalando as persianas lado a lado, de forma que os suportes delas se encostem, o espaço sem tecido entre elas será 3cm a 3,5 cm."
          ]
        },
        {
          "title": "7 - Como devem ser tiradas as medidas para instalação fora do vão da janela?",
          "contents": [
            "Para instalações de persianas fora do vão da janela, meça a largura e altura da janela e, ao fazer o pedido, adicione pelo menos 10cm a cada uma das dimensões das persianas."
          ]
        },
        {
          "title": "8 - Como devem ser tiradas as medidas para instalação dentro do vão da janela?",
          "contents": [
            "Para instalações de persianas dentro do vão da janela, meça as dimensões da janela e, ao fazer o pedido, reduza 1cm da largura e da altura das persianas."
          ]
        },
        {
          "title": "9 - Posso automatizar o controle das persianas Romana?",
          "contents": [
            "Sim! Aqui na <a target=\"_blank\" href=\"/\">RM Policarbonatos & Acessórios</a> você irá encontrar os anúncios por padrão com acionamento manual, mas no momento da compra é possível selecionar a opção de motorização. ",
            "As opções que temos são: ",
            "1 - Acionamento manual: o comando se dá por meio de uma pequena corrente de bolinhas, que permite a coleta dos tecidos até que o batente seja alcançado e bloqueado. Por padrão, o acionamento é sempre posicionado para o lado direto da peça. ",
            "2 - Acionamento motorizado: permite que os tecidos sejam recolhidos ou abaixados ao toque de um botão, tornando o uso da cortina mais prático. Por padrão, o lado de saída do fio do motor é sempre posicionado para o lado direto da peça."
          ]
        },
        {
          "title": "10- Qual a garantia das persianas",
          "contents": [
            "A garantia para as Persianas Romana é de 2 anos, cobrindo defeitos de fabricação. Esta garantia se aplica apenas aos produtos que possuam a etiqueta de fabricação e a identificação da empresa. É importante ressaltar que a garantia é efetivada somente na fábrica, não sendo viável o deslocamento de técnicos para realizar assistência no local."
          ]
        }
      ]
    }
  }
]