export class ValueUtils {
  static currency(value: number): string {
    if (value > 0) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      return `R$ ${formatter.format(value)}`;
    } else {
      return 'R$ 0,00';
    }
  }
}
