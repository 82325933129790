import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

import {
  ConfirmationService,
  MessageService,
  PrimeNGConfig,
} from 'primeng/api';
import { PrimeNGModule } from './primeng.module';
import { PagSeguroService } from './services/pag-seguro/pag-seguro.service';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { LoaderComponent } from './shared/components/loader/loader.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    ConfirmDialogComponent,
    PrimeNGModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [MessageService, ConfirmationService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppComponent implements OnInit {
  public previousUrl?: string;

  constructor(
    private pagSeguroService: PagSeguroService,
    private primeNgConfig: PrimeNGConfig,
    private router: Router
  ) {}

  ngOnInit() {
    this.primeNgConfig.ripple = true;
    this.primeNgConfig.setTranslation({
      ...this.primeNgConfig.translation,
      aria: {
        ...this.primeNgConfig.translation.aria,
        pageLabel: 'Indicador para a imagem {page}',
        slideNumber: 'Item {slideNumber}',
      },
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.previousUrl === '/pagamento') {
          this.pagSeguroService.removeIframe();
        } else if (event.url === '/pagamento') {
          this.pagSeguroService.addIframe();
        }
        this.previousUrl = event.url;
      }
    });
  }
}
