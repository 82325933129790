import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WhatsAppData } from '../../shared/components/whats-app/whats-app.component';
import { Product } from '../../shared/models/product/product';
import WhatsApp from '../../shared/models/whatsapp/whats-app';
import WhatsAppFilter from '../../shared/models/whatsapp/whats-app-filter';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class WhatsAppService {
  public openWhatsAppProduct$ = new BehaviorSubject<Product | null>(null);
  public openWhatsApp$ = new BehaviorSubject<boolean>(false);

  constructor(private httpService: HttpService) {}

  setOpenWhatsAppProduct(value: Product | null) {
    this.openWhatsAppProduct$.next(value);
  }

  setOpenWhatsApp(show: boolean) {
    this.openWhatsApp$.next(show);
  }

  getOpenWhatsAppProduct() {
    return this.openWhatsAppProduct$.asObservable();
  }

  getOpenWhatsApp() {
    return this.openWhatsApp$.asObservable();
  }

  public list(filter: WhatsAppFilter): Promise<Array<WhatsApp>> {
    const lsWhats: Array<WhatsApp> = [];
    return new Promise((resolve, reject) => {
      this.httpService
        .post(`request-contact-whatsapp/pageable/list/`, filter)
        .subscribe({
          next: (res: any) => {
            if (res?.content) {
              let newWhats: WhatsApp;
              res?.content?.forEach((whatsBanco: any) => {
                newWhats = Object.assign(new WhatsApp(), whatsBanco);
                lsWhats.push(newWhats);
              });
              resolve(lsWhats);
            }
          },
          error: (error: any) => {
            const msg =
              error?.error?.message ||
              error?.error?.error ||
              'Ocorreu um erro ao buscar a lista de WhatsApp!';
            reject(msg);
          },
        });
    });
  }

  public save(whatsAppData: WhatsAppData): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService
        .post(`request-contact-whatsapp/`, whatsAppData)
        .subscribe({
          next: (res: any) => {
            if (res) {
              resolve(true);
            }
          },
          error: (error: any) => {
            const msg =
              error?.error?.message ||
              error?.error?.error ||
              'Ocorreu um erro ao cadastrar o WhatsApp!';
            reject(msg);
          },
        });
    });
  }

  public sendToPloomes(whatsAppData: WhatsAppData): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService
        .post(`ploomes/contact-whatsapp/`, whatsAppData)
        .subscribe({
          next: (res: any) => {
            if (res) {
              resolve(true);
            }
          },
          error: (error: any) => {
            const msg =
              error?.error?.message ||
              error?.error?.error ||
              'Ocorreu um erro ao criar negócio do WhatsApp na Ploomes!';
            reject(msg);
          },
        });
    });
  }
}
