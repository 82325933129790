<div class="no-itens" *ngIf="_itensCart?.length === 0">
  Nenhum produto adicionado ao seu carrinho!
</div>
<div class="cart-products" *ngIf="(_itensCart?.length || 0) > 0">
  <div class="checkout-popup-title">Produtos adicionados ao carrinho</div>
  <div class="product-checkout-list">
    <a
      class="product-checkout-popup"
      href="{{ item?.product?.url || item?.product?.url }}"
      (click)="
        onClickOpenProduct(item?.product?.url || '');
        $event.stopPropagation();
        $event.preventDefault()
      "
      *ngFor="let item of _itensCart"
    >
      <figure>
        <img
          [src]="
            item?.product?.mainPhotoS3Url ||
            item?.product?.photos?.slice()?.shift()?.url ||
            ''
          "
          [alt]="item?.product?.name"
        />
      </figure>
      <div class="product-checkout-popup-inf">
        <span
          >{{ item?.product?.name
          }}{{
            item?.product?.productPerSquareMeter
              ? " - (" +
                item?.customWidth?.toFixed(2) +
                " m x " +
                item?.customHeight?.toFixed(2) +
                " m)"
              : ""
          }}</span
        >
        <span *ngIf="item?.product?.isPersiana()"
          >Comando: <strong>{{ item?.controlSideDescription }}</strong>
        </span>
        <span *ngIf="item?.product?.isPersiana()"
          >Acionamento: <strong>{{ item?.driveDescription }}</strong></span
        >
        <span *ngIf="!item?.product?.isPersianaRomana()"
          >Acabamento: <strong>{{ item?.panelDescription }}</strong></span
        >
        <span>Quantidade: ({{ item?.amount }})</span>
        <span *ngIf="!item?.product?.discount">
          <b>{{ getValueItem(item, false) | customCurrency }}</b></span
        >
        <span *ngIf="item?.product?.discount || 0 > 0"
          ><del>{{
            getValueItem(item, false) * (item?.amount || 1) | customCurrency
          }}</del
          ><b> por </b>
          <b>{{
            getValueItem(item, true) * (item?.amount || 1) | customCurrency
          }}</b></span
        >
      </div>
    </a>
  </div>
  <span class="total-price-cart"
    ><span>Subtotal: {{ cart.valueSubtotal | customCurrency }}</span>
    <span *ngIf="cart.haveProductDiscount"
      >Desconto: -{{ cart.valueDiscount | customCurrency }}</span
    ><span>Total: {{ cart.valueTotal || 0 | customCurrency }}</span></span
  >
  <div class="footer-button">
    <a href="carrinho">
      <button
        title="Acessar seu carrinho para finalizar sua compra"
        aria-label="Acessar seu carrinho para finalizar sua compra"
        class="button-rm"
      >
        FINALIZAR SUA COMPRA
      </button>
    </a>
  </div>
</div>
