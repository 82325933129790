import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import faqJson from '../../../../assets/faq/faq.json';
import InfoSubCategory from '../../models/product/info-sub-category';
import { SharedModule } from '../../shared.module';
import { YoutubeVideoComponent } from '../youtube-video/youtube-video.component';

export class Faq {
  public id?: string;
  public faqs?: FaqItem;
  public subTitleVideo?: string;
  public titleCatalog?: string;
  public titleFaq?: string;
  public titleVideo?: string;
  public urlCatalog?: string;
  public urlVideo?: string;
  public videoId?: string;
}

export class FaqItem {
  public contents?: FaqItemContent[];
}

export class FaqItemContent {
  public contents?: string[] = [];
  public title?: string;
}

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [NgIf, NgFor, YoutubeVideoComponent, SharedModule],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss',
})
export class FaqComponent {
  @Input()
  public set subCategory(subCategory: InfoSubCategory | undefined) {
    if (subCategory) {
      this.setFaq(subCategory);
    }
  }

  public faq: Faq = new Faq();

  setFaq(subCategory: InfoSubCategory) {
    if (faqJson) {
      const faqObj = faqJson.filter((info) => info.id === subCategory.id);
      if (faqObj) {
        this.faq = Object.assign(new Faq(), faqObj[0]);
      }
    }
  }
}
