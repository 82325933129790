import { NgFor, NgIf } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { PrimeNGModule } from '../../../primeng.module';
import { Product } from '../../models/product/product';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-carousel-products',
  standalone: true,
  imports: [NgFor, NgIf, SharedModule, PrimeNGModule],
  templateUrl: './carousel-products.component.html',
  styleUrl: './carousel-products.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CarouselProductsComponent {
  @Input()
  public products?: Product[];

  public breakpoints = {};

  constructor() {
    this.breakpoints = {
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
    };
  }
}
