<p-progressSpinner
  *ngIf="loadingFreight"
  strokeWidth="3"
  aria-label="Buscando informações de frete"
></p-progressSpinner>

<div class="freight-quotes" *ngIf="quotes && quotes.carriers">
  <div
    class="carrying-row"
    *ngFor="let carrying of quotes?.carriers"
    (click)="setFreightSelected(carrying)"
    [ngClass]="{
      'freight-selected': freightSelected?.freight?.offer === carrying.offer
    }"
  >
    <p class="carrying-row-name">
      {{ carrying?.displayName?.toLowerCase() }}
    </p>
    <p>
      {{ carrying.deliveryTime }}
      {{ carrying.deliveryTime === 1 ? "dia" + " útil" : "dias úteis" }}
    </p>
    <p>{{ carrying.priceFreight | customCurrency }}</p>
  </div>
</div>
<div class="freight-quotes-error" *ngIf="!quotes && errorFreight">
  <p>{{ errorFreight }}</p>
</div>
