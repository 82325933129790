import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import Card from '../../shared/models/payment/card';
import { HttpService } from '../http/http.service';

declare var PagSeguroDirectPayment: any;

@Injectable({
  providedIn: 'root',
})
export class PagSeguroService {
  public iframePagSeguro: any;
  constructor(
    private httpService: HttpService,
    @Inject(DOCUMENT) private dom: any
  ) {}

  loadLib() {
    const script = this.dom.getElementById('scriptpagseguro');
    if (!script) {
      new Promise((resolve, reject) => {
        const scriptElement = this.dom.createElement('script');
        scriptElement.src = 'assets/scripts/pagseguro.js';
        scriptElement.id = 'scriptpagseguro';
        scriptElement.onload = resolve;
        scriptElement.onerror = reject;
        this.dom.body.appendChild(scriptElement);
      });
    }
  }

  removeIframe() {
    this.iframePagSeguro = this.dom.getElementById('directpaymentgateway');
    const iframe = this.dom.getElementById('directpaymentgateway');
    if (iframe) {
      iframe.parentNode.removeChild(iframe);
    }
  }

  addIframe() {
    this.loadLib();
    const iframe = this.dom.getElementById('directpaymentgateway');
    if (this.iframePagSeguro && !iframe) {
      this.dom.body.appendChild(this.iframePagSeguro);
    }
  }

  async gettSessionId(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpService.post(`payments/v1/create/session/`, null).subscribe({
        next: (res: any) => {
          if (res) {
            resolve(res);
          }
        },
        error: async (error: any) => {
          reject(error);
        },
      });
    });
  }

  async setSessionId(sessionId: string) {
    try {
      await PagSeguroDirectPayment.setSessionId(sessionId);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getPaymentMethods(value: any): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        PagSeguroDirectPayment.getPaymentMethods({
          amount: value,
          success: (response: any) => {
            resolve(response);
          },
          error: (response: any) => {
            reject(JSON.stringify(response));
          },
        });
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  onSenderHashReady() {
    try {
      return new Promise((resolve, reject) => {
        PagSeguroDirectPayment.onSenderHashReady((response: any) => {
          if (response?.status === 'error') {
            reject(JSON.stringify(response));
          }
          resolve(response?.senderHash);
        });
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  getInstallments(value: any, brand: any) {
    try {
      return new Promise((resolve, reject) => {
        PagSeguroDirectPayment.getInstallments({
          amount: value,
          brand,
          maxInstallmentNoInterest: environment.maxInstallmentNoInterest,
          success: (response: any) => {
            resolve(response);
          },
          error: (response: any) => {
            reject(JSON.stringify(response));
          },
        });
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  getBrand(cardBin: string) {
    try {
      return new Promise((resolve, reject) => {
        PagSeguroDirectPayment.getBrand({
          cardBin,
          success: (response: any) => {
            resolve(response);
          },
          error: (response: any) => {
            reject(JSON.stringify(response));
          },
        });
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  createCardToken(card: Card) {
    try {
      return new Promise((resolve, reject) => {
        PagSeguroDirectPayment.createCardToken({
          cardNumber: card?.cardNumber,
          brand: card?.brand,
          cvv: card?.cvv,
          expirationMonth: card?.expirationMonth,
          expirationYear: card?.expirationYear,
          success: (response: any) => {
            resolve(response);
          },
          error: (response: any) => {
            reject(JSON.stringify(response));
          },
        });
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
