<div class="data-customers-main">
  <h2>DADOS PESSOAIS</h2>
  <app-user-data
    [user]="user"
    [userError]="userError"
    [readonly]="true"
  ></app-user-data>
  <button
    class="button-rm"
    aria-label="Atualizar seus dados"
    (click)="onClickUpdate()"
  >
    ALTERAR
  </button>
</div>
