export default class Card {
  cardNumber?: string; // Número do cartão de crédito
  brand?: string; // Bandeira do cartão
  cvv?: string; // CVV do cartão
  expirationMonth?: any; // Mês da expiração do cartão
  expirationYear?: any; // Ano da expiração do cartão, é necessário os 4 dígitos.
  name?: string; // Nome impresso no cartao
  installments?: any; // Numero de parcelas selecionadas
  totalValue?: number; // Valor total da compra
  token?: string; // TOken gerado pelo pagseguro

  cardTest?() {
    this.cardNumber = '4111111111111111';
    this.brand = 'visa';
    this.cvv = '013';
    this.expirationMonth = '12';
    this.expirationYear = '2026';
    this.name = 'BRUNO SOUZA';
  }
}
