<ng-container *ngIf="loaderService.initialLoaded">
  <app-slider-swiper></app-slider-swiper>
  <h1 style="display: none">RM Policarbonatos e Acessórios</h1>

  <div class="product-featured-section">
    <div>
      <div class="container">
        <div class="row">
          <div class="section-content">
            <h3 class="section-title" data-aos="fade-up" data-aos-delay="50">
              Mais vendidos
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="container">
        <app-carousel-products
          [products]="productsFeatured"
        ></app-carousel-products>
      </div>
    </div>
  </div>

  <div class="social-media">
    <div class="container">
      <a href="https://www.facebook.com/rmpolicarbonatos/" target="_blank">
        <div class="social-media-fb-logo">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              d="M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2 C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z"
            ></path>
          </svg>
        </div>

        <div class="social-media-fb-text">
          <h3 class="subtitle">Siga nossa página no</h3>
          <h2 class="title">Facebook</h2>
        </div>
      </a>
      <a href="https://www.instagram.com/rmpolicarbonatos/" target="_blank">
        <div class="social-media-fb-logo social-media-ig-logo">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"
            ></path>
          </svg>
        </div>

        <div class="social-media-fb-text">
          <h3 class="subtitle">Siga nossa página no</h3>
          <h2 class="title">Instagram</h2>
        </div>
      </a>
      <a
        href="https://www.youtube.com/channel/UC_SLAjoVniYGnfr3p0EvOyQ"
        target="_blank"
      >
        <div class="social-media-fb-logo social-media-yt-logo">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              d="M23.498 6.186c-.27-1.014-1.067-1.812-2.082-2.08C19.431 3.5 12 3.5 12 3.5s-7.431 0-9.416.606c-1.015.27-1.812 1.067-2.082 2.08C.5 8.17.5 12 .5 12s0 3.83.606 5.814c.27 1.013 1.067 1.812 2.082 2.08C4.57 20.5 12 20.5 12 20.5s7.431 0 9.416-.606c1.015-.27 1.812-1.067 2.082-2.08.606-1.984.606-5.814.606-5.814s0-3.83-.606-5.814zM9.845 15.568V8.432L15.568 12l-5.723 3.568z"
            />
          </svg>
        </div>

        <div class="social-media-fb-text">
          <h3 class="subtitle">Siga nossa página no</h3>
          <h2 class="title">Youtube</h2>
        </div>
      </a>
    </div>
  </div>

  <div class="categories-more">
    <div>
      <div class="container">
        <div class="row">
          <div class="section-content">
            <h3 class="section-title" data-aos="fade-up" data-aos-delay="50">
              Categorias mais acessadas
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div class="categories-more-content">
      <div class="container">
        <a routerLink="persianas/rolo">Persiana Rolo</a>
        <a routerLink="kits/policarbonato-alveolar-kit">Kit Cobertura</a>
        <a routerLink="coberturas-em-policarbonato/policarbonato-alveolar"
          >Policarbonato Alveolar</a
        >
        <a routerLink="persianas/double-vision">Persiana Romana</a>
        <a routerLink="kits/toldo-em-policarbonato ">Toldo Fixo</a>
      </div>
    </div>
  </div>

  <div class="product-videos-section">
    <div>
      <div class="container">
        <div class="row">
          <div class="section-content">
            <h3 class="section-title" data-aos="fade-up" data-aos-delay="50">
              Aprenda a instalar
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div class="product-videos-items">
      <div class="container">
        <ng-container *ngFor="let video of videos">
          <div class="product-videos-item">
            <h3>
              <i class="pi pi-youtube"></i>
              {{ video.title }}
            </h3>
            <app-youtube-video
              [videoId]="video.id"
              [title]="video.title"
              [url]="
                'https://www.youtube.com/embed/' + video.id + '?autoplay=1'
              "
            ></app-youtube-video>

            <a href="{{ video.url }}" target="_blank">
              <p>
                Donwload do manual e PDF
                <img
                  src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/pdf-20.png"
                  alt="Faça o donwload do manual e PDF"
                  width="20"
                  height="20"
                />
              </p>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
