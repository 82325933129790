import InfoSubCategory from '../product/info-sub-category';
import { AbstractInfoEnum } from './abstract-info-enum';

export class InfoCategory extends AbstractInfoEnum {
  subCategories?: Array<InfoSubCategory>;

  override fromJson(categoriesObj: any) {
    if (categoriesObj != null) {
      super.fromJson(categoriesObj);
      this.path = this.getFullpath(categoriesObj, null);

      if (
        categoriesObj.subCategories != null &&
        categoriesObj.subCategories.length > 0
      ) {
        this.subCategories = [];
        let newcSubCategory: InfoSubCategory;
        categoriesObj.subCategories.forEach((item: any) => {
          item.main = categoriesObj.id;
          newcSubCategory = new InfoSubCategory();
          newcSubCategory.fromJson(item);
          newcSubCategory.path = this.getFullpath(
            categoriesObj,
            newcSubCategory
          );
          this.subCategories?.push(newcSubCategory);
        });
      }
    }
  }

  public getFullpath(category: any, subcategory: any | null): string {
    let path;
    path = category?.id?.toLowerCase()?.replaceAll('_', '-');
    if (subcategory) {
      let pathSub;
      pathSub = subcategory?.id?.toLowerCase()?.replaceAll('_', '-');
      path = path + '/' + pathSub;
    }
    return path;
  }
}
