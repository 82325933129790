import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrimeNGModule } from '../../primeng.module';
import { ContactService } from '../../services/contact/contact.service';
import { LoaderService } from '../../services/loader/loader.service';
import { MetaTagsService } from '../../services/meta-tags/meta-tags.service';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { ContactFormComponent } from '../../shared/components/contact-form/contact-form.component';

@Component({
  selector: 'app-sac',
  standalone: true,
  imports: [BreadcrumbComponent, ContactFormComponent, PrimeNGModule],
  templateUrl: './sac.component.html',
  styleUrl: './sac.component.scss',
})
export class SacComponent {
  public typeSac = ContactService.TYPE_SAC;

  constructor(
    private activatedRoute: ActivatedRoute,
    public loaderService: LoaderService,
    private metaServices: MetaTagsService
  ) {}

  async ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_SAC,
        url[0]?.path,
        '',
        '',
        null
      );
    });
  }
}
