<p-progressSpinner
  *ngIf="loadingAllOrders"
  strokeWidth="3"
  aria-label="Buscando informações dos seus pedidos"
></p-progressSpinner>

<div class="orders-customers-main" *ngIf="!loadingAllOrders">
  <h2>MEUS PEDIDOS</h2>
  <div class="orders-customers-main-grid">
    <p-table
      [value]="orders"
      dataKey="id"
      [rowExpandMode]="'single'"
      (onRowExpand)="onRowExpand($event)"
      #tableOrdersCustomer
      selectionMode="single"
    >
      <ng-template pTemplate="header">
        <tr *ngIf="!isMobile">
          <th *ngIf="user?.isAdmin" style="width: 10%">Pedido</th>
          <th *ngIf="user?.isAdmin" style="width: 20%">Cliente</th>
          <th *ngIf="user?.isAdmin" style="width: 10%">Marketplace</th>
          <th *ngIf="user?.isAdmin" style="width: 15%">Transportadora</th>
          <th *ngIf="user?.isAdmin" style="width: 10%">Valor</th>
          <th *ngIf="user?.isAdmin" style="width: 15%">Data</th>
          <th *ngIf="user?.isAdmin" style="width: 10%">Previsão</th>
          <th *ngIf="user?.isAdmin" style="width: 10%">Status</th>

          <th *ngIf="!user?.isAdmin" style="width: 15%">Pedido</th>
          <th *ngIf="!user?.isAdmin" style="width: 20%">Valor</th>
          <th *ngIf="!user?.isAdmin" style="width: 20%">Data</th>
          <th *ngIf="!user?.isAdmin" style="width: 15%">Previsão</th>
          <th *ngIf="!user?.isAdmin" style="width: 15%">Status</th>

          <th style="width: 5%"></th>
        </tr>
        <tr *ngIf="isMobile"></tr>
      </ng-template>
      <ng-template pTemplate="body" let-order let-expanded="expanded">
        <tr
          id="{{ order.id }}"
          *ngIf="!isMobile"
          class="tbody-tr"
          (click)="tableOrdersCustomer.toggleRow(order, $event)"
        >
          <td>#{{ order?.id }}</td>
          <td *ngIf="user?.isAdmin">{{ order?.nameUser }}</td>
          <td *ngIf="user?.isAdmin">
            {{ order?.marketPlace || "RM ECOMMERCE" }}
          </td>
          <td *ngIf="user?.isAdmin">{{ order?.shippingCompany || "" }}</td>
          <td>{{ order?.totalValue | customCurrency }}</td>
          <td>{{ order?.createdAt }}</td>
          <td>{{ order?.estimateDate }}</td>
          <td>
            <p-tag
              [style]="{
                background: orderService.getTagByStatus(order?.currentStatus)
              }"
              value="{{ orderService.getNameByStatus(order?.currentStatus) }}"
            ></p-tag>
          </td>

          <td>
            <button
              type="button"
              pButton
              pRipple
              [pRowToggler]="order"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              [pRowTogglerDisabled]="true"
            ></button>
          </td>
        </tr>
        <tr
          id="{{ order.id }}"
          *ngIf="isMobile"
          class="tbody-tr"
          (click)="tableOrdersCustomer.toggleRow(order, $event)"
        >
          <p-card>
            <div class="card-orders">
              <div class="card-orders-tag">
                <p>
                  Pedido:
                  <span>#{{ order.id }} </span>
                </p>

                <p-tag
                  [style]="{
                    background: orderService.getTagByStatus(
                      order?.currentStatus
                    )
                  }"
                  value="{{
                    orderService.getNameByStatus(order?.currentStatus)
                  }}"
                ></p-tag>
              </div>
              <p *ngIf="user?.isAdmin">
                Cliente: <span>{{ order?.nameUser }}</span>
              </p>
              <p *ngIf="user?.isAdmin">
                Marketplace:
                <span>{{ order?.marketPlace || "RM ECOMMERCE" }}</span>
              </p>
              <p>
                Valor: <span>{{ order?.totalValue | customCurrency }}</span>
              </p>
              <p>
                Data: <span>{{ order?.createdAt }}</span>
              </p>
            </div>
            <div class="card-orders">
              <div class="card-orders-expand">
                <p>
                  Previsão:
                  <span>{{ order.estimateDate || "Não informado" }}</span>
                </p>
                <button
                  type="button"
                  pButton
                  pRipple
                  [pRowToggler]="order"
                  [icon]="
                    expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                  "
                  [pRowTogglerDisabled]="true"
                ></button>
              </div>
            </div>
          </p-card>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-order>
        <tr class="row-expansion">
          <td *ngIf="!user?.isAdmin" colspan="6">
            <div>
              <div *ngIf="loadingOrder" class="row-expansion-loading">
                <p-progressSpinner
                  strokeWidth="3"
                  aria-label="Buscando informações do seu pedido"
                ></p-progressSpinner>
              </div>
              <app-order-customers
                *ngIf="!loadingOrder"
                [orderStatusInfo]="orderStatusInfo"
                [order]="orderSelected"
                [user]="user"
              ></app-order-customers>
            </div>
          </td>
          <td *ngIf="user?.isAdmin" colspan="8">
            <div>
              <div *ngIf="loadingOrder" class="row-expansion-loading">
                <p-progressSpinner
                  strokeWidth="3"
                  aria-label="Buscando informações do seu pedido"
                ></p-progressSpinner>
              </div>
              <app-order-customers
                *ngIf="!loadingOrder"
                [orderStatusInfo]="orderStatusInfo"
                [order]="orderSelected"
                [user]="user"
              ></app-order-customers>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
