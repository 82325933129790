import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrimeNGModule } from '../../../primeng.module';
import { AddressService } from '../../../services/address/address.service';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { ToastService } from '../../../services/toast/toast.service';
import { StorageConstants } from '../../models/storage/storage-constants';
import Address from '../../models/user/address';
import extractNumbers from '../../utils/extract-numbers';

@Component({
  selector: 'app-zip-code-input',
  standalone: true,
  imports: [NgIf, PrimeNGModule],
  templateUrl: './zip-code-input.component.html',
  styleUrl: './zip-code-input.component.scss',
})
export class ZipCodeInputComponent implements OnInit {
  @Output()
  public changed: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public zipcodeError: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public showKnowZipCoded: boolean = true;

  @Input()
  public emitterEventError: boolean = false;

  @Input()
  public placeholder: string = '';

  @Input()
  set value(value: string) {
    this._value = value;
    if (this._value) {
      this.getAddress(false);
    }
  }

  public errorInput?: boolean;

  @Input()
  public readOnly?: boolean;

  @Output()
  public addressFound: EventEmitter<Address> = new EventEmitter<Address>();

  public _value: string = '';

  constructor(
    private addressService: AddressService,
    private localStorageService: LocalStorageService,
    private toastService: ToastService
  ) {}

  async ngOnInit() {
    const zipCode = await this.localStorageService.get(
      StorageConstants.RM_ECOMMERCE_ZIP_CODE
    );

    if (zipCode) {
      this.value = zipCode;
    }
  }

  async getAddress(showMsg: boolean) {
    const valueNumber = extractNumbers(this._value);
    if (valueNumber?.length === 8) {
      try {
        const address: Address = await this.addressService.getAddress(
          valueNumber
        );
        this.localStorageService.set(
          StorageConstants.RM_ECOMMERCE_ZIP_CODE,
          valueNumber
        );
        this.addressFound.emit(address);
      } catch (error) {
        if (this.emitterEventError) {
          return this.zipcodeError.emit(true);
        } else {
          this.toastService.error('CEP não localizado!6');
        }
      } finally {
        return this.changed.emit(valueNumber);
      }
    } else {
      this.localStorageService.removeItem(
        StorageConstants.RM_ECOMMERCE_ZIP_CODE
      );
      this.changed.emit(valueNumber);
      if (showMsg) {
        this.toastService.error('Digite um CEP válido para obter sua cotação!');
      }
    }
  }
}
