<div class="more-information-main" *ngIf="moreInformations?.length || 0 > 0">
  <div
    class="more-information-item"
    *ngFor="let item of moreInformations"
    [ngClass]="{
      'more-information-item-show-all': showAllInformations
    }"
  >
    <h2 class="section-title mobile-visible">{{ item?.title }}</h2>
    <div class="more-information-item-img">
      <img
        src="{{ item?.img }}"
        alt="Informações das imagens"
        width="auto"
        height="auto"
      />
    </div>
    <div class="more-information-item-content">
      <h2 class="section-title mobile-invisible">{{ item?.title }}</h2>
      <div class="more-information-item-texts">
        <p
          *ngFor="let content of item?.contents"
          [innerHTML]="content | safe : 1"
        ></p>
      </div>
      <button
        #scrollButtonMoreInformations
        class="button-rm"
        [attr.aria-label]="
          showAllInformations
            ? 'FECHAR DESCRIÇÃO COMPLETA'
            : 'VER DESCRIÇÃO COMPLETA'
        "
        (click)="
          showAllInformations = !showAllInformations;
          goToElement(scrollButtonMoreInformations)
        "
      >
        {{
          showAllInformations
            ? "FECHAR DESCRIÇÃO COMPLETA"
            : "VER DESCRIÇÃO COMPLETA"
        }}
      </button>
    </div>
  </div>
</div>
