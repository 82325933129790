import { Injectable } from '@angular/core';
import Budget from '../../shared/models/budget/budget';
import { Cart, CartFull } from '../../shared/models/cart/cart';
import Address from '../../shared/models/user/address';
import User from '../../shared/models/user/user';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class BudgetService {
  constructor(private httpService: HttpService) {}

  public save(budget: Budget, cart: Cart): Promise<Budget> {
    const itens = cart?.items?.map((i) => ({
      amount: i.amount,
      idProduct: i.productId,
    }));
    return new Promise((resolve, reject) => {
      this.httpService
        .post(`budget/`, {
          email: budget.email,
          name: budget.name,
          phone: budget.phone,
          zipCode: budget.zipCode,
          cart: { cartProducts: itens },
        })
        .subscribe({
          next: (res: any) => {
            if (res) {
              resolve(Object.assign(new Budget(), res));
            }
          },
          error: (error: any) => {
            const msg =
              error?.error?.message ||
              error?.error?.error ||
              'Ocorreu um erro ao salvar o cupom!';
            reject(msg);
          },
        });
    });
  }

  public get(hash: string): Promise<Budget> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`budget/${hash}`).subscribe({
        next: (res: any) => {
          if (res) {
            resolve(Object.assign(new Budget(), res));
          }
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao buscar o cupom!';
          reject(msg);
        },
      });
    });
  }

  public delete(hash: string | undefined): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete(`budget/${hash}`).subscribe({
        next: (res: any) => {
          if (res) {
            resolve(true);
          }
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao deletar o cupom!';
          reject(msg);
        },
      });
    });
  }

  public list(): Promise<Array<CartFull>> {
    const carts: Array<CartFull> = [];
    return new Promise((resolve, reject) => {
      this.httpService
        .post('budget/pageable/list/', {
          status: `GENERATED`,
        })
        .subscribe({
          next: (res: any) => {
            if (res) {
              let newBudget: CartFull;
              res.content.forEach((budgetBanco: any) => {
                newBudget = {};
                newBudget.cartProducts = budgetBanco.cart.cartProducts;
                newBudget.createdAt = budgetBanco.createdAt;
                newBudget.hash = budgetBanco.hash;
                newBudget.id = budgetBanco.cart.id;
                newBudget.user = new User();
                newBudget.user.name = budgetBanco.name;
                newBudget.user.phone = budgetBanco.phone;
                newBudget.user.email = budgetBanco.email;
                newBudget.user.address = [];
                const newAddress: Address = new Address();
                newAddress.fromJson(budgetBanco);
                newBudget.user.address.push(newAddress);
                carts.push(newBudget);
              });
              resolve(carts);
            }
          },
          error: (error: any) => {
            const msg =
              error?.error?.message ||
              error?.error?.error ||
              'Ocorreu um erro ao buscar os cupons!';
            reject(msg);
          },
        });
    });
  }

  async deleteBudgets(carts: Array<CartFull>) {
    try {
      await Promise.all(carts.map((c) => this.delete(c.hash)));
    } catch (err) {
      console.error(err);
    }
  }
}
