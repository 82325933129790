<app-breadcrumb [path]="'Sobre Nós'" [showTitle]="true"></app-breadcrumb>

<div class="about-main">
  <div class="container">
    <img
      width="1162"
      height="438"
      src="https://d20p8exwoll05u.cloudfront.net/assets/images/about/about.webp"
      alt="Fachada da empresa!"
    />
    <div class="about-main-content">
      <h2>Bem vindo à RM Policarbonatos</h2>
      <p>
        <span>Quem somos:</span> Somos uma empresa familiar fundada em 2016,
        especializada na venda de materiais para coberturas, toldos, persianas e
        peças de montagem e instalação. Atuamos tanto com vendas online quanto
        presenciais, oferecendo suporte técnico para ajudar nossos clientes a
        escolher os produtos ideais e fornecendo orientações para sua
        instalação. Acreditamos que a compra online pode ser fácil,
        descomplicada e segura, oferecendo aos nossos clientes praticidade,
        autonomia, custo-benefício e qualidade. Aplique você mesmo nossos
        produtos e transforme seu ambiente com facilidade.
      </p>
      <p>
        <span>Missão:</span> Nossa missão é oferecer aos nossos clientes
        praticidade, autonomia e segurança ao comprar nossos produtos online,
        com uma experiência de compra fácil e descomplicada. Buscamos oferecer
        produtos de qualidade com preços competitivos e garantia, além de um
        suporte técnico eficiente e eficaz, permitindo que nossos clientes
        instalem seus produtos com facilidade e confiança.
      </p>
      <p>
        <span>Visão:</span> Nosso objetivo é ser referência no mercado online de
        coberturas, toldos, persianas e peças de montagem e instalação,
        oferecendo aos nossos clientes uma experiência de compra fácil,
        descomplicada e segura, com produtos de qualidade, com garantia, preços
        competitivos e suporte técnico de excelência. Queremos ser a escolha
        número um para quem busca aplicar por conta própria soluções práticas e
        de qualidade.
      </p>
      <p>
        <span>Valores:</span> Os valores que fundamentam a nossa empresa são
        qualidade, transparência, comprometimento e excelência no atendimento ao
        cliente. Acreditamos que a qualidade de nossos produtos e serviços deve
        ser a base de nossa empresa. Valorizamos a transparência em todas as
        nossas transações, garantindo a confiabilidade e a honestidade em nossos
        negócios. E, acima de tudo, valorizamos a satisfação do cliente,
        colocando o atendimento ao cliente em primeiro lugar em todas as etapas
        do processo de compra e venda. Estamos comprometidos em fornecer aos
        nossos clientes um suporte técnico eficiente e eficaz, ajudando-os a
        escolher os produtos ideais para suas necessidades e fornecendo
        orientações para a instalação.
      </p>
    </div>
  </div>
</div>
