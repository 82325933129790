import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { PrimeNGModule } from '../../../primeng.module';
import { SharedModule } from '../../shared.module';
import { YoutubeVideoComponent } from '../youtube-video/youtube-video.component';

@Component({
  selector: 'app-galleria',
  standalone: true,
  imports: [PrimeNGModule, YoutubeVideoComponent, SharedModule],
  templateUrl: './galleria.component.html',
  styleUrl: './galleria.component.scss',
})
export class GalleriaComponent implements AfterViewInit, OnInit {
  @Input()
  public images: any[] | undefined;

  @Input()
  public discount?: number;

  public isMobile = false;
  public innerWidth = '';
  public fullscreenImage: string | null = null;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (isPlatformBrowser(this.platformId)) {
      this.setResize();
    }
  }

  constructor(
    @Inject(DOCUMENT) private dom: any,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.setResize();
    }
  }

  ngAfterViewInit(): void {
    this.setAriaLabel();
  }

  setAriaLabel() {
    setTimeout(() => {
      if (isPlatformBrowser(this.platformId)) {
        var element: any[] = this.dom.getElementsByClassName('p-link');
        if (element.length > 0) {
          for (let index = 0; index < element.length; index++) {
            element[index].ariaLabel = 'Selecione uma foto';
          }
        }

        const buttons = this.dom.querySelectorAll(
          '.p-galleria-thumbnail-prev, .p-galleria-thumbnail-next'
        );
        const buttonsObserver = new MutationObserver((mutations) => {
          mutations.forEach((mutation: any) => {
            if (
              mutation.attributeName === 'role' &&
              mutation.target.getAttribute('role') !== 'button'
            ) {
              mutation.target.setAttribute('role', 'button');
            }
          });
        });

        buttons.forEach((button: HTMLElement) => {
          const ariaLabel = button.classList.contains(
            'p-galleria-thumbnail-prev'
          )
            ? 'Foto anterior'
            : 'Próxima foto';
          button.setAttribute('aria-label', ariaLabel);
          buttonsObserver.observe(button, { attributes: true });
        });

        const galeriaContainer = this.dom.querySelector(
          '.p-galleria-indicators'
        );
        galeriaContainer?.setAttribute('role', 'tablist');

        const galeriaItems = this.dom.querySelectorAll(
          '.p-galleria-indicators li.p-galleria-indicator'
        );
        const thumbnailsItems = this.dom.querySelectorAll(
          '.p-galleria-thumbnail-item'
        );

        galeriaItems.forEach((item: any) => {
          item.setAttribute('role', 'tab');
        });

        thumbnailsItems.forEach((item: any) => {
          item.setAttribute('role', 'tab');
        });
      }
    }, 700);
  }

  zoomIn(event: MouseEvent) {
    if (this.isMobile) return;
    const container = event.currentTarget as HTMLElement;
    const image = container.querySelector('.zoom-image') as HTMLImageElement;
    image.classList.add('zoomed');
  }

  zoomOut(event: MouseEvent) {
    if (this.isMobile) return;
    const container = event.currentTarget as HTMLElement;
    const image = container.querySelector('.zoom-image') as HTMLImageElement;
    image.classList.remove('zoomed');
    image.style.transform = ''; // Reset the transform on mouse leave
  }

  zoomMove(event: MouseEvent) {
    if (this.isMobile) return;
    const container = event.currentTarget as HTMLElement;
    const image = container.querySelector('.zoom-image') as HTMLImageElement;

    const rect = container.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const xPercent = x / rect.width;
    const yPercent = y / rect.height;

    image.style.transformOrigin = `${xPercent * 100}% ${yPercent * 100}%`;
  }

  openFullscreen(imageUrl: string) {
    if (!this.isMobile) return;
    this.fullscreenImage = imageUrl;
  }

  closeFullscreen() {
    this.fullscreenImage = null;
  }

  setResize() {
    this.isMobile = window?.innerWidth < 640;
    this.innerWidth = window?.innerWidth - 20 + 'px';
  }
}
