import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  constructor() {}

  public initialLoaded: boolean = false;

  private loading: boolean = false;

  showLoader() {
    if (!this.getLoading()) {
      this.setLoading(true);
    }
  }

  hideLoader() {
    if (this.getLoading()) {
      this.setLoading(false);
    }
  }
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  getLoading(): boolean {
    return !this.initialLoaded ? true : this.loading;
  }
}
