import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-has-no-itens',
  standalone: true,
  imports: [],
  templateUrl: './has-no-itens.component.html',
  styleUrl: './has-no-itens.component.scss',
})
export class HasNoItensComponent {
  @Input()
  public title: string = '';

  @Input()
  public labelButton: string = '';

  @Input()
  public route: string = '';
}
