import User from '../user/user';
import Phone from './phone';

export default class PaymentSender {
  public documentType?: string; // CPF, CNPJ, OTHER
  public documentValue?: string;
  public email?: string;
  public hash?: string;
  public name?: string;
  public phone?: Phone;

  constructor(user: User, hash: string) {
    this.documentType = 'CPF';
    this.documentValue = user?.document;
    this.email = user?.email;
    // this.email = 'teste@sandbox.pagseguro.com.br';
    this.name = `${user?.name} ${user?.lastName}`;
    this.phone = new Phone(user?.phone?.toString() || '');
    this.hash = hash;
  }
}
