import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  PasswordError,
  UserError,
} from '../../../pages/register-user/register-user.component';
import { PrimeNGModule } from '../../../primeng.module';
import User from '../../models/user/user';

@Component({
  selector: 'app-user-data',
  standalone: true,
  imports: [NgClass, NgIf, FormsModule, PrimeNGModule],
  templateUrl: './user-data.component.html',
  styleUrl: './user-data.component.scss',
})
export class UserDataComponent {
  @Input()
  public user: User = new User();

  @Input()
  public userError?: UserError;

  @Input()
  public readonly = false;

  public passwordError: PasswordError = new PasswordError();

  onChangePassword() {
    if (this.user) {
      const validLowercase = new RegExp('^^(?=.*[a-z])').test(
        this.user.password || ''
      );
      const validUpperCase = new RegExp('^^(?=.*[A-Z])').test(
        this.user.password || ''
      );
      const validNumber = new RegExp('^^(?=.*[0-9])').test(
        this.user.password || ''
      );
      const validLength =
        (this.user?.password && this.user?.password.length >= 8) || false;
      this.passwordError.errorLowercase = validLowercase;
      this.passwordError.errorLength = validLength;
      this.passwordError.errorNumber = validNumber;
      this.passwordError.errorUpperCase = validUpperCase;
      this.passwordError.errorSame =
        (this.user.password &&
          this.user.password === this.user.confirmPassword) ||
        false;
    }
  }
}
