import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  addSingle(severity: string, summary: string, detail: string, life: number) {
    this.messageService.add({ severity, summary, detail, life });
  }

  success(detail: string, life = 4000) {
    this.addSingle('success', 'Sucesso!', detail, life);
  }

  error(detail: string, life = 4000) {
    this.addSingle('error', 'Erro!', detail, life);
  }

  info(detail: string, life = 4000) {
    this.addSingle('info', 'Informação!', detail, life);
  }

  warning(detail: string, life = 4000) {
    this.addSingle('warn', 'Aviso!', detail, life);
  }

  clear() {
    this.messageService.clear();
  }
}
