import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { PrimeNGModule } from '../../primeng.module';
import { AddressService } from '../../services/address/address.service';
import { AuthService } from '../../services/auth/auth.service';
import { CartService } from '../../services/cart/cart.service';
import { FreightService } from '../../services/freight/freight.service';
import { GoogleAnalyticsService } from '../../services/google/google-analytics.service';
import { LoaderService } from '../../services/loader/loader.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { MetaTagsService } from '../../services/meta-tags/meta-tags.service';
import { PagSeguroService } from '../../services/pag-seguro/pag-seguro.service';
import { ProductsService } from '../../services/product/products.service';
import { ToastService } from '../../services/toast/toast.service';
import { TransactionsService } from '../../services/transactions/transactions.service';
import { UserService } from '../../services/user/user.service';
import {
  AddressComponent,
  AddressDisable,
  AddressError,
} from '../../shared/components/address/address.component';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import {
  CardInputError,
  CreditCardInputComponent,
} from '../../shared/components/credit-card-input/credit-card-input.component';
import { FreightQuotesComponent } from '../../shared/components/freight-quotes/freight-quotes.component';
import { ModalPixComponent } from '../../shared/components/modals/modal-pix/modal-pix.component';
import { BlindsOptionsEnum } from '../../shared/models/app/info-enum';
import CartCheckoutDetails from '../../shared/models/cart/cart-checkout-details';
import Checkout from '../../shared/models/checkout/checkout';
import CheckoutPayment from '../../shared/models/checkout/checkout-payment';
import CreditCart from '../../shared/models/checkout/credit-cart';
import PaymentSender from '../../shared/models/checkout/payment-sender';
import { Pix } from '../../shared/models/checkout/pix';
import CouponDiscount from '../../shared/models/coupons/coupon-discount';
import CalculateShipping from '../../shared/models/freight/calculate-shipping';
import Freight from '../../shared/models/freight/freight';
import FreightSelected from '../../shared/models/freight/freight-selected';
import QuoteList from '../../shared/models/freight/quote-list';
import Order from '../../shared/models/order/order';
import Card from '../../shared/models/payment/card';
import { Product } from '../../shared/models/product/product';
import { StorageConstants } from '../../shared/models/storage/storage-constants';
import Address from '../../shared/models/user/address';
import User from '../../shared/models/user/user';
import { SharedModule } from '../../shared/shared.module';
import {
  THREE_SECONDS_IN_MS,
  TWO_SECONDS_IN_MS,
  wait,
} from '../../shared/utils/wait';

export enum PaymentMethodsEnum {
  PIX = 'PIX',
  CREDIT_CARD = 'CARTAO_CREDITO',
}

@Component({
  selector: 'app-payment',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    AddressComponent,
    FreightQuotesComponent,
    PrimeNGModule,
    CreditCardInputComponent,
    ModalPixComponent,
    SharedModule,
  ],
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss',
  providers: [ToastService, MessageService],
})
export class PaymentComponent implements OnInit {
  STATUS = 'status';
  AVAILABLE = 'AVAILABLE';
  ONLINE_DEBIT = 'ONLINE_DEBIT';
  MEDIUM = 'MEDIUM';
  PAYMENTSMETHODS = 'paymentMethods';

  public address: Address = new Address();
  public addressDisable: AddressDisable = new AddressDisable();
  public addressError: AddressError = new AddressError();
  public card: Card = new Card();
  public cardInputError: CardInputError = new CardInputError();
  public cartCheckout: CartCheckoutDetails | undefined;
  public coupon: string | null = null;
  public couponDiscount: CouponDiscount | undefined;
  public hashUser?: string;
  public loadingFreight = false;
  public order: Order = new Order();
  public paymentMethods: any;
  public paymentSelected: string | undefined = 'PIX';
  public pix = new Pix();
  public quotes: QuoteList | undefined;
  public showModalPix = false;
  public user: User | undefined = new User();
  public zipCode?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private addressService: AddressService,
    private authService: AuthService,
    private cartService: CartService,
    private confirmationService: ConfirmationService,
    private freightService: FreightService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private metaServices: MetaTagsService,
    private loaderService: LoaderService,
    private localStorageService: LocalStorageService,
    private pagSeguroService: PagSeguroService,
    private productsService: ProductsService,
    public router: Router,
    private toastService: ToastService,
    private transactionsService: TransactionsService,
    public userService: UserService
  ) {
    this.authService.userData$.subscribe((res: any) => {
      this.user = undefined;
      if (res) {
        this.user = new User();
        this.user.fromJson(res);
      }
    });
  }

  ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_PAYMENT,
        url[0]?.path,
        '',
        '',
        null
      );
    });

    this.productsService.getValueMotorBando();
    this.configCheckout();
  }

  async configCheckout() {
    if (!this.user) {
      this.cartService.goToCart();
    }

    this.loaderService.showLoader();
    try {
      this.zipCode = await this.localStorageService.get(
        StorageConstants.RM_ECOMMERCE_ZIP_CODE
      );

      const freightSelected = await this.localStorageService.get(
        StorageConstants.RM_ECOMMERCE_FREIGHT
      );
      if (!freightSelected) {
        this.cartService.goToCart();
      }

      this.cartCheckout = await this.cartService.prepareCheckout(
        freightSelected
      );
      if (this.cartCheckout) {
        this.address = await this.addressService.getAddress(this.zipCode || '');
        this.addressDisable.address = !!this.address.address;
        this.addressDisable.district = !!this.address.district;
        this.addressDisable.complement = false;
        this.addressDisable.number = false;
        await this.getSession();
        this.loaderService.hideLoader();
      } else {
        this.cartService.goToCart();
      }
    } catch (error: any) {
      this.toastService.error(error, 5000);
      this.loaderService.hideLoader();
      this.cartService.goToCart();
    }
  }

  async getSession() {
    try {
      const session = await this.pagSeguroService.gettSessionId();
      if (session) {
        this.pagSeguroService.setSessionId(session?.id || '');
        Promise.all([
          this.onSenderHashReady(),
          this.getPaymentMethods(this.getFinalValue()),
          this.calculateFreight(),
        ]);
      }
    } catch (err) {
      throw 'Sessão não foi criada pelo PagSeguro! Tente novamente mais tarde!';
    }
  }

  async calculateFreight() {
    this.quotes = undefined;
    if (this.address?.zipCode) {
      try {
        this.loadingFreight = true;
        const calculate: CalculateShipping = new CalculateShipping();
        calculate.cep = this.address?.zipCode;
        calculate.cartId = this.cartCheckout?.cart?.id;
        const quotes = await this.freightService.calculateShippingOrCart(
          calculate
        );

        if (quotes) {
          this.quotes = quotes;
          if ((quotes?.carriers?.length || 0) > 0) {
            let daysOnlyOrder = 0;
            this.cartCheckout?.cart?.cartProducts?.forEach((i) => {
              if ((i?.product?.daysOnlyOrder || 0) > daysOnlyOrder) {
                daysOnlyOrder = i?.product?.daysOnlyOrder || 0;
              }
            });

            if (daysOnlyOrder > 0) {
              this.quotes?.carriers?.forEach((carrier) => {
                if (carrier.optionFreight === Freight.STORE_PICKUP) {
                  carrier.deliveryTime =
                    (carrier.deliveryTime || 0) + daysOnlyOrder;
                }
              });
            }
          } else {
            return this.toastService.error(
              'Para cotar esse frete com 6 metros, solicite via whatsapp!'
            );
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingFreight = false;
      }
    }
  }

  getFinalValue(): number {
    let value = this.cartCheckout?.gross || 0;
    if (this.cartCheckout) {
      const discountAllProduct =
        this.cartCheckout?.gross - this.cartCheckout?.total;
      if (
        discountAllProduct > 0 &&
        this.paymentSelected === PaymentMethodsEnum.PIX &&
        !this.couponDiscount
      ) {
        value -= discountAllProduct;
      }
      if (this.cartCheckout?.freightSelected?.freight) {
        const priceFreight =
          this.cartCheckout?.freightSelected?.freight?.priceFreight || 0;
        value += priceFreight;
      }
      if (this.couponDiscount) {
        value = value - (this.couponDiscount?.totalDiscount || 0);
      }
      this.card.totalValue = value;
    }
    return value;
  }

  getValueCashDiscount(): number {
    const value = this.valueProductsWithoutDiscount();
    return (value * environment.cashDiscount) / 100;
  }

  valueProductsWithoutDiscount(): number {
    let valueTotal = 0;
    this.cartCheckout?.cart?.cartProducts?.forEach((cp) => {
      const product = cp?.product || new Product();
      if (cp.discount === 0) {
        const amount = cp?.amount || 0;
        let value = cp?.value || 0;

        if (product.isPersiana()) {
          if (cp.drive === BlindsOptionsEnum.DRIVE_MOTOR) {
            value += this.productsService.getValueMotor();
          }

          if (cp?.panel === BlindsOptionsEnum.WITH_PANEL) {
            value += this.productsService.getValueTotalBando(
              cp.customWidth || 0
            );
          }
        }

        valueTotal += amount * value;
      }
    });
    return valueTotal;
  }

  async getPaymentMethods(value: number) {
    this.paymentMethods = null;
    try {
      const res = await this.pagSeguroService.getPaymentMethods(value);
      this.paymentMethods = res[this.PAYMENTSMETHODS];
    } catch (err) {
      throw 'Erro ao buscar os métodos de pagamento!';
    }
  }

  async onSenderHashReady() {
    try {
      const hash = await this.pagSeguroService.onSenderHashReady();
      if (hash) {
        this.hashUser = hash.toString();
      } else {
        throw 'Erro ao gerar o hash do cliente! Tente novamente em alguns minutos.';
      }
    } catch (err) {
      throw 'Erro ao gerar o hash do cliente! Tente novamente em alguns minutos.';
    }
  }

  async createCardToken(card: Card) {
    try {
      if (card.cardNumber || 0 > 0) {
        const token: any = await this.pagSeguroService.createCardToken(card);
        card.token = token?.card?.token;
      }
    } catch (err) {
      throw {
        error:
          'Os dados do seu cartão estão inválidos. Revise os campos e tente novamente!',
      };
    }
  }
  clearedZip() {
    this.quotes = undefined;
    if (this.cartCheckout) {
      this.cartCheckout.freightSelected = undefined;
    }
    this.addressError.validAll(true);
  }

  foundAddress(address: Address) {
    if (address?.zipCode?.length === 9) {
      this.calculateFreight();
    }
  }

  async setFreightSelected(freightSelected: FreightSelected) {
    if (this.cartCheckout) {
      this.cartCheckout.freightSelected = freightSelected;
      this.card = {};
      this.addressError.errorNumber =
        !this.freightIsStorePickup() && !this.address.numberAddress;
      this.addressError.errorComplement =
        !this.freightIsStorePickup() && !this.address.complement;
    }
  }

  freightIsStorePickup(): boolean {
    return (
      this.cartCheckout?.freightSelected?.optionFreight === Freight.STORE_PICKUP
    );
  }

  async onClickBtcalculateCoupon() {
    if (this.coupon) {
      this.loaderService.showLoader();
      try {
        this.couponDiscount = await this.cartService.coupon(
          this.cartCheckout?.cart?.id || 0,
          this.coupon?.toUpperCase()
        );
        this.coupon = null;
        if (this.couponDiscount) {
          if (
            this.couponDiscount?.coupon?.meanOfPayment === 'IN_CASH' &&
            this.paymentSelected === PaymentMethodsEnum.CREDIT_CARD
          ) {
            this.couponDiscount = undefined;
            this.toastService.error(
              'Cupom disponível apenas para pagamento via PIX!'
            );
          } else {
            this.toastService.success('Cupom aplicado com sucesso!');
          }
        }
      } catch (error: any) {
        this.toastService.error(error || 'Cupom inválido!');
      } finally {
        this.loaderService.hideLoader();
      }
    } else {
      this.toastService.error('Informe um cupom!');
    }
  }

  async onClickCheckout() {
    if (this.validCoupon()) {
      const msgValidation = this.validPayment();
      if (!msgValidation) {
        if (this.freightIsStorePickup()) {
          this.checkout();
        } else {
          this.confirmationService.confirm({
            message: 'O endereço de entrega está correto?',
            header: 'Confirmação',
            accept: async () => {
              this.checkout();
            },
          });
        }
      } else {
        return this.toastService.error(msgValidation);
      }
    }
  }

  async checkout() {
    try {
      if (this.user) {
        this.loaderService.showLoader();
        let crediCart: CreditCart | null = null;
        if (this.paymentSelected === PaymentMethodsEnum.CREDIT_CARD) {
          const cardFormat: Card = { ...this.card };
          cardFormat.cardNumber = cardFormat?.cardNumber?.replace(/\D/g, '');
          cardFormat.expirationMonth = cardFormat?.expirationMonth?.value;
          cardFormat.expirationYear = cardFormat?.expirationYear?.value;
          await this.createCardToken(cardFormat);
          crediCart = new CreditCart(this.user, cardFormat);
        }
        const sender: PaymentSender = new PaymentSender(
          this.user,
          this.hashUser || ''
        );
        const payment: CheckoutPayment = new CheckoutPayment(
          crediCart,
          this.paymentSelected,
          sender,
          null
        );
        const checkout: Checkout = new Checkout(
          this.cartCheckout?.cart?.id,
          this.cartCheckout?.freightSelected,
          payment,
          this.freightIsStorePickup()
            ? this.user.getAddress(true)
            : this.address
        );

        if (this.couponDiscount?.coupon) {
          checkout.coupon = this.couponDiscount.coupon;
        }

        if (this.cartCheckout) {
          this.googleAnalyticsService.sendEventAddPaymentInfo(
            this.cartCheckout,
            this.paymentSelected === PaymentMethodsEnum.CREDIT_CARD
              ? 'Cartão de Crédito'
              : 'PIX'
          );

          if (this.cartCheckout?.freightSelected) {
            this.googleAnalyticsService.sendEventAddShippingInfo(
              this.cartCheckout,
              this.cartCheckout?.freightSelected,
              this.zipCode || ''
            );
          }
        }

        const order = await this.transactionsService.checkout(checkout);
        if (order) {
          this.googleAnalyticsService.sendEventBeginCheckout(order);
          this.order = order;
          this.loaderService.hideLoader();
          this.cartService.setSearchProductsCart(true);
          const msg = 'Pedido realizado com sucesso!';
          if (this.paymentSelected === PaymentMethodsEnum.CREDIT_CARD) {
            this.toastService.success(
              `${msg} Acompanhe o andamento nos seus pedidos.`
            );
            await wait(THREE_SECONDS_IN_MS);
            this.userService.goToCustomerOrders();
          } else if (this.paymentSelected === PaymentMethodsEnum.PIX) {
            this.toastService.success(
              `${msg} Realize o pagamento com o QRCode a seguir.`
            );
            await wait(TWO_SECONDS_IN_MS);
            this.pix.pixData = this.order?.orderTransaction?.pix;
            this.pix.base64QrCode = this.order?.orderTransaction?.base64QrCode;
            this.pix.value = this.getFinalValue();
            this.showModalPix = true;
          }
        } else {
          this.toastService.error(
            'Erro ao gerar seu pedido! Entre em contato pelo WhatsApp!'
          );
        }
      }
    } catch (error: any) {
      this.toastService.error(`Erro ao gerar seu pedido: ${error || ''}`);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  onChangePaymentPix() {
    this.card = new Card();
  }

  onChangePaymentCard() {
    this.validCoupon();
  }

  validCoupon() {
    if (this.couponDiscount) {
      if (
        this.couponDiscount?.coupon?.meanOfPayment === 'IN_CASH' &&
        this.paymentSelected === PaymentMethodsEnum.CREDIT_CARD
      ) {
        this.toastService.error(
          'Cupom disponível apenas para pagamento via PIX. Remova o cupom para realizar o pagamento com cartão de crédito!',
          6000
        );
        setTimeout(() => {
          this.paymentSelected = PaymentMethodsEnum.PIX;
        }, 100);
        return false;
      }
    }
    return true;
  }

  validPayment() {
    if (
      !this.cartCheckout?.freightSelected ||
      (this.cartCheckout?.freightSelected &&
        this.cartCheckout?.freightSelected?.optionFreight ===
          Freight.CARRYING &&
        !this.cartCheckout?.freightSelected?.freight)
    ) {
      return 'Selecione um frete!';
    }

    if (!this.freightIsStorePickup()) {
      if (!this.address?.numberAddress || !this.address?.complement) {
        if (!this.address?.numberAddress) {
          this.addressError.errorNumber = true;
        }
        if (!this.address?.complement) {
          this.addressError.errorComplement = true;
        }
        return 'Informe os dados de entrega corretamente!';
      } else {
        this.addressError.errorNumber = false;
        this.addressError.errorComplement = false;
      }
    }

    if (!this.paymentSelected) {
      return 'Selecione uma forma de pagamento!';
    }

    if (this.paymentSelected === PaymentMethodsEnum.CREDIT_CARD) {
      if (this.getFinalValue() < 5) {
        return 'Valor total da compra é menor do que a parcela mímima de R$ 5,00 para pagamento com Cartão de Crédito!';
      } else {
        this.cardInputError.errorCardNumber =
          (this.card?.cardNumber?.length || 0) < 19;
        this.cardInputError.errorCvv = (this.card?.cvv?.length || 0) < 3;
        this.cardInputError.errorExpirationMonth = !this.card.expirationMonth;
        this.cardInputError.errorExpirationYear = !this.card.expirationYear;
        this.cardInputError.errorName = (this.card?.name?.length || 0) < 5;
        this.cardInputError.errorInstallments = !this.card.installments;

        const hasError =
          this.cardInputError.errorCardNumber ||
          this.cardInputError.errorCvv ||
          this.cardInputError.errorExpirationMonth ||
          this.cardInputError.errorExpirationYear ||
          this.cardInputError.errorName ||
          this.cardInputError.errorInstallments;

        return hasError
          ? 'Preencha corretamente os dados do seu cartáo de crédito!'
          : null;
      }
    }
    return null;
  }

  haveProductDiscount(): boolean {
    if (this.cartCheckout) {
      return this.cartCheckout.gross - this.cartCheckout.total > 0;
    }
    return false;
  }

  async onClickBtRemoveCoupon() {
    this.loaderService.showLoader();
    try {
      this.couponDiscount = undefined;
      this.card = {};
      this.toastService.success('Cupom removido com sucesso!');
    } catch (error: any) {
      this.toastService.error(
        'Erro',
        error?.message || 'Erro ao remover o cupom!'
      );
    } finally {
      this.loaderService.hideLoader();
    }
  }

  closeModalPix() {
    this.userService.goToCustomerOrders();
  }
}
