import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppInfoService } from '../../../services/app-info/app-info.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { ToastService } from '../../../services/toast/toast.service';
import { InfoCategory } from '../../models/app/info-category';
import { CategoriesEnum } from '../../models/app/info-enum';
import InfoSubCategory from '../../models/product/info-sub-category';
import {
  BreadcrumbComponent,
  BreadcrumbData,
} from '../breadcrumb/breadcrumb.component';
import {
  ConfigCategories,
  ProductsShopComponent,
} from '../products-shop/products-shop.component';
import { SubcategoriesComponent } from '../subcategories/subcategories.component';

@Component({
  selector: 'app-categories',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    BreadcrumbComponent,
    RouterOutlet,
    ProductsShopComponent,
  ],
  templateUrl: './categories.component.html',
  styleUrl: './categories.component.scss',
  providers: [ToastService, MessageService],
})
export class CategoriesComponent implements OnInit {
  public categories: Array<InfoCategory> = [];
  public category: BreadcrumbData = new BreadcrumbData();
  public configCategories?: ConfigCategories;
  public subCategories: Array<InfoSubCategory> = [];
  public showBreadcrumbCat: boolean = true;
  public showProductsOnOffer: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appInfo: AppInfoService,
    private metaServices: MetaTagsService
  ) {}

  async ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.category = this.setCategory(url[0]?.path);

      this.metaServices.configTagPages(
        MetaTagsService.PAGE_CATEGORIES,
        this.category.path,
        '',
        '',
        null
      );
    });
    this.configItens();
  }

  setCategory(path: string): BreadcrumbData {
    return {
      title: this.metaServices.getTitlePageCategory(path),
      path,
      id: path?.toUpperCase()?.replaceAll('-', '_'),
    };
  }

  setConfig(component: SubcategoriesComponent) {
    if (!this.category.path) {
      this.activatedRoute.url.subscribe((url) => {
        this.category = this.setCategory(url[0]?.path);
        component.category = this.category;
      });
    } else {
      component.category = this.category;
    }
    setTimeout(() => {
      this.showBreadcrumbCat = false;
    });
  }

  async configItens() {
    this.categories = await this.appInfo.getCategoriesWithProductActive();
    const categoryInfo = this.categories.find((c) => c.id === this.category.id);
    if (categoryInfo) {
      this.subCategories = categoryInfo?.subCategories || [];
    }

    if (this.category.id === CategoriesEnum.PRODUCTS_ON_OFFER_PT) {
      this.configProductsOnOffer();
    }
  }

  configProductsOnOffer() {
    this.configCategories = {
      categories: this.categories,
      categorySelected: undefined,
      subCategorySelected: undefined,
      productsOnOffer: true,
      productsQuerySearch: undefined,
    };
    this.showProductsOnOffer = true;
  }
}
