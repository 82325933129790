import Address from './address';

export default class User {
  public static ROLE_ADMIN = 'ROLE_ADMIN';
  public static ROLE_USER = 'ROLE_USER';

  address?: Array<Address>;
  birthDate?: string;
  confirmPassword?: string;
  createdAt?: string;
  document?: string;
  email?: string;
  id?: number;
  isAdmin = false;
  isCompany = false;
  lastName?: string;
  name?: string;
  password?: string;
  phone?: string;
  roles?: Array<any> = [];
  type?: string;

  fromJson(user: any) {
    if (user) {
      this.id = user.id;
      this.name = user.name;
      this.isCompany = user.isCompany;
      this.document = user?.document;
      this.phone = user.phone;
      this.birthDate = user.birthDate;
      this.email = user.email;
      this.password = user.password;
      this.lastName = user.lastName;
      this.createdAt = user.createdAt;

      let newAddress: Address;
      this.address = [];
      user?.address?.forEach((adress: any) => {
        newAddress = new Address();
        newAddress.fromJson(adress);
        this.address?.push(newAddress);
      });

      this.roles = [];
      user?.roles?.forEach((role: any) => {
        if (role.name === User.ROLE_ADMIN) {
          this.isAdmin = true;
        }
        this.roles?.push(role);
      });
      this.type = this.isAdmin ? 'Admin' : 'Cliente';
    }
  }

  getIsAdmin(): boolean {
    const roleAdmin = this.roles?.find((role) => role.name === User.ROLE_ADMIN);
    return roleAdmin != null;
  }

  fullName(): string {
    return `${this.name} ${this.lastName}`;
  }

  isTeste(): boolean {
    return (
      this.email === 'brunocordovaa@gmail.com' ||
      this.email === 'karoline.godoi@hotmail.com'
    );
  }
  getAddress(principal: boolean): Address {
    return (
      this.address?.find((ad) => ad.principal === principal) || new Address()
    );
  }
}
