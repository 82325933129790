import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

export class BreadcrumbData {
  public title: string = '';
  public id: string = '';
  public path: string = '';
}

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [NgIf, NgClass],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent {
  @Input()
  public path: string = '';

  @Input()
  public title: string = '';

  @Input()
  public showTitle = false;

  @Input()
  public category: BreadcrumbData = new BreadcrumbData();

  @Input()
  public showCategory = false;

  @Input()
  public subCategory: BreadcrumbData = new BreadcrumbData();

  @Input()
  public showSubCategory = false;

  @Input()
  public productName = '';

  @Input()
  public querySearch = '';

  @Input()
  public showProduct = false;

  @Input()
  public justShowProduct = false;
}
