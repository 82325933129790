import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../../../services/auth/auth.service';
import { LoaderService } from '../../../../services/loader/loader.service';
import { MetaTagsService } from '../../../../services/meta-tags/meta-tags.service';
import { ToastService } from '../../../../services/toast/toast.service';
import { UserService } from '../../../../services/user/user.service';
import Address from '../../../models/user/address';
import User from '../../../models/user/user';
import { validZipCode } from '../../../utils/validators';
import {
  AddressComponent,
  AddressDisable,
  AddressError,
} from '../../address/address.component';

@Component({
  selector: 'app-address-customers',
  standalone: true,
  imports: [AddressComponent],
  templateUrl: './address-customers.component.html',
  styleUrl: './address-customers.component.scss',
  providers: [ToastService, MessageService],
})
export class AddressCustomersComponent implements OnInit {
  public address: Address = new Address();
  public addressDisable: AddressDisable = new AddressDisable();
  public addressError: AddressError = new AddressError();
  public user: User = new User();

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private loaderService: LoaderService,
    private metaServices: MetaTagsService,
    private toastService: ToastService,
    private userService: UserService
  ) {
    this.authService.userData$.subscribe((res: any) => {
      this.user = new User();
      if (res) {
        this.user = new User();
        this.user.fromJson(res);
        this.address = this.user.getAddress(true);
        this.address.id = undefined;
        this.addressDisable.complement = false;
        this.addressDisable.number = false;
      }
    });
  }

  async ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_CUSTOMER_AREA_ADDRESS,
        url[0]?.path,
        '',
        '',
        null
      );
    });
  }

  onClickUpdate() {
    const validUser = this.validAddress();
    if (validUser) {
      this.update();
    } else {
      this.toastService.error('É necessário preencher todos os campos!');
    }
  }

  validAddress() {
    let hasError = true;
    this.addressError.errorZipCode = !validZipCode(this.address.zipCode || '');
    this.addressError.errorAddress = !this.address.address;
    this.addressError.errorNumber = !this.address.numberAddress;
    this.addressError.errorComplement = !this.address.complement;
    this.addressError.errorDistrict = !this.address.district;
    this.addressError.errorUF = !this.address.uf;
    this.addressError.errorCity = !this.address.city;

    hasError =
      this.addressError.errorZipCode ||
      this.addressError.errorAddress ||
      this.addressError.errorNumber ||
      this.addressError.errorComplement ||
      this.addressError.errorDistrict ||
      this.addressError.errorUF ||
      this.addressError.errorCity;

    return !hasError;
  }

  async update() {
    try {
      this.loaderService.showLoader();
      await this.userService.update(this.user);
      this.loaderService.hideLoader();
      this.toastService.success('Seus endereço foi alterado com sucesso!');
    } catch (error: any) {
      this.loaderService.hideLoader();
      this.toastService.error(error || 'Erro ao alterar seu endereço!');
    }
  }
}
