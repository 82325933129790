export default class Coupon {
  static CATEGORY = 'CATEGORY';
  static SUB_CATEGORY = 'SUBCATEGORY';
  static ALL_PRODUCTS = 'ALLPRODUCTS';
  static FIXED = 'FIXED';
  static PERCENT = 'PERCENT';

  public amountUse?: number;
  public category?: string;
  public code?: string;
  public deletedAt?: string;
  public description?: string;
  public discountType?: string;
  public endDate?: string;
  public id?: number;
  public meanOfPayment?: string;
  public percentOff?: number;
  public startDate?: string;
  public status?: string;
  public subCategory?: string;
  public type?: string;
  public value?: number;
}
