import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrimeNGModule } from '../../../primeng.module';
import Carrying from '../../models/freight/carrying';
import FreightSelected from '../../models/freight/freight-selected';
import QuoteList from '../../models/freight/quote-list';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-freight-quotes',
  standalone: true,
  imports: [NgIf, NgFor, NgClass, PrimeNGModule, SharedModule],
  templateUrl: './freight-quotes.component.html',
  styleUrl: './freight-quotes.component.scss',
})
export class FreightQuotesComponent {
  @Output()
  public changeFreightSelected: EventEmitter<FreightSelected> =
    new EventEmitter<FreightSelected>();

  @Input()
  public errorFreight: string | undefined;

  @Input()
  public freightSelected: FreightSelected | undefined;

  @Input()
  public loadingFreight = false;

  @Input()
  public quotes: QuoteList | undefined;

  async setFreightSelected(carrier: Carrying) {
    this.freightSelected = new FreightSelected(
      carrier,
      this.quotes?.tokenOffer || ''
    );

    this.changeFreightSelected.emit(this.freightSelected);
  }
}
