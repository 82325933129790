<p-toast position="top-center" [baseZIndex]="9999"></p-toast>
<p-dialog
  header="Resgate seu CUPOM"
  [visible]="true"
  [modal]="true"
  [style]="{ width: '35vw', height: '60vh' }"
  [draggable]="false"
  [resizable]="false"
  (onHide)="onClickClose()"
  [(visible)]="visible"
>
  <h1>Que tal 7% de desconto na sua primeira compra?</h1>
  <h2>
    Cadastre-se agora e ganhe 7% de desconto na sua primeira compra a vista
  </h2>
  <div class="inputs">
    <input
      type="text"
      placeholder="Seu nome..."
      name="name"
      pInputText
      [(ngModel)]="couponData.name"
      [ngClass]="{
        'error-input': couponError.errorName
      }"
    />
    <input
      type="text"
      placeholder="Seu e-mail..."
      required
      pInputText
      name="email"
      [(ngModel)]="couponData.email"
      [ngClass]="{
        'error-input': couponError.errorEmail
      }"
    />
    <p-inputMask
      mask="(99) 99999-9999"
      name="phone"
      [(ngModel)]="couponData.phone"
      placeholder="Seu telefone com DDD..."
      [ngClass]="{
        'error-input-mask': couponError.errorPhone
      }"
    ></p-inputMask>
  </div>

  <button *ngIf="!showCoupon" (click)="onClickCoupon()">QUERO MEU CUPOM</button>

  <h3 *ngIf="showCoupon">7PRIMEIRACOMPRA</h3>

  <p>
    *Ao se cadastrar, você concorda com nosso
    <a target="_blank" href="aviso-de-privacidade">Aviso de Privacidade!</a>
  </p>
</p-dialog>
