import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PrimeNGModule } from '../../../primeng.module';
import InformationSection from '../../models/product/information-section';
import { SharedModule } from '../../shared.module';
import { YoutubeVideoComponent } from '../youtube-video/youtube-video.component';

@Component({
  selector: 'app-products-shop-sessions',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    YoutubeVideoComponent,
    PrimeNGModule,
    SharedModule,
  ],
  templateUrl: './products-shop-sessions.component.html',
  styleUrl: './products-shop-sessions.component.scss',
})
export class ProductsShopSessionsComponent {
  @Input()
  public set sessions(sessions: Array<InformationSection>) {
    if (sessions) {
      this._sessions = sessions;
      this.clickTab(this._sessions[0], null);
    }
  }

  activeIndex: number = 0;
  _sessions?: Array<InformationSection> = [];
  sessionSelected?: InformationSection;

  clickTab(info: InformationSection, event: any) {
    info.selected = !info?.selected;
    const otherMenis = this._sessions?.filter((tab) => tab.id !== info.id);
    otherMenis?.forEach((i) => (i.selected = false));
    this.sessionSelected = info;
    if (event) {
      event.scrollIntoView();
    }
  }
}
