import CreditCart from './credit-cart';
import PaymentSender from './payment-sender';

export default class CheckoutPayment {
  public bank?: any;
  public creditCart?: CreditCart | null;
  public method?: string;
  public sender?: PaymentSender;

  constructor(
    creditCart: CreditCart | null,
    method: string | undefined,
    sender: PaymentSender,
    bank: string | null
  ) {
    this.creditCart = creditCart;
    this.method = method;
    this.sender = sender;
    if (bank) {
      this.bank = { name: bank };
    }
  }
}
