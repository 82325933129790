<div class="breadcrumb-section">
  <h1 title="{{ path }}" style="display: none" color="#404040">{{ path }}</h1>
  <div class="container">
    <div
      class="breadcrumb-main"
      [ngClass]="{
        'breadcrumb-main-left': !path
      }"
    >
      <h1>{{ title || path }}</h1>
      <div class="breadcrumb-nav">
        <nav aria-label="breadcrumb">
          <ul>
            <li><a href="/">Home</a></li>

            <li *ngIf="showTitle" class="active" aria-current="page">
              {{ path }}
            </li>

            <li *ngIf="showCategory" class="active" aria-current="page">
              {{ category.title }}
            </li>

            <li
              *ngIf="showSubCategory || showProduct"
              class="active"
              aria-current="page"
            >
              <a href="{{ category.path }}">{{ category.title }}</a>
            </li>

            <li *ngIf="showSubCategory" class="active" aria-current="page">
              {{ subCategory.title }}
            </li>

            <li *ngIf="showProduct" class="active" aria-current="page">
              <a href="{{ subCategory.path }}">{{ subCategory.title }}</a>
            </li>

            <li *ngIf="showProduct" class="active" aria-current="page">
              {{ productName }}
            </li>

            <li *ngIf="justShowProduct" class="active" aria-current="page">
              Produtos
            </li>

            <li *ngIf="justShowProduct" class="active" aria-current="page">
              {{ querySearch }}
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
