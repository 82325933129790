import { Component } from '@angular/core';
import { LoaderService } from '../../../services/loader/loader.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [NgClass],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent {
  constructor(public loader: LoaderService) {}
}
