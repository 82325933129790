export class ArrayUtils {
  static sort(array: Array<any>, property: string, desc = false) {
    if (this.isNotEmpty(array)) {
      array.sort((left, right) => {
        if (desc) {
          if (left[property] < right[property]) {
            return 1;
          }
          if (left[property] > right[property]) {
            return -1;
          }
        } else {
          if (left[property] < right[property]) {
            return -1;
          }
          if (left[property] > right[property]) {
            return 1;
          }
        }
        return 0;
      });
    }
  }

  /**
   * Determines whether not empty is
   *
   * @author Vinícius
   * @returns true if not empty
   *
   */
  static isNotEmpty(array: Array<any>): boolean {
    return Array.isArray(array) && array.length > 0;
  }
}
