import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaTagsService } from '../../services/meta-tags/meta-tags.service';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-privacy',
  standalone: true,
  imports: [BreadcrumbComponent],
  templateUrl: './privacy.component.html',
  styleUrl: './privacy.component.scss',
})
export class PrivacyComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private metaServices: MetaTagsService
  ) {}

  ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_PRIVACY,
        url[0]?.path,
        '',
        '',
        null
      );
    });
  }
}
