import Carrying from './carrying';
import Freight from './freight';
export default class FreightSelected {
  public freight?: Freight | null;
  public optionFreight?: string;

  constructor(carrier: Carrying, tokenOffer: string) {
    if (!carrier) {
      this.freight = {};
      this.optionFreight = Freight.STORE_PICKUP;
    } else {
      this.freight = new Freight(carrier, tokenOffer);
      this.optionFreight = carrier?.optionFreight;
    }
  }
}
