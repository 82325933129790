import { NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaTagsService } from '../../services/meta-tags/meta-tags.service';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';

export class CatalogsItem {
  public title = '';
  public url = '';
}

@Component({
  selector: 'app-catalogs',
  standalone: true,
  imports: [NgFor, BreadcrumbComponent],
  templateUrl: './catalogs.component.html',
  styleUrl: './catalogs.component.scss',
})
export class CatalogsComponent {
  public catalogs: CatalogsItem[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private metaServices: MetaTagsService
  ) {}

  ngOnInit() {
    this.setCatalogs();
    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_CATALOG,
        url[0]?.path,
        '',
        '',
        null
      );
    });
  }

  setCatalogs() {
    this.catalogs.push(
      {
        title: 'Certificado de Garantia',
        url: 'https://d20p8exwoll05u.cloudfront.net/catalogs/rm-certificado-garantia.pdf',
      },
      {
        title: 'ACM',
        url: 'https://d20p8exwoll05u.cloudfront.net/catalogs/rm-catalogo-acm-e-guia-de-instalacao.pdf',
      },
      {
        title: 'Policarbonatos e Coberturas',
        url: 'https://d20p8exwoll05u.cloudfront.net/catalogs/rm-catalogo-policarbonato-e-guia-de-instalacao.pdf',
      },
      {
        title: 'Lonas e Acessórios',
        url: 'https://d20p8exwoll05u.cloudfront.net/catalogs/rm-catalogo-lonas-e-acessorios.pdf',
      },
      {
        title: 'Guia Instalação Kit Cobertura',
        url: 'https://d20p8exwoll05u.cloudfront.net/catalogs/rm-guia-rapido-instalacao-kit-de-cobertura.pdf',
      },
      {
        title: 'Manual Motor TUBE Rádio Frequência NR-Rn',
        url: 'https://d20p8exwoll05u.cloudfront.net/catalogs/rm-manual-motor-tube-nr2-100-12-220v.pdf',
      },
      {
        title: 'Manual de Persianas',
        url: 'https://d20p8exwoll05u.cloudfront.net/catalogs/manual-de-persianas-rm-policarbbonatos.pdf',
      },
      {
        title: 'Catálogo de Persianas',
        url: 'https://d20p8exwoll05u.cloudfront.net/catalogs/rm-catalogo-persianas.pdf',
      }
    );
  }
}
