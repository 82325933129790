export default class ReportUpdateInfo {
  availableReaders?: Array<string>;
  id?: string;
  label?: string;
  description?: string;

  fromJson(reportUpdateObj: any) {
    if (reportUpdateObj != null) {
      this.id = reportUpdateObj.id;
      this.label = reportUpdateObj.label;
      this.description = reportUpdateObj.label;
      this.availableReaders = reportUpdateObj.availableReaders;
    }
  }
}
