import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ProductsComponent } from '../../../pages/products/products.component';
import { PrimeNGModule } from '../../../primeng.module';
import { AppInfoService } from '../../../services/app-info/app-info.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { ProductsService } from '../../../services/product/products.service';
import { ToastService } from '../../../services/toast/toast.service';
import { InfoCategory } from '../../models/app/info-category';
import { PageResponse } from '../../models/page/page-response';
import InfoSubCategory from '../../models/product/info-sub-category';
import { Product } from '../../models/product/product';
import ProductFilter from '../../models/product/product-filter';
import {
  BreadcrumbComponent,
  BreadcrumbData,
} from '../breadcrumb/breadcrumb.component';
import { CarouselProductsComponent } from '../carousel-products/carousel-products.component';
import { MoreInformationComponent } from '../more-information/more-information.component';
import {
  ConfigCategories,
  ProductsShopComponent,
} from '../products-shop/products-shop.component';

@Component({
  selector: 'app-subcategories',
  standalone: true,
  imports: [
    NgIf,
    RouterOutlet,
    BreadcrumbComponent,
    ProductsShopComponent,
    CarouselProductsComponent,
    MoreInformationComponent,
    PrimeNGModule,
  ],
  templateUrl: './subcategories.component.html',
  styleUrl: './subcategories.component.scss',
  providers: [ToastService, MessageService],
})
export class SubcategoriesComponent {
  public categories: Array<InfoCategory> = [];
  public category: BreadcrumbData = new BreadcrumbData();
  public categorySelected: InfoCategory | undefined;
  public configCategories?: ConfigCategories;
  public loadProducts: boolean = true;
  public productsRelated: Product[] = [];
  public subCategory: BreadcrumbData = new BreadcrumbData();
  public subCategorySelected: InfoSubCategory | undefined;
  public showBreadcrumbSub: boolean = true;
  public showOthersInformations: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appInfo: AppInfoService,
    private metaServices: MetaTagsService,
    private productsService: ProductsService
  ) {}

  async ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.configPage(url[0]?.path);
    });
  }

  configPage(path: string) {
    this.showBreadcrumbSub = true;
    this.subCategory.title = this.metaServices.getTitlePageSubCategory(
      this.category.id,
      path
    );
    this.subCategory.path = this.category.path + '/' + path;
    this.subCategory.id = path?.toUpperCase()?.replaceAll('-', '_');
    this.metaServices.configTagPages(
      MetaTagsService.PAGE_SUB_CATEGORIES,
      path,
      this.category?.path,
      '',
      null
    );
    this.getProductsRelated();
    this.configItens();
  }

  setConfigRoute(component: ProductsComponent) {
    this.showBreadcrumbSub = false;
    this.showOthersInformations = false;
    this.loadProducts = false;
    component.category = this.category;
    component.subCategory = this.subCategory;
  }

  async configItens() {
    this.categories = await this.appInfo.getCategoriesWithProductActive();
    this.categorySelected = this.categories.find(
      (c) => c.id === this.category.id
    );
    if (this.categorySelected) {
      this.subCategorySelected = this.categorySelected?.subCategories?.find(
        (s) => s.id === this.subCategory.id
      );

      this.configCategories = {
        categories: this.categories,
        categorySelected: this.categorySelected,
        subCategorySelected: this.subCategorySelected,
        productsOnOffer: false,
        productsQuerySearch: undefined,
      };
    }
  }

  async getProductsRelated() {
    try {
      const filter: ProductFilter = new ProductFilter();
      filter.inactive = false;
      filter.perPage = 40;
      filter.withDiscountOrFeatured = true;
      filter.discount = 5;
      const pageRes: PageResponse | undefined =
        await this.productsService.products(filter);
      if (pageRes) {
        this.productsRelated = pageRes.content || [];
      }
    } catch (error) {
      console.log(error);
    }
  }
}
