<div class="not-found">
  <div class="container">
    <div class="not-found-main">
      <app-has-no-itens
        [title]="'PÁGINA NÃO ENCONTRADA!'"
        [labelButton]="'ENCONTRAR PRODUTOS'"
        [route]="'/coberturas-em-policarbonato/policarbonato-alveolar'"
      ></app-has-no-itens>
    </div>
  </div>
</div>
