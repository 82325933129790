export class AbstractInfoEnum {
  id?: string;
  description?: string;
  hasActiveProduct?: boolean;
  path?: string;

  fromJson(infObj: any) {
    this.id = infObj.id;
    this.description = infObj.description;
    this.hasActiveProduct = infObj.hasActiveProduct;
  }
}
