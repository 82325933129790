import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PrimeNGModule } from '../../../primeng.module';
import { CartService } from '../../../services/cart/cart.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { ToastService } from '../../../services/toast/toast.service';
import { CartItem } from '../../models/cart/cart';
import { Product } from '../../models/product/product';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-products-shop-similar',
  standalone: true,
  imports: [CurrencyPipe, PrimeNGModule, SharedModule],
  templateUrl: './products-shop-similar.component.html',
  styleUrl: './products-shop-similar.component.scss',
  providers: [ToastService, MessageService],
})
export class ProductsShopSimilarComponent {
  @Input()
  public similarProduct: Product | undefined;

  @Output()
  public onClickbtnClose: EventEmitter<boolean> = new EventEmitter(true);

  public visible: boolean = true;

  onClickClose() {
    this.visible = false;
    this.onClickbtnClose.emit(true);
  }

  constructor(
    private cartService: CartService,
    public loaderService: LoaderService,
    private toastService: ToastService
  ) {}

  async onClickAddToCart() {
    try {
      if (!this.similarProduct?.enabled) {
        return this.toastService.error(
          `Produto ${this.similarProduct?.id} não pode ser adicionado ao seu carrinho pois está inativo!`
        );
      }

      if (
        this.similarProduct?.qtd > this.similarProduct?.stockBalance &&
        !this.similarProduct?.canBeGeneratedWithStockZero
      ) {
        return this.toastService.error(
          `Quantidade informada não disponível. Existe apenas ${this.similarProduct?.stockBalance} item(s) disponível(is)`
        );
      }

      this.loaderService.showLoader();

      const cartItem: CartItem = {
        productId: this.similarProduct?.id,
        amount: this.similarProduct?.qtd || 1,
        product: this.similarProduct,
      };

      setTimeout(async () => {
        await this.cartService.addItem(cartItem, true);
        this.loaderService.hideLoader();
        this.toastService.success(
          'Produto adicionado ao carrinho com sucesso!',
          2000
        );
      }, 500);
    } catch (err) {
      this.loaderService.hideLoader();
      this.toastService.error(
        'Ocorreu um erro ao tentar adicionar seu produto ao carrinho!'
      );
    }
  }
}
