<app-breadcrumb
  [path]="'Checkout Confiável'"
  [showTitle]="true"
></app-breadcrumb>

<div class="payment-main">
  <div class="container">
    <div class="payment-main-content">
      <div class="payment-main-content-address">
        <h2>ENDEREÇO PARA ENTREGA</h2>
        <div class="payment-main-content-address-main">
          <app-address
            [address]="address"
            [addressError]="addressError"
            [addressDisable]="addressDisable"
            [showKnowZipCoded]="false"
            (clearedZip)="clearedZip()"
            (foundAddress)="foundAddress($event)"
          ></app-address>
          <app-freight-quotes
            [loadingFreight]="loadingFreight"
            [quotes]="quotes"
            [freightSelected]="cartCheckout?.freightSelected"
            (changeFreightSelected)="setFreightSelected($event)"
          ></app-freight-quotes>
        </div>
      </div>
      <div class="payment-main-content-payment">
        <div class="payment-main-content-payment-coupon">
          <h2>CUPOM DESCONTO</h2>
          <div>
            <input
              placeholder="Informe seu cupom"
              aria-label="Cupom desconto"
              type="text"
              name="coupon"
              pInputText
              (keydown.enter)="onClickBtcalculateCoupon()"
              [(ngModel)]="coupon"
            />
            <button
              (click)="onClickBtcalculateCoupon()"
              title="Calcular cupom desconto"
              class="button-rm"
              aria-label="Calcular cupom desconto"
            >
              USAR
            </button>
          </div>
        </div>
        <div class="payment-main-content-payment-methods">
          <h2>FORMAS DE PAGAMENTO</h2>
          <div class="payment-methods-radiogroup">
            <div class="payment-methods-radio">
              <p-radioButton
                name="payment"
                value="PIX"
                [(ngModel)]="paymentSelected"
                (ngModelChange)="onChangePaymentPix()"
                inputId="pix"
              ></p-radioButton>
              <label for="pix">PIX</label>
            </div>
            <div class="payment-methods-radio">
              <p-radioButton
                name="payment"
                value="CARTAO_CREDITO"
                [(ngModel)]="paymentSelected"
                (ngModelChange)="onChangePaymentCard()"
                inputId="cartao"
              ></p-radioButton>
              <label for="cartao">CARTÃO</label>
            </div>
          </div>
          <div
            class="payment-methods-descriptions"
            *ngIf="paymentSelected === 'CARTAO_CREDITO'"
          >
            <app-credit-card-input
              [card]="card"
              [cardInputError]="cardInputError"
              [paymentMethods]="paymentMethods"
            ></app-credit-card-input>
          </div>
          <div
            class="payment-methods-descriptions"
            *ngIf="paymentSelected === 'BOLETO'"
          >
            <p>
              O boleto bancário será exibido após a confirmação da sua compra e
              poderá ser impresso para pagamento em qualquer agência bancária ou
              casas lotéricas.
            </p>
            <p>Você terá 3 dias úteis para efetuar o pagamento do boleto.</p>
            <p>
              O prazo de entrega dos pedidos com boleto começa a contar após a
              confirmação do pagamento pela instituição financeira.
            </p>
            <p>
              Em caso de não pagamento do boleto, seu pedido será cancelado.
            </p>
          </div>
          <div
            class="payment-methods-descriptions"
            *ngIf="paymentSelected === 'PIX'"
          >
            <p>
              Os dados do PIX são exibidos após a conclusão do seu pedido e
              poderá ser acessado novamente nos seus pedidos.
            </p>
          </div>
        </div>
      </div>
      <div class="payment-main-content-resume">
        <h2>RESUMO DA COMPRA</h2>
        <div class="payment-resume-main">
          <div class="payment-resume-products">
            <a
              href="{{ item?.product?.url }}"
              target="_blank"
              class="payment-resume-products-item"
              *ngFor="let item of cartCheckout?.cart?.cartProducts"
            >
              <div class="payment-resume-products-item-description">
                <img
                  [src]="item?.product?.photos?.slice()?.shift()?.url"
                  [alt]="item?.product?.name"
                  width="70"
                  height="70"
                />
                <p>
                  {{ item?.product?.name }} -
                  {{
                    item?.product?.productPerSquareMeter
                      ? "(" +
                        item?.customWidth?.toFixed(2) +
                        " m x " +
                        item?.customHeight?.toFixed(2) +
                        " m) - "
                      : ""
                  }}
                  {{ item?.product?.brandDescription }} -
                  {{ item?.product?.id }}
                  <small *ngIf="item?.product?.isPersiana()">
                    | Comando:
                    <span>{{ item?.controlSideDescription }}</span>
                    | Acionamento:
                    <span>{{ item?.driveDescription }}</span>
                    <small *ngIf="!item?.product?.isPersianaRomana()">
                      | Acabamento: </small
                    ><span>{{ item?.panelDescription }}</span>
                  </small>
                </p>
              </div>

              <div class="payment-resume-products-item-value">
                <p>Quantidade: {{ item.amount }}</p>
                <p>
                  <span *ngIf="item.discount">
                    {{ item.gross | customCurrency }}
                  </span>
                  &nbsp;&nbsp;{{ item.total | customCurrency }}
                </p>
              </div>
            </a>
          </div>
          <div class="payment-resume-totals">
            <div class="payment-resume-subtotal">
              <p>Subtotal</p>
              <p>
                {{ cartCheckout?.gross || 0 | customCurrency }}
              </p>
            </div>

            <div class="payment-resume-subtotal" *ngIf="couponDiscount">
              <p>
                Cupom: {{ couponDiscount.coupon?.code
                }}<span
                  class="payment-resume-subtotal-coupon"
                  (click)="onClickBtRemoveCoupon()"
                  >Remover</span
                >
              </p>
              <p>
                {{ couponDiscount.totalDiscount || 0 | customCurrency }}
              </p>
            </div>
            <div
              class="payment-resume-subtotal"
              *ngIf="
                haveProductDiscount() &&
                paymentSelected === 'PIX' &&
                !couponDiscount
              "
            >
              <p class="payment-resume-subtotal-discount">
                Desconto do produto
              </p>
              <p class="payment-resume-subtotal-discount">
                -
                {{ cartCheckout?.discount | customCurrency }}
              </p>
            </div>
            <div
              class="payment-resume-subtotal"
              *ngIf="
                !haveProductDiscount() &&
                paymentSelected === 'PIX' &&
                !couponDiscount
              "
            >
              <p>Desconto à vista</p>
              <p>
                {{ getValueCashDiscount() | customCurrency }}
              </p>
            </div>
            <div
              *ngIf="
                (cartCheckout?.freightSelected?.freight?.priceFreight || 0) > 0
              "
              class="payment-resume-subtotal"
            >
              <p>Frete</p>
              <p>
                +
                {{
                  cartCheckout?.freightSelected?.freight?.priceFreight || 0
                    | customCurrency
                }}
              </p>
            </div>
            <div class="payment-resume-total">
              <p>Total</p>
              <p>{{ getFinalValue() || 0 | customCurrency }}</p>
            </div>
          </div>
          <button
            (click)="onClickCheckout()"
            title="Realizar o pagamento e finalizar sua compra"
            class="button-rm"
            aria-label="Realizar o pagamento e finalizar sua compra"
          >
            FINALIZAR SUA COMPRA
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal-pix
  [order]="order"
  [pix]="pix"
  [showCountDown]="true"
  [visible]="showModalPix"
  (closeModal)="closeModalPix()"
  (paymentAccept)="userService.goToCustomerOrders()"
></app-modal-pix>
