export default class OrderTransaction {
  base64QrCode?: string;
  installmentCount?: number;
  paymentCode?: string;
  paymentCodeDescription?: string;
  paymentLink?: string;
  paymentType?: string;
  paymentTypeDescription?: string;
  pix?: string;
  status?: string;
  type?: number;

  getStatus() {
    switch (this.status) {
      case 'AGUARDANDO_PAGAMENTO':
        return 'Aguardando Pagamento';
      case 'CANCELADA':
        return 'Pagamento Cancelado';
      case 'PAGA':
        return 'Pagamento Confirmado';
      default:
        return '';
    }
  }
}
