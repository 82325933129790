<div
  class="youtube-video-container"
  [attr.id]="'video-' + videoId + sufixId"
  (click)="loadVideo()"
>
  <img class="youtube-thumb" [alt]="title" [src]="videoImage" />
  <div class="youtube-play-button">
    <i class="pi pi-youtube"></i>
  </div>
</div>
