import { environment } from '../../../../environments/environment';

export default class Installment {
  // Quantidade de parcelas sem juros. O mesmo do método getInstallments, maxInstallmentNoInterest.
  public noInterestInstallmentQuantity?: number;

  // Quantidade de parcelas escolhidas pelo cliente.
  public quantity?: number;

  // Valor das parcelas obtidas no serviço de opções de parcelamento.
  public value?: number;

  constructor(installment: any) {
    this.noInterestInstallmentQuantity = environment.maxInstallmentNoInterest;
    this.quantity = installment.quantity;
    this.value = installment.installmentAmount;
  }
}
