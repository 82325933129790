import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrimeNGModule } from '../../primeng.module';
import { ContactService } from '../../services/contact/contact.service';
import { LoaderService } from '../../services/loader/loader.service';
import { MetaTagsService } from '../../services/meta-tags/meta-tags.service';
import { WhatsAppService } from '../../services/whats-app/whats-app.service';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { ContactFormComponent } from '../../shared/components/contact-form/contact-form.component';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [BreadcrumbComponent, ContactFormComponent, PrimeNGModule],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss',
})
export class ContactComponent {
  public typeContact = ContactService.TYPE_CONTACT;

  constructor(
    private activatedRoute: ActivatedRoute,
    public loaderService: LoaderService,
    private metaServices: MetaTagsService,
    private whatsAppService: WhatsAppService
  ) {}

  async ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_CONTACT,
        url[0]?.path,
        '',
        '',
        null
      );
    });
  }

  onClickWhatss() {
    this.whatsAppService.setOpenWhatsApp(true);
  }
}
