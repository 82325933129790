<div
  id="products-shop-sessions-target"
  class="products-shop-sessions mobile-invisible"
>
  <div *ngIf="sessionSelected" class="products-shop-sessions-header">
    <div
      class="products-shop-sessions-header-title"
      *ngFor="let ses of _sessions"
      [ngClass]="{
        'products-shop-sessions-active': ses.selected
      }"
      (click)="clickTab(ses, null)"
    >
      {{ ses.title }}
    </div>
  </div>
  <div *ngIf="sessionSelected" class="products-shop-sessions-content">
    <div
      *ngIf="sessionSelected.title !== 'Vídeo'"
      [innerHTML]="sessionSelected.description || '' | safe : 1"
    ></div>

    <div
      class="products-shop-sessions-video"
      *ngIf="sessionSelected.title === 'Vídeo'"
    >
      <app-youtube-video
        [sufixId]="'-2'"
        [title]="sessionSelected.title"
        [url]="sessionSelected.description"
      ></app-youtube-video>
    </div>
  </div>
</div>

<div
  #infoSectionTarget
  id="products-shop-sessions-mobile-target"
  class="products-shop-sessions mobile-visible"
>
  <div *ngIf="sessionSelected" class="products-shop-sessions-header">
    <div
      class="products-shop-sessions-header-title"
      *ngFor="let ses of _sessions"
      [ngClass]="{
        'products-shop-sessions-active': ses.selected
      }"
      (click)="clickTab(ses, infoSectionTarget)"
    >
      <h1>{{ ses.title }}</h1>
      <div
        *ngIf="ses.selected && ses.title !== 'Vídeo'"
        class="products-shop-sessions-content"
        [innerHTML]="ses.description || '' | safe : 1"
      ></div>
    </div>
    <div
      class="products-shop-sessions-video"
      *ngIf="sessionSelected.title === 'Vídeo'"
    >
      <app-youtube-video
        [sufixId]="'-2'"
        [title]="sessionSelected.title"
        [url]="sessionSelected.description"
      ></app-youtube-video>
    </div>
  </div>
</div>
