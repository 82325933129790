import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserForgot } from '../../pages/forgot/forgot.component';
import User from '../../shared/models/user/user';
import { AuthService } from '../auth/auth.service';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private authService = inject(AuthService);

  constructor(private httpService: HttpService, public router: Router) {}

  public validate(email: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.post(`user/validate/${email}/`, null).subscribe({
        next: () => {
          resolve(true);
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao validar o E-mail!';
          reject(msg);
        },
      });
    });
  }

  public save(user: User): Promise<boolean> {
    return new Promise((resolve, reject) => {
      user.roles = [{ name: 'ROLE_USER' }];
      this.httpService.post(`user/`, user).subscribe({
        next: () => {
          resolve(true);
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao cadastrar seu usuário!';
          reject(msg);
        },
      });
    });
  }

  public forgot(email: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`user/password-recovery/${email}/`).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao solicitar uma nova senha!';
          reject(msg);
        },
      });
    });
  }

  public update(user: User): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.put(`user/${user.id}/`, user).subscribe({
        next: async () => {
          await this.authService.refreshUser(user);
          resolve(true);
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao alterar seus dados!';
          reject(msg);
        },
      });
    });
  }

  public changePassword(userForgot: UserForgot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService
        .post(`user/password-recovery/${userForgot.email}/`, {
          token: userForgot.token,
          password: userForgot.password,
          confirmPassword: userForgot.password,
        })
        .subscribe({
          next: (res: any) => {
            resolve(res);
          },
          error: (error: any) => {
            const msg =
              error?.error?.message ||
              error?.error?.error ||
              'Ocorreu um erro ao alterar a sua senha!';
            reject(msg);
          },
        });
    });
  }

  public goToLogin() {
    this.router.navigate(['login']);
  }

  public goToCustomerOrders() {
    this.router.navigate(['area-do-cliente/meus-pedidos']);
  }

  public goToPayment() {
    this.router.navigate(['pagamento']);
  }

  public goToHome() {
    this.router.navigate(['']);
  }
}
