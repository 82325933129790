<p-dropdown
  [options]="options"
  name="name"
  (onChange)="optionSelected()"
  optionLabel="name"
  placeholder="Escolha uma ordem"
  [(ngModel)]="filterSelected"
  ariaLabel="Ordenação de produtos"
  aria-required="true"
  aria-invalid="false"
></p-dropdown>
