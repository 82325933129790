<form>
  <div class="form-itens">
    <div>
      <label for="zipCode">CEP</label>
      <p-inputMask
        ariaLabel="CEP"
        mask="99999-999"
        name="zipCode"
        id="zipCode"
        [required]="true"
        (onComplete)="getAddress()"
        (onKeydown)="onCleanAddress()"
        (keyup)="onCleanAddress()"
        [ngModel]="address?.zipCode"
        (ngModelChange)="updateAddressField('zipCode', $event)"
        [ngClass]="{
          'error-input-mask': addressError?.errorZipCode
        }"
      ></p-inputMask>
    </div>

    <div>
      <a
        *ngIf="showKnowZipCoded"
        href="https://buscacepinter.correios.com.br/app/endereco/index.php"
        target="_blank"
        >Não sabe o seu CEP?</a
      >
    </div>
  </div>

  <div class="form-itens form-itens-address">
    <div>
      <label for="address">Endereço</label>
      <input
        aria-label="Endereço"
        type="text"
        name="address"
        id="address"
        required
        pInputText
        [ngModel]="address?.address"
        (ngModelChange)="updateAddressField('address', $event)"
        [disabled]="addressDisable?.address ?? false"
        [ngClass]="{
          'error-input': addressError?.errorAddress
        }"
      />
    </div>

    <div>
      <label for="numberAddress">Número</label>
      <input
        [disabled]="addressDisable?.number ?? false"
        aria-label="Número"
        type="text"
        required
        name="numberAddress"
        id="numberAddress"
        pInputText
        [ngModel]="address?.numberAddress"
        (ngModelChange)="updateAddressField('numberAddress', $event)"
        [ngClass]="{
          'error-input': addressError?.errorNumber
        }"
      />
    </div>
  </div>

  <div class="form-itens">
    <div>
      <label for="complement">Complemento</label>
      <input
        aria-label="Complemento"
        type="text"
        name="complement"
        id="complement"
        required
        pInputText
        [maxLength]="20"
        [disabled]="addressDisable?.complement ?? false"
        [ngModel]="address?.complement"
        (ngModelChange)="updateAddressField('complement', $event)"
        [ngClass]="{
          'error-input': addressError?.errorComplement
        }"
      />
    </div>
  </div>

  <div class="form-itens form-itens-district">
    <div>
      <label for="district">Bairro</label>
      <input
        aria-label="Bairro"
        type="text"
        name="district"
        id="district"
        required
        [disabled]="addressDisable?.district ?? false"
        pInputText
        [ngModel]="address?.district"
        (ngModelChange)="updateAddressField('district', $event)"
        [ngClass]="{
          'error-input': addressError?.errorDistrict
        }"
      />
    </div>

    <div>
      <label for="uf">Estado</label>
      <input
        [disabled]="addressDisable?.all ?? false"
        aria-label="Estado"
        type="text"
        required
        name="uf"
        id="uf"
        pInputText
        [ngModel]="address?.uf"
        (ngModelChange)="updateAddressField('uf', $event)"
        [ngClass]="{
          'error-input': addressError?.errorUF
        }"
      />
    </div>

    <div>
      <label for="city">Cidade</label>
      <input
        [disabled]="addressDisable?.all ?? false"
        aria-label="Cidade"
        type="text"
        required
        name="city"
        id="city"
        pInputText
        [ngModel]="address?.city"
        (ngModelChange)="updateAddressField('city', $event)"
        [ngClass]="{
          'error-input': addressError?.errorCity
        }"
      />
    </div>
  </div>
</form>
