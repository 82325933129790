import { NgModule } from '@angular/core';
import { LazyLoadImages } from './directives/lazy-load-images/lazy-load-images.directive';
import { NoRenderDirective } from './directives/no-render/no-render.directive';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { SafePipe } from './pipes/safe.pipe';

@NgModule({
  declarations: [
    SafePipe,
    CustomCurrencyPipe,
    LazyLoadImages,
    NoRenderDirective,
  ],
  imports: [],
  exports: [SafePipe, CustomCurrencyPipe, LazyLoadImages, NoRenderDirective],
})
export class SharedModule {}
