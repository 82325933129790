import User from '../user/user';
import Phone from './phone';
export default class Holder {
  public birthDate?: string; // Data de nascimento do dono do cartão de crédito
  public documentValue?: string;
  public name?: string;
  public phone?: Phone;

  constructor(user: User) {
    this.birthDate = user?.birthDate?.toString();
    this.documentValue = user?.document;
    this.name = `${user.name} ${user?.lastName}`;
    this.phone = new Phone(user?.phone?.toString() || '');
  }
}
