export default class ReportPrintInfo {
  availableFormats?: Array<string>;
  id?: string;
  label?: string;
  description?: string;

  fromJson(reportPrintObj: any) {
    if (reportPrintObj != null) {
      this.id = reportPrintObj.id;
      this.label = reportPrintObj.label;
      this.description = reportPrintObj.label;
      this.availableFormats = reportPrintObj.availableFormats;
    }
  }
}
