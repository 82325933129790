<div
  class="loaderMain"
  [ngClass]="{
    'loaderMain-show': loader.getLoading(),
    'loaderMain-hidden': !loader.getLoading()
  }"
>
  <div class="loader-inner">
    <div id="preloader">
      <div id="loader"></div>
    </div>
  </div>
</div>
