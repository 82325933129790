import Carrying from './carrying';

export default class Freight {
  public static CARRYING = 'CARRYING';
  public static STORE_PICKUP = 'STORE_PICKUP';

  public tokenOffer?: string;
  public offer?: number;
  public cnpj?: string;
  public logo?: string;
  public name?: string;
  public service?: string;
  public serviceDescription?: string;
  public deliveryTime?: number;
  public estimatedDelivery?: string;
  public validity?: string;
  public costFreight?: number;
  public priceFreight?: number;

  constructor(carrier: Carrying, tokenOffer: string) {
    this.tokenOffer = tokenOffer;
    this.offer = carrier.offer;
    this.cnpj = carrier.cnpj;
    this.logo = carrier.logo;
    this.name = carrier.name;
    this.service = carrier.service;
    this.serviceDescription = carrier.serviceDescription;
    this.deliveryTime = carrier.deliveryTime;
    this.estimatedDelivery = carrier.estimatedDelivery;
    this.validity = carrier.validity;
    this.costFreight = carrier.costFreight;
    this.priceFreight = carrier.priceFreight;
  }
}
