import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrimeNGModule } from '../../../../primeng.module';
import { ToastService } from '../../../../services/toast/toast.service';
import { TransactionsService } from '../../../../services/transactions/transactions.service';
import { Pix } from '../../../models/checkout/pix';
import Order from '../../../models/order/order';
import { SharedModule } from '../../../shared.module';
import {
  SIX_SECONDS_IN_MS,
  THREE_SECONDS_IN_MS,
  wait,
} from '../../../utils/wait';
import { CountdownTimerComponent } from '../../countdown-timer/countdown-timer.component';

@Component({
  selector: 'app-modal-pix',
  standalone: true,
  imports: [PrimeNGModule, CountdownTimerComponent, SharedModule],
  templateUrl: './modal-pix.component.html',
  styleUrl: './modal-pix.component.scss',
})
export class ModalPixComponent {
  @Input()
  public order?: Order;

  @Input()
  public pix?: Pix;

  @Input()
  public showCountDown = false;

  @Input()
  public set visible(visible: boolean) {
    if (visible) {
      this._visible = true;
      this._showCountDown = this.showCountDown;
      this.getStatusPix();
    }
  }

  @Output()
  public closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public paymentAccept: EventEmitter<boolean> = new EventEmitter<boolean>();

  public _visible: boolean = false;
  public _showCountDown: boolean = false;

  constructor(
    private toastService: ToastService,
    private transactionsService: TransactionsService
  ) {}

  closeModalPix() {
    this._visible = false;
    this.closeModal.emit(true);
  }

  async onClickCopyCodePix() {
    if (this.pix?.pixData) {
      await navigator.clipboard
        .writeText(this.pix.pixData)
        .then(() => {
          this.toastService.success(
            'Link copiado para área de transferência. Cole no seu app de pagamento!'
          );
        })
        .catch(() => {
          this.toastService.error('Ocorreu um erro ao copiar o link!');
        });
    }
  }

  async getStatusPix(tries = 0) {
    try {
      const res: any = await this.transactionsService.statusPix(
        this.order?.id || 0
      );
      if (res?.status === 'PAGA') {
        this.toastService.success(
          'Pagamento realizado com sucesso! Acompanhe o andamento do seu pedido em "Pedidos".'
        );
        await wait(THREE_SECONDS_IN_MS);
        this.paymentAccept.emit(true);
      } else {
        if (tries < 20) {
          tries++;
          await wait(SIX_SECONDS_IN_MS);
          await this.getStatusPix(tries);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
}
