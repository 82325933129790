import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { PrimeNGModule } from '../../../primeng.module';
import { CouponService } from '../../../services/coupon/coupon.service';
import { ToastService } from '../../../services/toast/toast.service';
import extractNumbers from '../../utils/extract-numbers';
import { validEmail, validName, validPhone } from '../../utils/validators';

export class CouponData {
  public email: string = '';
  public name: string = '';
  public phone: string = '';
}

export class CouponError {
  public errorEmail = false;
  public errorName = false;
  public errorPhone = false;
}

@Component({
  selector: 'app-coupon-home',
  standalone: true,
  imports: [NgClass, NgIf, FormsModule, PrimeNGModule],
  templateUrl: './coupon-home.component.html',
  styleUrl: './coupon-home.component.scss',
  providers: [ToastService, MessageService],
})
export class CouponHomeComponent {
  @Output()
  public onClickbtnClose: EventEmitter<boolean> = new EventEmitter(true);

  public couponData: CouponData = new CouponData();
  public couponError: CouponError = new CouponError();
  public showCoupon: boolean = false;
  public visible: boolean = true;

  constructor(
    private couponService: CouponService,
    private toastService: ToastService
  ) {}

  async onClickCoupon() {
    this.couponError.errorName = !validName(this.couponData.name);
    this.couponError.errorEmail = !validEmail(this.couponData.email);
    this.couponError.errorPhone = !validPhone(this.couponData.phone);

    if (
      this.couponError.errorName ||
      this.couponError.errorEmail ||
      this.couponError.errorPhone
    ) {
      this.toastService.error('É necessário preencher todos os campos!');
    } else {
      try {
        this.showCoupon = true;
        this.couponData.phone = extractNumbers(this.couponData?.phone);
        await this.couponService.save(this.couponData);
      } catch (error: any) {
        console.log(error);
      }
    }
  }

  onClickClose() {
    this.visible = false;
    this.onClickbtnClose.emit(true);
  }
}
