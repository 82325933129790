import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-youtube-video',
  standalone: true,
  imports: [],
  templateUrl: './youtube-video.component.html',
  styleUrl: './youtube-video.component.scss',
})
export class YoutubeVideoComponent {
  @Input() sufixId: string = '';
  @Input() videoId: string | undefined;
  @Input() set url(url: string | undefined) {
    if (!url) return;
    this.videoUrl = url;
    this.videoImage = this.getVideoImage(url);
    if (!this.videoId) this.videoId = this.extractYoutubeVideoId(url);
  }
  @Input() title: string | undefined;
  public videoImage?: string | null;
  public videoUrl?: string | null;

  loadVideo() {
    this.launchVideo(this.videoUrl, this.title);
  }

  private launchVideo(
    url: string | null | undefined,
    title: string | undefined
  ) {
    if (!url) return;
    const videoId = this.extractYoutubeVideoId(url);
    const selectorContainer = `.youtube-video-container#video-${videoId}${this.sufixId}`;

    const iframe = document.createElement('iframe');
    iframe.setAttribute(
      'src',
      `https://www.youtube.com/embed/${videoId}?autoplay=1`
    );
    iframe.setAttribute(
      'allow',
      'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
    );
    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('title', title || '');
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '100%');
    const container: HTMLElement | null =
      document.querySelector(selectorContainer);
    if (!container) return;
    container.innerHTML = '';
    container.appendChild(iframe);
  }

  private extractYoutubeVideoId(url: string): string | undefined {
    const videoIdRegex = /(?:embed\/)([0-9A-Za-z_-]+).*/;
    const videoId = url.match(videoIdRegex)?.[1];
    return videoId || undefined;
  }

  private getVideoImage(
    url: string,
    imageType: string = 'default'
  ): string | null {
    const imageTypes: Record<string, string> = {
      default: 'hqdefault',
      hq: '0',
    };

    const imageTypeSelected = imageTypes[imageType] || imageTypes['default'];

    const videoId = this.extractYoutubeVideoId(url);
    return videoId
      ? `https://img.youtube.com/vi/${videoId}/${imageTypeSelected}.jpg`
      : null;
  }
}
