import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../../../services/cart/cart.service';
import { Cart, CartItem } from '../../models/cart/cart';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-cart-header',
  standalone: true,
  imports: [NgIf, NgFor, SharedModule],
  templateUrl: './cart-header.component.html',
  styleUrl: './cart-header.component.scss',
})
export class CartHeaderComponent {
  @Input()
  public set itensCart(itensCart: Array<CartItem>) {
    if (itensCart?.length > 0) {
      this._itensCart = itensCart;
      this.configCart();
    }
  }

  public _itensCart: Array<CartItem> = [];
  public cart: Cart = {};

  constructor(private cartService: CartService, private router: Router) {}

  async configCart() {
    try {
      this.cart.items = this._itensCart;
      this.cartService.setAuxVariables(this.cart, undefined);
    } catch (err) {
      console.error(err);
    }
  }

  getValueItem(cartProduct: CartItem, whithDiscount: boolean) {
    return this.cartService.getValueItem(cartProduct, whithDiscount);
  }

  onClickOpenProduct(url: string) {
    this.router.navigate([url]);
  }
}
