import { Product } from '../product/product';

export default class CartProductCheckout {
  amount?: number;
  controlSide?: string;
  controlSideDescription?: string;
  customHeight?: number;
  customWidth?: number;
  discount?: number;
  discountPercent?: number;
  drive?: string;
  driveDescription?: string;
  gross?: number;
  id?: number;
  insufficientStock?: boolean;
  panel?: string;
  panelDescription?: string;
  product?: Product;
  typeFixing?: string;
  typeFixingDescription?: string;
  total?: number;
  value?: number;
}
