import MarketplaceTransaction from '../app/marketplace-transaction';
import { CartItem } from '../cart/cart';
import Coupon from '../coupons/coupon';
import Address from '../user/address';
import OrderBuyer from './order-buyer';
import OrderCancel from './order-cancel';
import OrderNFE from './order-nfe';
import OrderPayment from './order-payment';
import OrderShipping from './order-shipping';
import OrderTracking from './order-tracking';
import OrderTransaction from './order-transaction';

export interface OrderEvent {
  id?: number;
  previous?: string;
  updated?: string;
  createdAt: string;
}

export enum OrderStatus {
  CANCELED = 'CANCELED',
  CONCLUDED = 'CONCLUDED',
  INVOICED = 'INVOICED',
  ON_EXPEDITION = 'ON_EXPEDITION',
  PAID_WAITING_DELIVERY = 'PAID_WAITING_DELIVERY',
  PAID_WAITING_SHIP = 'PAID_WAITING_SHIP',
  PENDING = 'PENDING',
}

export const orderStatus = {
  CANCELED: 'Cancelado',
  CONCLUDED: 'Entregue',
  INVOICED: 'Faturado',
  ON_EXPEDITION: 'Em Expedição',
  PAID_WAITING_DELIVERY: 'Enviado',
  PAID_WAITING_SHIP: 'Pago',
  PENDING: 'Pendente',
  order: [
    'PENDING',
    'CONCLUDED',
    'PAID_WAITING_SHIP',
    'ON_EXPEDITION',
    'INVOICED',
    'PAID_WAITING_DELIVERY',
  ],
};

export default class Order {
  address?: Address;
  administratorNotes?: string;
  cartProducts?: Array<CartItem>;
  createdAt?: string;
  currentStatus?: string;
  coupon?: Coupon;
  discount?: number;
  documentUser?: string;
  dtEstimate?: string;
  emailUser?: string;
  freight?: number;
  gross?: number;
  id?: number;
  idUser?: string;
  interestValue?: number;
  marketplaceTransaction?: MarketplaceTransaction;
  nameUser?: string;
  orderHistories?: Array<OrderEvent>;
  orderBuyer?: OrderBuyer;
  orderCanceled?: OrderCancel;
  orderEntry?: string;
  orderHubId?: string;
  orderNFE?: OrderNFE;
  orderPayments?: Array<OrderPayment>;
  orderShipping?: OrderShipping;
  orderTracking?: OrderTracking;
  orderTransaction?: OrderTransaction;
  phoneUser?: string;
  sentToTagmanager?: boolean;
  statusCanceled?: string;
  totalValue?: number;
  totalVolumes?: number;
  totalWeight?: number;
}
