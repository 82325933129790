<p-toast position="top-center" [baseZIndex]="9999"></p-toast>
<p-dialog
  header="Produto não disponível para sua região"
  [visible]="true"
  [modal]="true"
  [style]="{ width: '25vw', height: '60vh' }"
  [draggable]="false"
  [resizable]="false"
  (onHide)="onClickClose()"
  [(visible)]="visible"
>
  <p>Mas não fique triste.</p>
  <p>Para sua região, possuímos o mesmo</p>
  <p>produto com uma medida menor:</p>

  <div class="product-similar">
    <a href="{{ similarProduct?.url }}">
      <img
        src="{{ similarProduct?.coverPhoto }}"
        [alt]="'Foto: ' + similarProduct?.name"
        width="180"
        height="180"
      />
      <p class="product-similar-name">
        {{ similarProduct?.name }}
      </p>

      <div class="product-similar-value">
        <p *ngIf="(similarProduct?.discount || 0) > 0" class="discount">
          de
          <span>{{ similarProduct?.value | customCurrency }}</span> por
        </p>
        <p class="total">
          {{ similarProduct?.valueWithDiscount | customCurrency }}
          <span>{{ similarProduct?.valueInterestFree }}</span>
        </p>
        <p *ngIf="(similarProduct?.discount || 0) <= 0" class="description">
          ou {{ similarProduct?.valueWithCashDiscount | customCurrency }} à
          vista
        </p>
      </div>
    </a>
  </div>
  <p-button
    class="prime-button-rm"
    label="ADICIONAR AO CARRINHO"
    icon="pi pi-shopping-cart"
    iconPos="right"
    aria-label="Adicionar produto ao carrinho"
    (click)="onClickAddToCart()"
  ></p-button>
</p-dialog>
