<p-confirmDialog
  #cd
  [style]="{ width: '40vw' }"
  [baseZIndex]="10000"
  [closable]="false"
  [breakpoints]="{ '960px': '70vw', '640px': '90vw' }"
  [icon]="'pi pi-exclamation-triangle color-warning'"
>
  <p-footer>
    <button class="button-rm" type="button" (click)="cd.reject()">Não</button>
    <button class="button-rm" type="button" (click)="cd.accept()">Sim</button>
  </p-footer></p-confirmDialog
>
