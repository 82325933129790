import { DOCUMENT, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrimeNGModule } from '../../../../primeng.module';
import { AppInfoService } from '../../../../services/app-info/app-info.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { MetaTagsService } from '../../../../services/meta-tags/meta-tags.service';
import { OrderService } from '../../../../services/order/order.service';
import { ToastService } from '../../../../services/toast/toast.service';
import { AbstractInfoEnum } from '../../../models/app/abstract-info-enum';
import Order, { OrderStatus } from '../../../models/order/order';
import OrderFilter from '../../../models/order/order-filter';
import OrderGrid from '../../../models/order/order-grid';
import User from '../../../models/user/user';
import { SharedModule } from '../../../shared.module';
import { OrderCustomersComponent } from '../../order-customers/order-customers.component';

@Component({
  selector: 'app-orders-customers',
  standalone: true,
  imports: [NgIf, PrimeNGModule, OrderCustomersComponent, SharedModule],
  templateUrl: './orders-customers.component.html',
  styleUrl: './orders-customers.component.scss',
  providers: [ToastService, MessageService],
})
export class OrdersCustomersComponent implements OnInit {
  public filter: OrderFilter = {};
  public isMobile = window?.innerWidth < 640;
  public loadingAllOrders = true;
  public loadingOrder = false;
  public marketplaces?: Array<AbstractInfoEnum>;
  public orders: Array<OrderGrid> = [];
  public ordersCache: Array<Order> = [];
  public orderSelected: Order = new Order();
  public orderStatus?: Array<AbstractInfoEnum>;
  public orderStatusInfo?: Array<AbstractInfoEnum>;
  public user: User | undefined = new User();

  constructor(
    private activatedRoute: ActivatedRoute,
    private appInfo: AppInfoService,
    private authService: AuthService,
    private metaServices: MetaTagsService,
    public orderService: OrderService,
    private toastService: ToastService,
    @Inject(DOCUMENT) private dom: any
  ) {
    this.authService.userData$.subscribe((res: any) => {
      this.user = undefined;
      if (res) {
        this.user = new User();
        this.user.fromJson(res);
      }
    });
  }

  async ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_CUSTOMER_AREA_ORDERS,
        url[0]?.path,
        '',
        '',
        null
      );
    });

    this.filter.status = await this.getStatusDefault();
    await this.getOrderStatus();
    this.getOrders();
  }

  async getOrderStatus() {
    try {
      this.orderStatus = await this.appInfo.getOrderStatus();
      this.marketplaces = await this.appInfo.getMarketplaces();
      this.orderStatusInfo = await this.appInfo.getOrderStatusToTimeLine();
    } catch (err) {
      console.error(err);
    }
  }

  async getOrders() {
    try {
      this.loadingAllOrders = true;
      this.orders = [];
      this.filter.direction = 'DESC';
      if (!this.user?.isAdmin) {
        this.filter.idUser = this.user?.id;
      }
      this.orders = await this.orderService.getOrders(
        this.filter,
        this.user?.isAdmin || false
      );
      this.loadingAllOrders = false;
    } catch (error: any) {
      this.loadingAllOrders = false;
      this.toastService.error(error);
    }
  }

  onRowExpand(order: any) {
    if (order) {
      this.getOrder(order.data);
    }
  }

  async getOrder(order: OrderGrid) {
    try {
      this.loadingOrder = true;
      this.orderSelected = await this.orderService.getOrder(order.id || 0);
      this.loadingOrder = false;

      const target = this.dom.getElementById(this.orderSelected.id);
      if (target) {
        const headerFixed = window.scrollY > 100;
        target.scrollIntoView();
        setTimeout(() => {
          let scrolly = headerFixed ? 70 : 140;
          if (this.isMobile) {
            scrolly = headerFixed ? 50 : 115;
          }
          window.scroll({
            top: window.scrollY - scrolly,
          });
        }, 500);
      }
    } catch (error: any) {
      this.loadingOrder = false;
      this.toastService.error(error);
    }
  }

  getStatusDefault(): Array<string> {
    const status = [
      OrderStatus.PENDING,
      OrderStatus.PAID_WAITING_SHIP,
      OrderStatus.ON_EXPEDITION,
      OrderStatus.INVOICED,
      OrderStatus.PAID_WAITING_DELIVERY,
    ];

    if (this.user?.isAdmin) {
      return status;
    } else {
      status.push(OrderStatus.CANCELED);
      status.push(OrderStatus.CONCLUDED);
      return status;
    }
  }
}
