<p-toast position="top-center" [baseZIndex]="9999"></p-toast>
<app-breadcrumb [path]="'Novo Cadastro'" [showTitle]="true"></app-breadcrumb>

<div class="register">
  <div class="container">
    <div class="register-main">
      <div class="register-main-data">
        <h2>DADOS PESSOAIS</h2>
        <div class="register-main-data-user">
          <app-user-data [user]="user" [userError]="userError"></app-user-data>
        </div>
      </div>
      <div class="register-main-address">
        <h2>ENDEREÇO</h2>
        <div class="register-main-address-user">
          <app-address
            [address]="address"
            [addressError]="addressError"
            [addressDisable]="addressDisable"
          ></app-address>
        </div>
      </div>
    </div>

    <div class="register-main-captcha">
      <re-captcha
        (resolved)="resolvedcCaptcha($event)"
        siteKey="{{ siteKey }}"
      ></re-captcha>
    </div>

    <button
      class="button-rm"
      aria-label="Cadastrar novo usuário"
      (click)="onClickRegister()"
    >
      REALIZAR CADASTRO
    </button>
  </div>
</div>
