<router-outlet (activate)="setConfigRoute($event)"></router-outlet>
<app-breadcrumb
  *ngIf="showBreadcrumbSub"
  [category]="category"
  [subCategory]="subCategory"
  [showSubCategory]="true"
></app-breadcrumb>

<div class="subcategories-main">
  <app-products-shop
    *ngIf="showBreadcrumbSub"
    [configCategories]="configCategories"
  ></app-products-shop>
</div>

<app-more-information
  *ngIf="showOthersInformations"
  [subCategory]="subCategorySelected"
></app-more-information>

<div
  class="products-related"
  *ngIf="productsRelated.length > 0 && showOthersInformations"
>
  <div class="container">
    <h1 class="section-title">Outlet</h1>
    <app-carousel-products [products]="productsRelated"></app-carousel-products>
  </div>
</div>
