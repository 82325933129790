import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageConstants } from '../../shared/models/storage/storage-constants';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  token$ = new BehaviorSubject<any>('');

  constructor(private localStorageService: LocalStorageService) {}

  async getToken() {
    await this.localStorageService
      .get(StorageConstants.RM_ECOMMERCE_TOKEN)
      .then((res: any) => {
        return this.token$.next(res);
      });
  }
}
