<p-toast position="top-center" [baseZIndex]="9999"></p-toast>
<form action="">
  <input
    type="text"
    placeholder="Informe seu Nome"
    pInputText
    name="fullName"
    [(ngModel)]="contactData.fullName"
    [ngClass]="{
      'error-input': contactError.errorFullName
    }"
  />
  <input
    type="text"
    placeholder="Informe seu E-mail"
    pInputText
    name="email"
    [(ngModel)]="contactData.email"
    [ngClass]="{
      'error-input': contactError.errorEmail
    }"
  />
  <p-inputMask
    mask="(99) 99999-9999"
    name="phone"
    [(ngModel)]="contactData.phone"
    placeholder="Informe seu telefone com DDD"
    [ngClass]="{
      'error-input-mask': contactError.errorPhone
    }"
  ></p-inputMask>
  <p-inputMask
    name="zipCode"
    mask="99999-999"
    type="text"
    (keyup)="getAddress()"
    [(ngModel)]="zipCode"
    placeholder="Informe seu CEP"
    [ngClass]="{
      'error-input-mask': contactError.errorZipCode
    }"
  ></p-inputMask>

  <p-inputMask
    *ngIf="isDealer()"
    ariaLabel="Seu CNPJ"
    placeholder="Informe seu CNPJ para cadastro"
    mask="99.999.999/9999-99"
    name="cnpj"
    [(ngModel)]="contactData.cnpj"
    [ngClass]="{
      'error-input-mask': contactError.errorCNPJ
    }"
  ></p-inputMask>

  <p-dropdown
    *ngIf="!isDealer()"
    [options]="optionsSubject"
    name="name"
    (onChange)="newChildren()"
    [(ngModel)]="selectedOption"
    optionLabel="name"
    placeholder="Escolha um assunto"
    [ngClass]="{
      'error-input': contactError.errorSubject
    }"
  ></p-dropdown>

  <div *ngIf="isSAC()">
    <p-inputMask
      ariaLabel="Seu CPF"
      placeholder="Informe o CPF utilizado na compra"
      mask="999.999.999-99"
      name="cpf"
      [(ngModel)]="contactData.cpf"
      [ngClass]="{
        'error-input-mask': contactError.errorCPF
      }"
    ></p-inputMask>
    <input
      type="number"
      placeholder="Qual o número da Nota Fiscal"
      pInputText
      name="orderId"
      [(ngModel)]="contactData.change.numberNFE"
      [ngClass]="{
        'error-input': contactError.errorNumberNFE
      }"
    />
    <p-inputMask
      placeholder="Data do recebimento (Não preencher caso não recebeu ainda)"
      mask="99/99/9999"
      name="dtReceipt"
      [(ngModel)]="contactData.change.dtReceipt"
    ></p-inputMask>
  </div>

  <textarea
    rows="3"
    cols="30"
    name="message"
    pInputTextarea
    placeholder="{{ placeholder }}"
    [autoResize]="true"
    [(ngModel)]="contactData.message"
    [ngClass]="{
      'error-input': contactError.errorMessage
    }"
  ></textarea>

  <div *ngIf="!isDealer()">
    <p-fileUpload
      #fileUpload
      [showUploadButton]="false"
      [showCancelButton]="false"
      [chooseLabel]="'ANEXAR ARQUIVO'"
      (onSelect)="onSelectFile($event)"
      accept="image/*"
      [maxFileSize]="3000000"
      [fileLimit]="3"
      [multiple]="true"
      [invalidFileSizeMessageSummary]="'Arquivo Inválido!'"
      [invalidFileSizeMessageDetail]="
        'Seu arquivo ultrapassou o limite de 3 mb.'
      "
      [invalidFileTypeMessageSummary]="'Arquivo Inválido!'"
      [invalidFileTypeMessageDetail]="'Permitido apenas imagens.'"
      [invalidFileLimitMessageSummary]="'Arquivo Inválido!'"
      [invalidFileLimitMessageDetail]="'Permitido enviar apenas 3 arquivos.'"
    >
      <ng-template let-file let-i="index" pTemplate="file">
        <div class="fileupload-row">
          <div>{{ file.name }}</div>
          <div class="fileupload-row-img">
            <img src="{{ file?.base64 }}" alt="" />
          </div>
          <div class="fileupload-row-btn">
            <button
              class="button-rm"
              aria-label="Remover arquivo"
              (click)="fileUpload.remove($event, i)"
            >
              X
            </button>
          </div>
        </div>
      </ng-template>
    </p-fileUpload>
  </div>

  <button
    class="button-rm"
    aria-label="Enviar dados para nosso suporte"
    type="submit"
    (click)="onClickSend()"
  >
    ENVIAR
  </button>
</form>
