import { afterNextRender, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrimeNGModule } from '../../primeng.module';
import { LoaderService } from '../../services/loader/loader.service';
import { MetaTagsService } from '../../services/meta-tags/meta-tags.service';
import { ProductsService } from '../../services/product/products.service';
import { ToastService } from '../../services/toast/toast.service';
import {
  BreadcrumbComponent,
  BreadcrumbData,
} from '../../shared/components/breadcrumb/breadcrumb.component';
import { ProductsShopDetailsComponent } from '../../shared/components/products-shop-details/products-shop-details.component';
import { ProductsShopComponent } from '../../shared/components/products-shop/products-shop.component';
import { ConfigCategories, Product } from '../../shared/models/product/product';

@Component({
  selector: 'app-products',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    PrimeNGModule,
    ProductsShopComponent,
    ProductsShopDetailsComponent,
  ],
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss',
  providers: [ToastService, MessageService],
})
export class ProductsComponent implements OnInit {
  public category: BreadcrumbData = new BreadcrumbData();
  public configCategories?: ConfigCategories;
  public urlName: any;
  public product: Product | undefined = new Product();
  public querySearch: any = null;
  public sku: any;
  public subCategory: BreadcrumbData = new BreadcrumbData();
  private alreadyInitialized = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public loaderService: LoaderService,
    private metaServices: MetaTagsService,
    private productService: ProductsService,
    public router: Router,
    private toastService: ToastService
  ) {
    this.loaderService.showLoader();
    afterNextRender(() => {
      this.init();
    });
  }

  async ngOnInit() {
    this.loaderService.showLoader();
    this.activatedRoute.params.subscribe((params) => {
      this.querySearch = params['querySearch'];
      if (this.alreadyInitialized) {
        this.init();
      }
    });
  }

  init() {
    this.alreadyInitialized = true;
    this.querySearch = this.activatedRoute.snapshot.paramMap.get('querySearch');
    this.sku = this.activatedRoute.snapshot.paramMap.get('sku');
    this.urlName = this.activatedRoute.snapshot.paramMap.get('name');

    if (this.querySearch) {
      this.configProducts();
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_PRODUCTS,
        'produtos/' + this.querySearch,
        '',
        '',
        this.querySearch
      );
    } else if (this.sku) {
      this.configProducts();
      this.configProductWithSku();
    }
  }

  configProducts() {
    this.configCategories = {
      categories: [],
      categorySelected: undefined,
      subCategorySelected: undefined,
      productsOnOffer: false,
      productsQuerySearch: this.querySearch,
    };
  }

  async configProductWithSku() {
    try {
      this.product = await this.productService.get(this.sku);
      this.loaderService.hideLoader();
      if (this.product) {
        this.metaServices.configTagPages(
          MetaTagsService.PAGE_PRODUCTS,
          this.sku + '/' + this.urlName,
          this.category?.path,
          this.subCategory?.path,
          this.product
        );
      }
    } catch (error: any) {
      this.toastService.error(error);
    }
  }
}
