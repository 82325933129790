import Address from '../user/address';

export default class ShippingAddress {
  public address?: string;
  public city?: string;
  public complement?: string;
  public district?: string;
  public numberAddress?: string;
  public reference?: string;
  public uf?: string;
  public zipCode?: string;

  fromAddress(address: Address) {
    this.address = address.address;
    this.city = address.city;
    this.complement = address.complement;
    this.district = address.district;
    this.numberAddress = address.numberAddress;
    this.reference = address?.id?.toString() || '1';
    this.uf = address.uf;
    this.zipCode = address.zipCode;
  }
}
