<form>
  <span class="p-float-label">
    <p-inputMask
      (onComplete)="onComplete()"
      (onKeydown)="onKeydown()"
      ariaLabel="Número do cartão"
      mask="9999.9999.9999.9999"
      name="cardNumber"
      [ngModel]="card?.cardNumber"
      (ngModelChange)="updateCardField('cardNumber', $event)"
      [ngClass]="{
        'error-input-mask': cardInputError?.errorCardNumber
      }"
    ></p-inputMask>
    <label htmlFor="cardNumber">Número do cartão</label>
    <img
      *ngIf="getImageBrand()"
      [src]="getImageBrand()"
      alt="Cartão de crédito."
    />
  </span>

  <span class="p-float-label">
    <input
      aria-label="Nome igual impresso no cartão"
      type="text"
      required
      name="name"
      pInputText
      [ngModel]="card?.name"
      (ngModelChange)="updateCardField('name', $event)"
      [ngClass]="{
        'error-input': cardInputError?.errorName
      }"
    />
    <label htmlFor="name">Nome igual impresso no cartão</label>
  </span>

  <div>
    <span class="p-float-label">
      <p-dropdown
        [options]="optionsMonth"
        aria-label="Mês da data de expiração"
        placeholder="Mês da data de expiração"
        name="expirationMonth"
        [ngModel]="card?.expirationMonth"
        (ngModelChange)="updateCardField('expirationMonth', $event)"
        optionLabel="description"
        [ngClass]="{
          'error-input-dropdown': cardInputError?.errorExpirationMonth
        }"
      ></p-dropdown>

      <label htmlFor="expirationMonth">Mês da data de expiração</label>
    </span>
    <span class="p-float-label">
      <p-dropdown
        [options]="optionsYears"
        aria-label="Ano da data de expiração"
        name="expirationYear"
        placeholder="Ano da data de expiração"
        [ngModel]="card?.expirationYear"
        (ngModelChange)="updateCardField('expirationYear', $event)"
        optionLabel="description"
        [ngClass]="{
          'error-input-dropdown': cardInputError?.errorExpirationYear
        }"
      ></p-dropdown>
      <label htmlFor="expirationYear">Ano da data de expiração</label>
    </span>
    <span class="p-float-label">
      <input
        aria-label="CVV"
        type="text"
        required
        name="cvv"
        pInputText
        [ngModel]="card?.cvv"
        (ngModelChange)="updateCardField('cvv', $event)"
        [maxLength]="3"
        [ngClass]="{
          'error-input': cardInputError?.errorCvv
        }"
      />
      <label htmlFor="cvv">CVV</label>
    </span>
  </div>
  <span class="p-float-label">
    <p-dropdown
      [disabled]="installments.length === 0"
      [options]="installments"
      aria-label="Número de parcelas"
      name="installments"
      placeholder="Número de parcelas"
      [ngModel]="card?.installments"
      (ngModelChange)="updateCardField('installments', $event)"
      optionLabel="description"
      [ngClass]="{
        'error-input-dropdown': cardInputError?.errorInstallments
      }"
    ></p-dropdown>
    <label htmlFor="installments">Número de parcelas</label>
  </span>
</form>
