<div class="menu-bar">
  <div class="container">
    <p-menubar
      #menubar
      [id]="'menu-bar'"
      [model]="items"
      [ariaLabel]="'Navegue pelo nosso menu e encontre nossos produtos! Menu'"
      (click)="onClickMenu($event)"
      (mouseleave)="addOverflowHiddenToBody(false, menubar)"
    >
    </p-menubar>
  </div>
</div>
