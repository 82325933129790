// primeng.module.ts
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenubarModule } from 'primeng/menubar';
import { PaginatorModule } from 'primeng/paginator';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [
    ButtonModule,
    CardModule,
    CarouselModule,
    ConfirmDialogModule,
    DialogModule,
    DropdownModule,
    FileUploadModule,
    GalleriaModule,
    InputMaskModule,
    InputTextModule,
    InputTextareaModule,
    MegaMenuModule,
    MenubarModule,
    PaginatorModule,
    PasswordModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    TableModule,
    TagModule,
    TimelineModule,
    ToastModule,
    TooltipModule,
  ],
  exports: [
    ButtonModule,
    CardModule,
    CarouselModule,
    ConfirmDialogModule,
    DialogModule,
    DropdownModule,
    FileUploadModule,
    GalleriaModule,
    InputMaskModule,
    InputTextModule,
    InputTextareaModule,
    MegaMenuModule,
    MenubarModule,
    PaginatorModule,
    PasswordModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    TableModule,
    TagModule,
    TimelineModule,
    ToastModule,
    TooltipModule,
  ],
})
export class PrimeNGModule {}
