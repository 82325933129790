import ReportOperations from './report-operations';

export default class Report {
  reports: ReportOperations | undefined;

  fromJson(reportObj: any) {
    if (reportObj != null) {
      this.reports = new ReportOperations();
      this.reports.fromJson(reportObj);
    }
  }
}
