<app-breadcrumb [path]="'Fale Conosco'" [showTitle]="true"></app-breadcrumb>

<div class="contact-main">
  <div class="container">
    <div class="contact-main-content">
      <div class="contact-main-content-form">
        <div class="contact-form">
          <h2>Mande sua mensagem</h2>
          <app-contact-form [contactType]="typeContact"></app-contact-form>
        </div>
        <div class="contact-infos">
          <div class="contact-infos-item">
            <i class="pi pi-whatsapp"></i>
            <div class="contact-infos-item-content">
              <a (click)="onClickWhatss()">(48) 3369-7157 - WhatsApp</a>
            </div>
          </div>
          <div class="contact-infos-item">
            <i class="pi pi-at"></i>
            <div class="contact-infos-item-content">
              <a href="mailto:comercial@rmpolicarbonatos.com.br"
                >comercial&#64;rmpolicarbonatos.com.br</a
              >
              <a href="/">www.rmpolicarbonatos.com.br</a>
            </div>
          </div>
          <div class="contact-infos-item">
            <i class="pi pi-map-marker"></i>
            <div class="contact-infos-item-content">
              <a target="_blank" href="https://g.page/rmpolicarbonatos">
                <span>Rua Pedro Bunn, 1603</span>
                <span>Jd. Cidade de Florianópolis</span>
                <span>São Jośe-SC - 88111-120</span></a
              >
            </div>
          </div>
          <div class="contact-infos-item">
            <i class="pi pi-map-marker"></i>
            <div class="contact-infos-item-content">
              <a target="_blank" href="https://g.page/rmpolicarbonatosilha">
                <span>Rod. José Carlos Daux, SC401, 14750</span>
                <span>Sala 02 - Vargem Pequena</span>
                <span>Florianópolis-SC - 88052-840</span></a
              >
            </div>
          </div>
          <div class="contact-infos-item">
            <i class="pi pi-clock"></i>
            <div class="contact-infos-item-content">
              <span>Segunda à Sexta</span>
              <span>Das 8h00 às 11:30</span>
              <span>13:30 às 17:30</span>
            </div>
          </div>
        </div>
      </div>
      <div class="contact-main-content-maps">
        <iframe
          title="Nossa localizaçção!"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3536.700791908642!2d-48.627741123758945!3d-27.571795021065515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x952749d4f9533ea3%3A0x88ea7b548ce890ed!2sRM%20Policarbonatos%20%26%20Acess%C3%B3rios%20-%20Unidade%20S%C3%A3o%20Jos%C3%A9!5e0!3m2!1spt-BR!2sbr!4v1688597215247!5m2!1spt-BR!2sbr"
          width="600"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  </div>
</div>
