<p-toast position="top-center" [baseZIndex]="9999"></p-toast>
<app-breadcrumb [path]="'Redefinir senha'" [showTitle]="true"></app-breadcrumb>
<div class="forgot-main">
  <div class="container">
    <div class="forgot-main-content">
      <div class="forgot-main-content-form">
        <h2 class="forgot-form-title">informe sua Nova senha</h2>
        <div class="forgot-form-inputs">
          <p-password
            name="password"
            placeholder="Nova senha"
            [(ngModel)]="userForgot.password"
            [toggleMask]="true"
            [feedback]="false"
            [ngClass]="{
              'error-input-prime': userError.errorPassword
            }"
          ></p-password>

          <p-password
            name="passwordConfirm"
            placeholder="Confirme sua senha"
            [(ngModel)]="userForgot.passwordConfirm"
            [toggleMask]="true"
            [feedback]="false"
            [ngClass]="{
              'error-input-prime': userError.errorPasswordConfirm
            }"
          ></p-password>

          <p>Sua senha deve conter no mínimo 8 caracteres, letras</p>
          <p>maiúsculas, minúsculas e números!</p>

          <re-captcha
            (resolved)="resolvedcCaptcha($event)"
            siteKey="{{ siteKey }}"
          ></re-captcha>

          <button
            class="button-rm"
            aria-label="Redefinir nova senha"
            type="submit"
            (click)="onClickChange()"
          >
            ALTERAR
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
