import { AbstractInfoEnum } from '../app/abstract-info-enum';
import {
  AttributeEnum,
  CategoriesEnum,
  FilterSubCategoriesAcessoriosEnum,
  FilterSubCategoriesChapasEnum,
  FilterSubCategoriesComunicacaoEnum,
  FilterSubCategoriesKitsEnum,
  FilterSubCategoriesPerfisEnum,
  FilterSubCategoriesToldosEnum,
  SubCategoriesEnum,
} from '../app/info-enum';
import { FilterProductsShop, Product } from './product';

export default class InfoSubCategory extends AbstractInfoEnum {
  public static readonly VALUE_BANDO: number = 55;
  public static readonly VALUE_MOTOR: number = 899;

  public actuationSides: Array<AbstractInfoEnum> = [];
  public actuationTypes: Array<AbstractInfoEnum> = [];
  public aluminumWallThickness: Array<AbstractInfoEnum> = [];
  public brands: Array<AbstractInfoEnum> = [];
  public capabilities: Array<AbstractInfoEnum> = [];
  public category?: string;
  public colors: Array<AbstractInfoEnum> = [];
  public colorsCode: Array<AbstractInfoEnum> = [];
  public fabricCompositions: Array<AbstractInfoEnum> = [];
  public finishes: Array<AbstractInfoEnum> = [];
  public heightThickness: Array<AbstractInfoEnum> = [];
  public lengths: Array<AbstractInfoEnum> = [];
  public models: Array<AbstractInfoEnum> = [];
  public potencies: Array<AbstractInfoEnum> = [];
  public speeds: Array<AbstractInfoEnum> = [];
  public unitsCommercialize: Array<AbstractInfoEnum> = [];
  public voltages: Array<AbstractInfoEnum> = [];
  public widths: Array<AbstractInfoEnum> = [];

  override fromJson(subCategoriesObj: any) {
    if (subCategoriesObj != null) {
      super.fromJson(subCategoriesObj);
      this.category = subCategoriesObj?.main;

      if (
        subCategoriesObj.aluminumWallThickness != null &&
        subCategoriesObj.aluminumWallThickness.length > 0
      ) {
        this.aluminumWallThickness = this.newAbstractInfo(
          subCategoriesObj.aluminumWallThickness,
          true
        );
      }

      if (
        subCategoriesObj.colors != null &&
        subCategoriesObj.colors.length > 0
      ) {
        this.colors = this.newAbstractInfo(subCategoriesObj.colors, false);
      }

      if (
        subCategoriesObj.colorsCode != null &&
        subCategoriesObj.colorsCode.length > 0
      ) {
        this.colorsCode = this.newAbstractInfo(
          subCategoriesObj.colorsCode,
          false
        );
      }

      if (
        subCategoriesObj.finishes != null &&
        subCategoriesObj.finishes.length > 0
      ) {
        this.finishes = this.newAbstractInfo(subCategoriesObj.finishes, false);
      }

      if (
        subCategoriesObj.heightThickness != null &&
        subCategoriesObj.heightThickness.length > 0
      ) {
        this.heightThickness = this.newAbstractInfo(
          subCategoriesObj.heightThickness,
          true
        );
      }

      if (
        subCategoriesObj.widths != null &&
        subCategoriesObj.widths.length > 0
      ) {
        this.widths = this.newAbstractInfo(subCategoriesObj.widths, true);
      }

      if (
        subCategoriesObj.lengths != null &&
        subCategoriesObj.lengths.length > 0
      ) {
        this.lengths = this.newAbstractInfo(subCategoriesObj.lengths, true);
      }

      if (
        subCategoriesObj.brands != null &&
        subCategoriesObj.brands.length > 0
      ) {
        this.brands = this.newAbstractInfo(subCategoriesObj.brands, false);
      }

      if (
        subCategoriesObj.unitsCommercialize != null &&
        subCategoriesObj.unitsCommercialize.length > 0
      ) {
        this.unitsCommercialize = this.newAbstractInfo(
          subCategoriesObj.unitsCommercialize,
          false
        );
      }

      if (
        subCategoriesObj.capabilities != null &&
        subCategoriesObj.capabilities.length > 0
      ) {
        this.capabilities = this.newAbstractInfo(
          subCategoriesObj.capabilities,
          false
        );
      }

      if (
        subCategoriesObj.models != null &&
        subCategoriesObj.models.length > 0
      ) {
        this.models = this.newAbstractInfo(subCategoriesObj.models, false);
      }

      if (
        subCategoriesObj.potencies != null &&
        subCategoriesObj.potencies.length > 0
      ) {
        this.potencies = this.newAbstractInfo(
          subCategoriesObj.potencies,
          false
        );
      }

      if (
        subCategoriesObj.speeds != null &&
        subCategoriesObj.speeds.length > 0
      ) {
        this.speeds = this.newAbstractInfo(subCategoriesObj.speeds, false);
      }

      if (
        subCategoriesObj.voltages != null &&
        subCategoriesObj.voltages.length > 0
      ) {
        this.voltages = this.newAbstractInfo(subCategoriesObj.voltages, false);
      }

      if (
        subCategoriesObj.fabricCompositions != null &&
        subCategoriesObj.fabricCompositions.length > 0
      ) {
        this.fabricCompositions = this.newAbstractInfo(
          subCategoriesObj.fabricCompositions,
          false
        );
      }

      if (
        subCategoriesObj.actuationTypes != null &&
        subCategoriesObj.actuationTypes.length > 0
      ) {
        this.actuationTypes = this.newAbstractInfo(
          subCategoriesObj.actuationTypes,
          false
        );
      }

      if (
        subCategoriesObj.actuationSides != null &&
        subCategoriesObj.actuationSides.length > 0
      ) {
        this.actuationSides = this.newAbstractInfo(
          subCategoriesObj.actuationSides,
          false
        );
      }
    }
  }

  newAbstractInfo(subCategoriesObj: Array<any>, metreage: boolean) {
    let itens: Array<AbstractInfoEnum> = [];
    let abstractInfo: AbstractInfoEnum;
    itens = [];
    subCategoriesObj.forEach((item) => {
      abstractInfo = new AbstractInfoEnum();
      abstractInfo.fromJson(item);
      let value = parseFloat(
        (abstractInfo?.description || '')?.replace(',', '.')
      );

      if (metreage) {
        let sufix = 'cm';
        value = parseFloat(value.toFixed(4));
        abstractInfo.description = value.toString().concat(sufix);
      }
      itens.push(abstractInfo);
    });
    return itens;
  }

  categoryUseMM(category: string): boolean {
    return (
      category === CategoriesEnum.KITS ||
      category === CategoriesEnum.COBERTURAS_EM_POLICARBONATO ||
      category === CategoriesEnum.COMUNICACAO_VISUAL ||
      category === CategoriesEnum.PERFIS_EM_ALUMINIO
    );
  }

  useMetersOrCentimeter(category: string, subCategory: string): boolean {
    return (
      category === CategoriesEnum.PERFIS_EM_ALUMINIO ||
      subCategory === SubCategoriesEnum.ITENS_DE_SEGURANCA ||
      subCategory === SubCategoriesEnum.CANTONEIRAS_BARRA_CHATA ||
      subCategory === SubCategoriesEnum.DISCOS_CORTE_DESBASTE ||
      subCategory === SubCategoriesEnum.FITAS_SELANTES ||
      subCategory === SubCategoriesEnum.BUCHAS_PARAFUSOS ||
      subCategory === SubCategoriesEnum.PLACAS_SINALIZACAO ||
      subCategory === SubCategoriesEnum.PLASTICOS_EMBALAGENS ||
      subCategory === SubCategoriesEnum._ACESSORIOS_TOLDOS
    );
  }

  subCategoryUseMM(subCategory: string): boolean {
    return (
      subCategory === SubCategoriesEnum.PLACAS_SINALIZACAO ||
      subCategory === SubCategoriesEnum.PLASTICOS_EMBALAGENS
    );
  }

  attributeUseMM(attribute: string): boolean {
    return attribute === AttributeEnum.HEIGHT_THICKNESS;
  }

  getFilterSubCategoryByName(attribute: string): FilterProductsShop {
    switch (attribute) {
      case 'capabilities':
        return {
          name: 'capacity',
          title: 'CAPACIDADES',
          values: this.capabilities,
          visible: this.showCapabilities(),
        };

      case 'colors':
        return {
          name: 'color',
          title: 'CORES',
          values: this.colors,
          visible: this.showColors(),
        };

      case 'finishes':
        return {
          name: 'finish',
          title: 'ACABAMENTOS',
          values: this.finishes,
          visible: this.showFinishes(),
        };

      case 'heightThickness':
        return {
          name: 'heightThickness',
          title: this.showHeight() ? 'ALTURAS' : 'ESPESSURAS',
          values: this.heightThickness,
          visible: this.showHeightThickness(),
        };

      case 'lengths':
        return {
          name: 'length',
          title: this.getTitleLength(),
          values: this.lengths,
          visible: this.showLength(),
        };

      case 'models':
        return {
          name: 'model',
          title: 'MODELOS',
          values: this.models,
          visible: this.showModels(),
        };

      case 'potencies':
        return {
          name: 'potency',
          title: 'POTÊNCIAS',
          values: this.potencies,
          visible: this.showPotencies(),
        };

      case 'speeds':
        return {
          name: 'speed',
          title: 'VELOCIDADES',
          values: this.speeds,
          visible: this.showSpeeds(),
        };

      case 'voltages':
        return {
          name: 'voltage',
          title: 'VOLTAGENS',
          values: this.voltages,
          visible: this.showVoltages(),
        };

      case 'widths':
        return {
          name: 'width',
          title: 'LARGURAS',
          values: this.widths,
          visible: this.showWidth(),
        };

      default:
        return new FilterProductsShop();
    }
  }

  showCapabilities(): boolean {
    return this?.capabilities?.length > 0;
  }

  showColors(): boolean {
    return (
      this?.colors?.length > 0 &&
      this.id !== SubCategoriesEnum.FITAS_SELANTES &&
      this.id !== SubCategoriesEnum.DISCOS_CORTE_DESBASTE
    );
  }

  showFinishes(): boolean {
    return (
      this?.finishes?.length > 0 &&
      (this.category === CategoriesEnum.TOLDOS ||
        this.category === CategoriesEnum.COBERTURAS_EM_POLICARBONATO ||
        this.category === CategoriesEnum.COMUNICACAO_VISUAL ||
        this.category === SubCategoriesEnum.ITENS_DE_SEGURANCA) &&
      this.id !== SubCategoriesEnum.TOLDOS_ARTICULADO &&
      this.id !== SubCategoriesEnum._ACESSORIOS_TOLDOS
    );
  }

  showHeightThickness(): boolean {
    return (
      (!this.isSubCategoryPerSquareMeter() &&
        this?.heightThickness?.length > 0 &&
        this.category !== CategoriesEnum.TOLDOS &&
        this.category !== SubCategoriesEnum.ITENS_DE_SEGURANCA &&
        this.id !== SubCategoriesEnum._ACESSORIOS_TOLDOS &&
        this.id !== SubCategoriesEnum.FITAS_SELANTES &&
        this.id !== SubCategoriesEnum.BUCHAS_PARAFUSOS &&
        this.id !== SubCategoriesEnum.DISCOS_CORTE_DESBASTE) ||
      false
    );
  }

  showHeight(): boolean {
    return (
      this.id === SubCategoriesEnum.CANTONEIRAS_BARRA_CHATA ||
      this.id === SubCategoriesEnum.PERFIS_ESTRUTURAIS ||
      this.id === SubCategoriesEnum.TOLDOS_CORTINA ||
      this.category === CategoriesEnum.PERSIANAS
    );
  }

  showLength(): boolean {
    return (
      (this?.lengths?.length > 0 &&
        this.id !== SubCategoriesEnum.DISCOS_CORTE_DESBASTE &&
        this.id !== SubCategoriesEnum.TOLDOS_CORTINA &&
        this.category !== CategoriesEnum.PERSIANAS) ||
      false
    );
  }

  showModels(): boolean {
    return (
      this?.models?.length > 0 &&
      this.category !== CategoriesEnum.KITS &&
      this.category !== CategoriesEnum.COBERTURAS_EM_POLICARBONATO &&
      this.category !== CategoriesEnum.COMUNICACAO_VISUAL
    );
  }

  showPotencies(): boolean {
    return this?.potencies?.length > 0;
  }

  showSpeeds(): boolean {
    return this?.speeds?.length > 0;
  }

  showVoltages(): boolean {
    return this?.voltages?.length > 0;
  }

  showWidth(): boolean {
    return !this.isSubCategoryPerSquareMeter() && this?.widths?.length > 0;
  }

  getTitleLength(): string {
    if (this.isCategoryKit()) {
      return 'AVANÇO';
    } else if (this.isCategoryPersianas()) {
      return 'PROFUNDIDADE';
    } else {
      return 'COMPRIMENTO';
    }
  }

  isCategoryKit(): boolean {
    return this.category === CategoriesEnum.KITS;
  }

  isCategoryToldos(): boolean {
    return this.category === CategoriesEnum.TOLDOS;
  }

  isSubCategoryToldoArticulado(): boolean {
    return this.id === SubCategoriesEnum.TOLDOS_ARTICULADO;
  }

  isSubCategoryToldosCortina(): boolean {
    return this.id === SubCategoriesEnum.TOLDOS_CORTINA;
  }

  isCategoryPerfisFitaFixacao(): boolean {
    return this.category === CategoriesEnum.PERFIS_EM_ALUMINIO;
  }

  isSubCategoryFitasSelantes(): boolean {
    return this.id === SubCategoriesEnum.FITAS_SELANTES;
  }

  isSubCategoryBuchasParafusos(): boolean {
    return this.id === SubCategoriesEnum.BUCHAS_PARAFUSOS;
  }

  isSubCategoryDiscos(): boolean {
    return this.id === SubCategoriesEnum.DISCOS_CORTE_DESBASTE;
  }

  isCategoryPersianas(): boolean {
    return this.id === CategoriesEnum.PERSIANAS;
  }

  isSubCategoryPerSquareMeter(): boolean {
    return (
      this.id === SubCategoriesEnum.TOLDOS_CORTINA ||
      this.id === SubCategoriesEnum.DOUBLE_VISION ||
      this.id === SubCategoriesEnum.PAINEL ||
      this.id === SubCategoriesEnum.ROLO ||
      this.id === SubCategoriesEnum.ROMANA ||
      this.id === SubCategoriesEnum.ROMANA_DE_TETO
    );
  }

  isCategoryModel(): boolean {
    return (
      this.isSubCategoryToldoArticulado() ||
      this.isCategoryPerfisFitaFixacao() ||
      this.isSubCategoryFitasSelantes() ||
      this.isSubCategoryBuchasParafusos() ||
      this.isSubCategoryDiscos()
    );
  }

  public static getEnumFilterSubcategories(
    product: Product | undefined
  ): any[] {
    let subCategories: any[] = [];
    switch (product?.category) {
      case CategoriesEnum.ACESSORIOS_DE_INSTALACAO:
        subCategories = Object.values(FilterSubCategoriesAcessoriosEnum);
        break;
      case CategoriesEnum.COBERTURAS_EM_POLICARBONATO:
        subCategories = Object.values(FilterSubCategoriesChapasEnum);
        break;
      case CategoriesEnum.COMUNICACAO_VISUAL:
        subCategories = Object.values(FilterSubCategoriesComunicacaoEnum);
        break;
      case CategoriesEnum.KITS:
        subCategories = Object.values(FilterSubCategoriesKitsEnum);
        break;
      case CategoriesEnum.PERFIS_EM_ALUMINIO:
        subCategories = Object.values(FilterSubCategoriesPerfisEnum);
        break;
      case CategoriesEnum.TOLDOS:
        subCategories = Object.values(FilterSubCategoriesToldosEnum);
        break;
      case CategoriesEnum.PERSIANAS:
        subCategories = [
          product.subCategory,
          SubCategoriesEnum.ACESSORIOS_PERSIANAS,
        ];
        break;
      default:
        break;
    }
    return subCategories;
  }
}
