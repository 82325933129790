<app-breadcrumb
  [path]="'Aviso de Privacidade'"
  [showTitle]="true"
></app-breadcrumb>

<div class="privacy-main">
  <div class="container">
    <div class="privacy-main-content">
      <p>
        A RM Policarbonatos tem como missão de suprir a carência e agregar
        qualidade no mercado de Termo Plásticos, Coberturas e Comunicação
        Visual, por isso respeita a privacidade de seus usuários e os protege em
        todas as atividades com a maior prioridade dentro da Internet. Isso
        significa que estamos comprometidos a tratar todas as informações
        fornecidas por você com a maior integridade e de forma transparente,
        incluindo a cooperação com parceiros e terceiros.
      </p>
      <p>
        Pedimos que leia atentamente este Aviso de Privacidade e qualquer
        controvérsia que possa surgir sobre este assunto, você tem o canal de
        comunicação exclusivo para o assunto de privacidade e proteção de dados
        disposto no item 18.
      </p>
      <details>
        <summary>1. QUEM SOMOS</summary>
        <div>
          <p>
            A controladora dos dados, RM Policarbonatos Fabricação e Comércio
            LTDA EPP inscrita no CNPJ sob nº 26.298.189/0001-34, com sede na Rua
            Pedro Bunn, 1603 - Jd. Cidade de Florianópolis - São Jośe - SC -
            88111-120, apresenta este Aviso de Privacidade e se aplica a todos
            os usuários e clientes, incluindo, sites e app, que de alguma forma
            tenham os dados pessoais tratados por nós.
          </p>
        </div>
      </details>
      <details>
        <summary>2. DEFINIÇÕES</summary>
        <div>
          <p>
            Este tópico tem o intuito de explicar, de forma clara e com foco na
            comunicação deste aviso, conceitos elencadas na Lei Geral de
            Proteção de Dados Pessoais.
          </p>
          <p>
            <strong>Dados pessoais</strong> é toda e qualquer informação que
            identifica ou possa identificar uma pessoa física.
          </p>
          <p>
            <strong>Operação de tratamento de dados:</strong> qualquer ação que
            realizada com dados pessoais, como: coleta, produção, recepção,
            classificação, utilização, acesso, reprodução, transmissão,
            distribuição, processamento, arquivamento, armazenamento,
            eliminação, avaliação ou controle da informação, modificação,
            comunicação, transferência, difusão ou extração...
          </p>
          <p>
            <strong>Titular de dados:</strong> Pessoa física a quem se refere o
            dado pessoal.
          </p>
          <p><strong>Agentes de tratamento:</strong> Controlador e Operador</p>
          <p>
            <strong>Controlador:</strong> Agente de tratamento responsável por
            conceder as diretrizes de como ocorrerão as operações de tratamento
            com os dados pessoais dos titulares.
          </p>
          <p>
            <strong>Operador:</strong> Agente de tratamento recebe as diretrizes
            do controlador e age conforme esta.
          </p>
          <p>
            <strong>Encarregado:</strong> Profissional que exerce a comunicação
            entre o titular de dados e o controlador, bem como a ANPD e o
            controlador.
          </p>
          <p>
            <strong
              >ANPD (Autoridade Nacional de Proteção de Dados Pessoais):</strong
            >
            Órgão que possui responsabilidade em relação à privacidade e
            proteção de dados, bem como fiscalizar as empresas em relação à
            matéria.
          </p>
        </div>
      </details>

      <details>
        <summary>3. RESPONSABILIDADE</summary>
        <div>
          <p>
            A RM Policarbonatos é responsável pela privacidade e proteção de
            dados dos clientes e usuários por garantir que este aviso seja
            disponibilizado aos titulares dos dados antes que a empresa realize
            qualquer tipo de operação de tratamento com seus dados pessoais.
          </p>
          <p>
            Todos os colaboradores da RM Policarbonatos que interagem com os
            titulares dos dados são responsáveis por garantir que este aviso
            seja levado ao conhecimento do titular dos dados e que o tratamento
            de seus dados seja protegido.
          </p>
        </div>
      </details>

      <details>
        <summary>4. COMO COLETAMOS</summary>
        <div>
          <p>
            Você nos fornece diretamente a maioria dos dados pessoais que
            coletamos quando você acessa nosso site, nos telefona, encaminha um
            whatsApp e envia e-mail.
          </p>
          <p>
            <strong>Quando o Usuário subscreve na plataforma:</strong>
            quando o usuário subscreve na plataforma, coletamos e-mail, nome
            completo, CPF, data de nascimento e endereço de entrega (CEP,
            Endereço, número, complemento, bairro, estado e cidade).
          </p>
          <p>
            <strong>Através da execução dos serviços:</strong> para execução dos
            serviços, serão confirmados os seguintes dados já fornecidos pelo
            usuário: endereço de entrega e coletados informações financeiras
            para pagamento, se necessário.
          </p>
          <p>
            <strong>Através da utilização da plataforma:</strong> quando o
            usuário utiliza e interage com a plataforma, coletamos dados
            pessoais sobre a sua utilização de modo automático ou
            semiautomático, como a localização e seu dispositivo móvel.
          </p>
          <p>
            Quando você clica em anúncios, e-mails de parceiros e outros
            conteúdos fornecidos por nós em outros sites.
          </p>
          <p>
            Podemos utilizar essas informações para oferecer conteúdos
            personalizados como, por exemplo, anúncios, resultados de buscas e
            outros. A maioria dos dispositivos móveis permite que o cliente
            desligue os serviços de localização.
          </p>
          <p>
            <strong>Através de compartilhamento com empresas parceiras:</strong>
            para obter uma informação verídica e confiável, coletamos dados
            pessoais que são disponibilizados, tais como e-mail, nome, número de
            CPF, telefones, data de nascimento e informações financeiras, como
            descrito no item compartilhamento e de acordo com a legislação
            aplicável.
          </p>
        </div>
      </details>

      <details>
        <summary>5. DADOS COLETADOS E SUA FINALIDADE</summary>
        <div>
          <p>
            Coletamos seus dados pessoais por diversos motivos, desta forma
            explicamos abaixo os dados pessoais e suas finalidades:
          </p>
          <p>
            É imprescindível a coleta do seu e-mail para que você tenha acesso
            aos nossos catálogos de produtos.
          </p>
          <p>
            Os dados de e-mail, nome completo, CPF, data de nascimento e
            endereço de entrega (CEP, Endereço, número, complemento, bairro,
            estado e cidade) são imprescindíveis para a subscrição na
            plataforma.
          </p>
          <p>
            A confirmação do endereço completo e informações financeiras para
            realizar o pagamento são necessários para que os serviços sejam
            executados da forma mais correta.
          </p>
          <p>
            São utilizados os dados de histórico de compra e histórico de
            navegação na plataforma para fornecer, personalizar e melhorar sua
            experiência. Por exemplo, indicar produtos e ofertas de acordo com o
            seu perfil e para compreender de que forma você acessa e utiliza a
            plataforma, a fim de garantir a funcionalidade técnica, desenvolver
            novos produtos e serviços.
          </p>
          <p>
            Guardamos registros de acesso à plataforma, como por exemplo:
            informações sobre as interações do Usuário com a Plataforma,
            incluindo a data e hora do envio de informações; Dados de transações
            bancárias ou financeiras em geral; Dados técnicos, que poderão
            incluir informações de URL, dados de cookies, o endereço IP do
            Usuário, os tipos de dispositivos por este utilizados para acessar à
            Plataforma, identificações exclusivas do dispositivo, atributos do
            dispositivo, tipo de ligação à rede (por exemplo, Wi-Fi, 3G, LTE,
            Bluetooth) e fornecedor de rede, desempenho da rede e do
            dispositivo, tipo de navegador, idioma, informações que permitam a
            gestão dos direitos digitais, sistema operativo e versão da
            plataforma, para, caso aconteça algum crime ou ato ilícito na
            plataforma, podermos identificar o responsável, em conformidade com
            o previsto no Marco Civil da Internet, prestar contas aos órgãos de
            fiscalização tributária e controle de operações financeiras, entre
            outras obrigações legais aplicáveis.
          </p>
        </div>
      </details>

      <details>
        <summary>6. DIREITOS DO TITULAR</summary>
        <div>
          <p>
            Gostaríamos de ter certeza de que você está totalmente ciente de
            todos os seus direitos relacionados à privacidade e proteção de
            dados pessoais, por isso elencamos a seguir:
          </p>
          <p>
            O direito da confirmação da existência de tratamento com dados
            pessoais;
          </p>
          <p>
            O direito de acesso aos dados pessoais que nós tratamos a seu
            respeito, e pode também solicitar informações sobre:
          </p>
          <p>• origem dos dados,</p>
          <p>• a inexistência de registro</p>
          <p>• os critérios utilizados</p>
          <p>• finalidade do tratamento</p>
          <p>
            O direito à correção de dados incompletos, inexatos ou
            desatualizados;
          </p>
          <p>
            O direito da anonimização, bloqueio ou eliminação de dados
            desnecessários, excessivos ou tratados em desconformidade com o
            disposto em Lei;
          </p>
          <p>
            O direito à portabilidade dos dados a outro fornecedor de serviço ou
            produto, mediante requisição expressa, sob certas condições;
          </p>
          <p>
            O direito à eliminação dos dados pessoais tratados com o
            consentimento do titular, sob certas condições;
          </p>
          <p>
            O direito à informação das entidades públicas e privadas com as
            quais o controlador realizou uso compartilhado de dados;
          </p>
          <p>
            O direito da informação sobre a possibilidade de não fornecer
            consentimento e sobre as consequências da negativa, o qual está
            descrito no item 16;
          </p>
          <p>O direito de revogação do consentimento, nos termos da LGPD;</p>
          <p>
            O direito de peticionamento em relação aos seus dados contra o
            controlador perante autoridade nacional;
          </p>
          <p>
            O direito de oposição ao tratamento realizado com fundamento em uma
            das hipóteses de dispensa de consentimento;
          </p>
          <p>
            O direito de revisão de decisões tomadas unicamente com base em
            tratamento automatizado de dados pessoais que afetem seus
            interesses, incluídas as decisões destinadas a definir o seu perfil
            pessoal, profissional, de consumo e de crédito ou os aspectos de sua
            personalidade;
          </p>
          <p>
            Se você deseja exercer algum desses direitos, entre em contato
            conosco, pelo e-mail
            <a href="mailto:privacidade&#64;rmpolicarbonatos.com.br"
              ><strong>privacidade&#64;rmpolicarbonatos.com.br</strong></a
            >
            e responderemos no menor tempo possível e dentro do prazo legal.
          </p>
        </div>
      </details>

      <details>
        <summary>7. COMPARTILHAMENTO E DIVULGAÇÃO</summary>
        <div>
          <p>
            A RM Policarbonatos necessita compartilhar alguns dados pessoais com
            os seguintes grupos de destinatários para melhorar atender você e
            disponibilizar um serviço de qualidade.
          </p>
          <p>
            Lojistas e empresa de faturamento e entrega- Ao efetuar compras na
            plataforma, quando aplicável, seus dados serão compartilhados com
            lojistas que vendem seus produtos pelo Marketplace, administradoras
            de cartão de crédito, instituições de recebimento de pagamentos,
            transportadoras, para que os valores devidos sejam computados, seja
            emitida nota fiscal, e os produtos entregues no endereço indicado
            por você.
          </p>
          <p>
            Operações de Marketing - Ao utilizar a plataforma, seus dados
            poderão ser fornecidos para operações de marketing para que seja
            possível a manutenção do envio de propagandas e ofertas.
          </p>
          <p>
            Operações de crédito, serviços e seguros - Para possibilitar o
            oferecimento de serviços financeiros, seguros e operações de crédito
            por instituições parceiras, caso se faça necessário, os seus dados
            poderão ser compartilhados mediante o fornecimento de consentimento
            e desde que observadas as regras desta Política e da legislação
            aplicável.
          </p>
          <p>
            Empresas de proteção ao crédito - Os seus dados poderão ser
            fornecidos para as empresas de serviços de proteção ao crédito para
            garantir a segurança da operação e das suas informações.
          </p>
          <p>
            Empresas de serviços de segurança da informação e Antifraude - Os
            seus dados poderão ser fornecidos para as empresas contratadas para
            garantir a segurança das suas informações.
          </p>
          <p>
            Cumprimento da lei e autoridades de proteção de dados -
            Compartilharemos os seus dados, quando necessário, para o
            cumprimento de uma obrigação legal, nos termos da legislação
            aplicável, ou para responder a um processo legal válido, como, por
            exemplo, um mandado de busca, uma ordem judicial ou uma intimação.
          </p>
          <p>
            Também compartilharemos seus dados, quando necessário, para o nosso
            legítimo interesse, ou o de terceiros, em matéria de segurança
            nacional, cumprimento da lei, contencioso, investigação criminal,
            prevenção à fraude, proteção da segurança de qualquer pessoa, ou
            para impedir a morte ou danos físicos eminentes, desde que
            consideremos que tal interesse não prevalece sobre seus os
            interesses ou direitos e liberdades fundamentais que exijam a
            proteção dos dados pessoais do Usuário.
          </p>
          <p>
            Transações e Alterações Societárias - Compartilharemos seus dados
            pessoais quando vendermos ou negociarmos a venda do nosso negócio a
            um comprador ou potencial comprador. No caso de transações e
            alterações societárias, hipótese em que a transferência das
            informações será necessária para a continuidade dos serviços e
            entrega dos produtos.
          </p>
          <p>
            Note que todos os operadores que atuam em nosso nome apenas tratam
            seus dados de acordo com nossas instruções e cumprem integralmente
            este aviso de privacidade, bem como as legislações de proteção de
            dados e quaisquer outras medidas de confidencialidade e segurança
            apropriadas.
          </p>
          <p>
            Ainda, ao acessar o site pode ser realizado o compartilhamento de
            seus dados pessoais com a empresa Facebook e Google para fins de
            marketing através do google analytics e cookies de marketing, no
            qual poderá ser verificado no item de cookies.
          </p>
          <p>
            Caso deseje entender como é realizado o tratamento de dados pessoais
            com a empresa Facebook, sugerimos que leia o aviso de privacidade
            desta empresa.
          </p>
        </div>
      </details>

      <details>
        <summary>8. ARMAZENAMENTO</summary>
        <div>
          <p>
            Mantemos seus dados pessoais enquanto for necessário para prestar
            nossos serviços e para fins comerciais legítimos e essenciais, tais
            como para manter o desempenho da plataforma, tomar decisões
            empresariais acerca de funcionalidades e ofertas com base em dados e
            cumprir as nossas obrigações legais.
          </p>
          <p>
            Temos políticas de revisão e retenção em vigor para cumprir essas
            obrigações e conservamos alguns dados pessoais enquanto você
            permanece como usuário da nossa plataforma.
          </p>
          <p>
            A pedido de você, vamos eliminar os seus dados pessoais de modo que
            não o identifiquem, exceto se for legalmente permitido ou
            obrigatório manter determinados dados pessoais, incluindo situações
            como as seguintes:
          </p>
          <p>
            • Se formos obrigados a manter os dados pessoais para as nossas
            obrigações jurídicas, fiscais, de auditoria e contabilidade, iremos
            reter seus dados pessoais necessários pelo período exigido pela
            legislação aplicável;
          </p>
          <p>
            • Sempre que necessário para os nossos legítimos interesses
            comerciais, como a prevenção contra fraudes ou para manter a
            segurança dos nossos usuários.
          </p>
          <p>
            Quando você nos der seu consentimento para que utilizemos seus dados
            para marketing direto ou cadastro em nossa plataforma, manteremos
            esses dados até que você exerça seu direito de oposição e/ou retirar
            seu consentimento, ou descadastramento da plataforma.
          </p>
          <p>
            Caso queira exercer o direito de retirada do consentimento descrito
            acima, no item marketing você encontra o link para descadastramento.
          </p>
        </div>
      </details>

      <details>
        <summary>9. MEDIDAS DE SALVAGUARDA</summary>
        <div>
          <p>
            A RM Policarbonatos preza pela segurança das informações e dos dados
            pessoais dos clientes, colaboradores e parceiros de negócios, por
            isso, investe continuamente e dispõe de medidas técnicas e
            administrativas de segurança para proteger seus dados, alinhadas às
            melhores práticas de segurança da informação, contudo sabemos que
            nenhum sistema é completamente seguro.
          </p>
          <p>
            Por isso, estamos sempre em busca de métodos de proteção contra
            ações danosas que visam utilizar, acessar, alterar e/ou destruir
            dados indevidamente, e utilizamos os seguintes mecanismos e
            ferramentas:
          </p>
          <p>• Controle de acesso físico aos ambientes corporativos;</p>
          <p>
            • Acesso aos dados somente por profissionais que exercem funções
            necessárias para atendimento das finalidades específicas;
          </p>
          <p>
            • Aplicamos mecanismos de autenticação de acesso aos registros
            capazes de individualizar o responsável pelo tratamento e acesso dos
            dados coletados em decorrência da utilização da plataforma;
          </p>
          <p>
            • Monitoramento contínuo dos sistemas de informações, de modo a
            prevenir, detectar e bloquear ações danosas;
          </p>
          <p>
            • Armazenamento e transferência de dados utilizando métodos seguros
            (criptografia em trânsito e repouso...).
          </p>
          <p>
            Ainda, a senha protege sua conta de usuário, por isso incentivamos
            que utilize senha forte (utilizando, quando possível, letras
            maiúsculas e minúsculas, números e caracteres especiais e evitando
            utilizar palavras ou datas), limitar o acesso ao computador e
            navegador, não acessar nossa plataforma em computadores públicos ou
            compartilhados e a encerrar a sessão clicando em “Desconectar”
            depois de utilizar a plataforma.
          </p>
        </div>
      </details>

      <details>
        <summary>10. TRANSFERÊNCIA INTERNACIONAL DE DADOS</summary>
        <div>
          <p>
            A internet é um ambiente global, e alguns dos nossos serviços podem
            realizar transferências internacionais com seus dados, pois utiliza
            alguns produtos ou serviços (ou partes deles) que não estão
            hospedados no Brasil.
          </p>
          <p>
            Bem como, nossas subcontratadas ou parceiras podem não estar
            sediadas em território nacional, desta maneira podem ocorrer
            tratamento com seus dados pessoais em outros países.
          </p>
          <p>
            Nestes casos, nas transferências internacionais realizadas pela RM
            Policarbonatos, subcontratadas e parceiras são adotamos medidas para
            garantir que quaisquer informações coletadas sejam tratadas com
            segurança, conforme os padrões de proteção de dado e estão
            submetidas a este aviso de privacidade, bem como a instrumentos
            jurídicos que asseguram o cumprimento de todas as obrigações legais.
          </p>
        </div>
      </details>

      <details>
        <summary>11. CONFIRMAÇÃO DE AUTENTICIDADE</summary>
        <div>
          <p>
            Ao recebermos uma requisição para exercer qualquer um dos direitos
            descritos no item 6 e quando você entra em contato com a gente para
            saber sobre algum determinado produto ou pedido, podemos pedir que
            você confirme sua identidade antes de agirmos com base em sua
            solicitação.
          </p>
          <p>
            Esta confirmação é necessária para garantirmos que estamos em
            contato com a pessoa correta e demonstra o quanto seus dados estão
            protegidos e são mantidos em segurança.
          </p>
        </div>
      </details>

      <details>
        <summary>12. DECISÕES AUTOMATIZADAS</summary>
        <div>
          <p>
            Utilizamos recursos tecnológicos que realizam decisões automatizadas
            para aumentar a qualidade e proteção dos nossos serviços, como,
            prevenção do ambiente tecnológico contra ações danosas,
            gerenciamento de riscos de crédito, combate à fraude à RM
            Policarbonatos e aos nossos clientes.
          </p>
          <p>
            Caso você queira saber mais sobre estas decisões, é seu direito e
            poderá ser exercido através do nosso canal de comunicação pelo
            e-mail do encarregado.
          </p>
        </div>
      </details>

      <details>
        <summary>13. CRIANÇAS E ADOLESCENTES</summary>
        <div>
          <p>
            A Plataforma não se destina a menores de 18 anos. Estão disponíveis
            mais detalhes acerca desta matéria nos nossos Termos e Condições de
            Uso.
          </p>
          <p>
            Não coletamos deliberadamente dados pessoais de crianças e
            adolescentes. Se o usuário for menor de 18 anos, não deverá utilizar
            a plataforma e nem nos fornecer quaisquer dados pessoais, precisará
            fazer a compra junto com um de seus pais ou responsável e em nome
            deste.
          </p>
          <p>
            Se tomarmos conhecimento de que coletamos dados pessoais de uma
            criança ou adolescente, tomaremos as medidas razoáveis para eliminar
            os dados pessoais em questão. Para tal, poderá ser necessário
            eliminar a conta relativa a essa criança ou adolescente.
          </p>
        </div>
      </details>

      <details>
        <summary>14. COOKIES</summary>
        <div>
          <p>
            Nós usamos os chamados "cookies" (pequenos pacotes de dados pessoais
            com informações sobre as configurações de exibição usadas ou o
            endereço de IP, por exemplo) quando você entra em nosso site e
            plataforma para reconhecer seu navegador ou dispositivo, aprender
            mais sobre seus interesses, fornecer recursos e serviços essenciais
            e também para:
          </p>
          <p>
            • acompanhar suas preferências para enviar somente anúncios de seu
            interesse.
          </p>
          <p>• acompanhar os itens armazenados no seu carrinho de compras;</p>
          <p>
            • ealização de pesquisas e diagnósticos para melhorar o conteúdo,
            produtos e serviços;
          </p>
          <p>• impedir atividades fraudulentas;</p>
          <p>• melhorar a segurança.</p>
          <p>
            Ao utilizar a opção “Permanecer Conectado”, o site armazenará os
            denominados cookies permanentes no seu dispositivo, de forma a
            garantir que você não precise se reconectar em visitas posteriores
            ao site, no entanto, esta função não estará disponível se a opção
            armazenamento de cookies estiver desativada no seu navegador.
          </p>
          <p>
            Você pode desabilitar o salvamento dos cookies em seu navegador
            (mais informações sobre isso podem ser encontradas em "Extras -
            opções de internet" na barra de ferramentas do seu navegador). Para
            realizar pedidos no nosso site, os cookies precisam estar ativados.
          </p>
        </div>
      </details>

      <details>
        <summary>15. MARKETING</summary>
        <div>
          <p>
            Desejamos sempre manter nossa missão, por isso, quando você se
            cadastra em nossa plataforma enviaremos informações sobre produtos e
            serviços que achamos que você pode gostar.
          </p>
          <p>
            Se você não deseja mais ser contatado para fins de marketing, entre
            em contato conosco através do e-mail
            <a href="mailto:privacidade&#64;rmpolicarbonatos.com.br"
              ><strong>privacidade&#64;rmpolicarbonatos.com.br</strong></a
            >.
          </p>
          <p>
            Para fins de marketing, também, é tratado informações dos usuários
            através de cookies de marketing, os quais são aceitos ao acessar
            nosso site com a finalidade de compartilhamento com a empresa
            parceira Facebook e Google.
          </p>
        </div>
      </details>

      <details>
        <summary>16. CONSEQUÊNCIAS DE NÃO FORNECER SEUS DADOS</summary>
        <div>
          <p>
            Você não é obrigado a fornecer seus dados pessoais para nós. No
            entanto, como essas informações são necessárias para o fornecimento
            dos nossos serviços e produtos, não seremos capazes de oferecer
            alguns/todos os nossos serviços sem seus dados.
          </p>
        </div>
      </details>

      <details>
        <summary>17. MUDANÇAS NO AVISO DE PRIVACIDADE</summary>
        <div>
          <p>
            A RM Policarbonatos mantém seu aviso de privacidade sob revisão
            semestral e coloca quaisquer atualizações nesta página da web. Este
            aviso de privacidade foi atualizado pela última vez em 20/10/2021.
          </p>
        </div>
      </details>

      <details>
        <summary>
          18. CANAL DE COMUNICAÇÃO SOBRE PRIVACIDADE E PROTEÇÃO DE DADOS
        </summary>
        <div>
          <p>
            Se você tiver alguma dúvida sobre nosso aviso de privacidade, mesmo
            após a leitura, os dados que mantemos sobre você, ou se gostaria de
            exercer um de seus direitos de proteção de dados, você pode nos
            contatar através do nosso canal de comunicação exclusivo, por meio
            do e-mail:
          </p>
          <p>
            <a href="mailto:privacidade&#64;rmpolicarbonatos.com.br"
              ><strong>privacidade&#64;rmpolicarbonatos.com.br</strong></a
            >
          </p>
        </div>
      </details>

      <details>
        <summary>19. AVISOS DE PRIVACIDADE DE OUTROS SITES</summary>
        <div>
          <p>
            O site contém links para outros sites e nosso aviso de privacidade
            se aplica apenas ao nosso site, portanto, se você clicar em um link
            para outro site, recomendamos ler o aviso de privacidade.
          </p>
        </div>
      </details>

      <details>
        <summary>20. AUTORIDADE COMPETENTE</summary>
        <div>
          <p>
            A RM Policarbonatos apenas trata seus dados pessoais em conformidade
            com este aviso de privacidade e de acordo com a legislação de
            proteção de dados vigente. Se, no entanto, você deseja fazer uma
            reclamação sobre nossas atividades de tratamento em relação aos seus
            dados pessoais ou estiver insatisfeito com a forma como lidamos com
            suas informações, você tem o direito de apresentar uma reclamação à
            autoridade de controle responsável, conforme informações de contato
            abaixo:
          </p>
          <p>
            O email:
            <a href="mailto:anpd&#64;anpd.gov.br"
              ><strong>anpd&#64;anpd.gov.br</strong></a
            >
          </p>
          <p>Telefone: (61) 3411-5961</p>
        </div>
      </details>
    </div>
  </div>
</div>
